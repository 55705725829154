import { HttpClient, HttpHeaders } from "@angular/common/http"
import { BehaviorSubject, Observable, Subject, Subscription } from "rxjs"
import { ErrorService } from "src/app/error/err.service";

export class RESTAPI {

    private isProgress_:BehaviorSubject<boolean> = new BehaviorSubject(false);
    isProgress$ = this.isProgress_.asObservable();
    private emitData:Subject<any> = new Subject();
    private subscription:Subscription = Subscription.EMPTY;

    constructor(
        private requestmethod:Function,
        public http:HttpClient,
        private userloggedout:Observable<boolean>,
        private errorservice :ErrorService,
        private url:string,
        private moduleName:string,
        
    ){
        this.userloggedout.subscribe((val:any)=>{
            this.cancelRequest()
        });
    }

    toStringify(postData:any){
        return JSON.stringify(postData)
    }

    headers = new HttpHeaders({'content-type': 'application/json'})

    postrequest(requestobj:any){
        if(!this.isProgress_.getValue()){
            this.isProgress_.next(true);
            this.emitData = new Subject();
            this.subscription = this.requestmethod(this.toStringify(requestobj)).subscribe((dat:any)=>{
                if(dat?.isError){
                    this.handleError();
                    this.showError(dat.error.errmsg,'failure')
                    this.errorservice.postOnlineErrors(this.createErrorObject(dat,1,1),1);
                    this.emitData.error(dat);
                }else{
                    this.emitData.next(dat);
                    this.isProgress_.next(false);
                }
            },(err:any)=>{
                this.emitData.error(err);
                this.isProgress_.next(false);
            })
        }        
        return this.emitData.asObservable();
    }

    handleError(){
        this.isProgress_.next(false);
    }

    showError(errormsg:string,type:string){
        this.errorservice.showError(this.moduleName +" :"+ errormsg,type);
    }

    createErrorObject(val:any,errorState:number,customstate:number){
        const errobj = this.errorservice.httpobjectBuilder(errorState,customstate,val);
        errobj.url = this.url;
        return errobj;
    }

    cancelRequest(){
        if(this.isProgress_.getValue()){
            this.subscription.unsubscribe();
            this.stopRequest();
            this.emitData.complete();
        }
    }

    stopRequest(){
        this.isProgress_.next(false);
    }
}