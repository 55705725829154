
import { AbstractControl, AsyncValidatorFn, ValidationErrors} from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserLoginService } from 'src/app/user-login/user-login.service';

  export class PrePasswordValidator {
    static createValidator(userLoginService: UserLoginService): AsyncValidatorFn {
      return (control: AbstractControl): Observable<ValidationErrors> => {
        return userLoginService
          .checkOldPassword(control.value)
          .pipe(
            map((result: any) =>{
              if(result.pwdExist){ 
                return {pwdexist: true}
              }else{
                return null
              } 
            })
          );
      };
    }
  }