import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'tab',
  template: `
    <div [hidden]="!active" class="pane">
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: ['./tab.component.scss']
})
export class TabComponent implements OnInit {

  constructor() { }

  ngOnInit(): void { }
  @Input('label') title: string;
  @Input() active = false;
  @Input() disable = false;

}
