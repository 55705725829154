import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate, CanActivateChild {
  constructor(private authService:AuthService, private router:Router){}
  canActivate(route: ActivatedRouteSnapshot):boolean{
    // console.log('route',route.routeConfig.path)
    
    if ( sessionStorage.getItem('SPL'))
      return true;
    else{
      this.authService.logout().subscribe(()=>{
        this.router.navigateByUrl('login')
        return false;
      })
     
    }
     
  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  
}
