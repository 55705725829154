import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ErrorService } from '../error/err.service';
import { UserLoginService } from '../user-login/user-login.service';
import { Observable, Subject } from "rxjs";
import { RESTAPI } from '../shared/stateapimanagement/apiwithoutstate';
import { formatDate } from '@angular/common';
import * as echarts from 'echarts';
import { takeUntil } from 'rxjs/operators';
import { TimeZoneService } from './time-zone.service';
import { LongPollingStatelessProgress } from '../shared/stateapimanagement/longpollingstateless-progress';


@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  headers = new HttpHeaders({ 'content-type': 'application/json','charset':'utf-8'});
  private designerDetails: Array<any> = [];
  private designerInit;

  constructor(
    private http: HttpClient,
    private userloginservice: UserLoginService,
    private errorservice: ErrorService,
    private timeZoneService: TimeZoneService,
  ) { }

  componentdestroyed: Subject<number> = new Subject()
  dashboardLandingData:any;
  $dashboardLandingdestroy:Subject<boolean> = new Subject()
  timeformat:string = "YYYY-MM-DD HH:mm:ss"

  defaultDate = this.userloginservice.metaData.dashboard.dateFilter_default.default_fromDt
  userTimeZone =this.userloginservice.timezone
  defaulttimerange:any;
  
  requesttimerange:any;
  destroyobs:Subject<number> = new Subject()

  getdashboardlanding = new LongPollingStatelessProgress(
    this.getDashboardLanding,
    this.http,
    this.userloginservice.userLoggedoff_,
    this.errorservice,
    '/dashboard/widgets/get',
    'Dashboard'
  )

  getDashboardLanding(reqwidgetdetails:any){
    return this.http.post<any>(environment.apiUrl + '/dashboard/widgets/get', reqwidgetdetails, {headers: this.headers})
  }

  getwidgetlabel = (() => {

    return new RESTAPI(
      this.getWidgetLabel,
      this.http,
      this.userloginservice.userLoggedoff_,
      this.errorservice,
      'dashboard/widget/label/get',
      'Dashboard'
    )

  })
  getWidgetLabel(widgetName: any) {
    return this.http.post<any>(environment.apiUrl + '/dashboard/widget/label/get', widgetName, { headers: this.headers })
  }

  getwidgetTable = (() => {
    return new RESTAPI(
      this.getWidgetTable,
      this.http,
      this.userloginservice.userLoggedoff_,
      this.errorservice,
      '/dashboard/widget/table/get',
      'Dashboard'
    )

  })
  getWidgetTable(requestObj: any) {
    return this.http.post<any>(environment.apiUrl + '/dashboard/widget/table/get', requestObj, { headers: this.headers })
  }


  getwidgetchart = (() => {
    return new RESTAPI(
      this.getWidgetChart,
      this.http,
      this.userloginservice.userLoggedoff_,
      this.errorservice,
      'dashboard/widget/chart/get',
      'Dashboard'
    )
  })
  getWidgetChart(requestObj: any) {
    return this.http.post<any>(environment.apiUrl + '/dashboard/widget/chart/get', requestObj, { headers: this.headers })
    //return this.http.post<any>('http://172.104.161.195:3006' + '/dashboard/widget/chart/get', requestObj, { headers: this.headers })
  }

  getStudioList(designReq): any {
    let request = JSON.stringify(designReq);
    return this.http.post<any>(environment.apiUrl + '/studio/list/get', request, { headers: this.headers })
  }

  getDashboardList(designReq:any){
    let request = JSON.stringify(designReq);
    return this.http.post<any>(environment.apiUrl + '/dashboard/dashboard_list/get', request, { headers: this.headers })
  }

  getTemplates(): any {
    let request = JSON.stringify({ "template_list": "all" });
    return this.http.post<any>(environment.apiUrl + '/studio/templates/get', request, { headers: this.headers })

  }
  postTemplates(templateObj) {
    let request = templateObj;
    return this.http.post<any>(environment.apiUrl + '/studio/template/post', request, { headers: this.headers })
  }
  getWidgetList(): any {
    let request = JSON.stringify({ "widgetType": "all" });
    return this.http.post<any>(environment.apiUrl + '/studio/widget_list/get', request, { headers: this.headers })
  }
  getstudioPreview(designId): any {
    let request = JSON.stringify({ "design_id": designId });
    return this.http.post<any>(environment.apiUrl + '/studio/preview/get', request, { headers: this.headers })
  }
  getstudioDelete(designId,designType:string): any {
    let request = { "design_id": designId, designType };
    if(designType == "Widget"){
      return this.http.post<any>(environment.apiUrl + '/studio/widget/delete', request, { headers: this.headers })
    } else {
        return this.http.post<any>(environment.apiUrl + '/studio/delete', request, { headers: this.headers })
    }
  }
  postStudio(design) {
    let request = JSON.stringify(design);
    return this.http.post<any>(environment.apiUrl + '/studio/new/post', request, { headers: this.headers })
  }
  editPostStudio(design) {
    let request = JSON.stringify(design);
    return this.http.post<any>(environment.apiUrl + '/studio/edit/post', request, { headers: this.headers })
  }

  getWidgetbuilder_edit(widget_details:any){
    const reqobj = JSON.stringify(widget_details);
    return this.http.post<any>(environment.apiUrl + '/studio/widget-builder/widget/edit/get', reqobj, { headers: this.headers })
  }

  widgetpreviewDestroyed:Subject<number> = new Subject();
  getWidgetPreview = new LongPollingStatelessProgress(
    this.getwidgetpreview,
    this.http,
    this.userloginservice.userLoggedoff_,
    this.errorservice,
    '/dashboard/widget/chart/get',
    'studio'
  );

  getwidgetpreview(reqobj:any){
    return this.http.post(environment.apiUrl+'/dashboard/widget/chart/get',reqobj,{ headers: this.headers })
  }



  //to calculate the timerange from the relative time
  timeRangeCalc(input) {
    let userCurrentTime = this.timeZoneService.getcurrentuserTime()
    
    if (input[0] == '-' && input[1] == 'minute') {
      return [userCurrentTime.clone().subtract(input[2],'minute').format(this.timeformat),userCurrentTime.format(this.timeformat)];
    }
    else if (input[0] == '-' && input[1] == 'hour') {
      return [userCurrentTime.clone().subtract(input[2],'hour').format(this.timeformat),userCurrentTime.format(this.timeformat)];
    }
    else if (input[0] == '-' && input[1] == 'day') {
      return [userCurrentTime.clone().subtract(input[2],'days').startOf('D').format(this.timeformat),userCurrentTime.format(this.timeformat)];
    }
    else if (input[0] == '*' && input[1] == 'day') {
      let newDate = userCurrentTime.clone().subtract(input[2],'days');
      if(input[2] == 0){
        return [newDate.clone().startOf('D').format(this.timeformat),userCurrentTime.format(this.timeformat)];
      } else {
        return [newDate.clone().startOf('D').format(this.timeformat), newDate.clone().endOf('D').format(this.timeformat)];
      }
    }
    else if (input[0] == '-' && input[1] == 'week') {
      let previousWeeks = userCurrentTime.clone().subtract(input[2],'weeks');

      if (input[2] == 0) {
        return [previousWeeks.startOf('week').format(this.timeformat),userCurrentTime.format(this.timeformat)];
      } else{
        return [previousWeeks.startOf('week').format(this.timeformat),previousWeeks.endOf('week').format(this.timeformat)];
      }      
    }
    else if (input[0] == '-' && input[1] == 'month') {
      return [userCurrentTime.clone().subtract(input[2],'months').format(this.timeformat),userCurrentTime.format(this.timeformat)];
    }
    else if (input[0] == '-' && input[1] == 'year') {
      return [userCurrentTime.clone().subtract(input[2],'year').format(this.timeformat),userCurrentTime.format(this.timeformat)];
    }
    else return [formatDate(input[0], this.timeformat, 'en-US'), formatDate(input[1], this.timeformat, 'en-US')]
  }


  getAlertCountChartdata(data:any){
    const alert_days = Object.keys(data).map((key)=> this.timeZoneService.toUserTimeZone(parseInt(key)).format('MMM DD HH:mm'));
    const alert_cnt = Object.values(data)
    return {"alert_cnt":alert_cnt,"alert_days":alert_days}
  }


  enumerateDaysBetweenDates = function(startDate, endDate) {
    var dates = [];
    var currDate = startDate.clone()
    var lastDate = endDate.clone()
    dates.push(currDate.format('dddd'))
    while(currDate.add(1, 'days').diff(lastDate) <= 0) {
      dates.push(currDate.format('dddd'));
    }
    return dates;
  };

  getCustomPolarHeatmapChartdata(chartdata:any){
    const data = []
    let start_dt = this.timeZoneService.toUserTimeZone(chartdata[1][0]).startOf('D');
    let end_dt = this.timeZoneService.toUserTimeZone(chartdata[1][1]).startOf('D');
    let end_dt_end_of_day = this.timeZoneService.toUserTimeZone(chartdata[1][1]).endOf('D');
    const days = this.enumerateDaysBetweenDates(start_dt,end_dt)
    let start_time =  parseInt(Object.keys(chartdata[0])[0])-3600
    let end_time =  parseInt(Object.keys(chartdata[0])[Object.keys(chartdata[0]).length-1])+3600

    while(start_time >= start_dt.unix()){
      chartdata[0][start_time] = -1
      start_time -= 3600
    }


    while(end_time < end_dt_end_of_day.unix()){
      chartdata[0][end_time] = -1
      end_time += 3600
    }

    for (const [ts, cnt] of Object.entries(chartdata[0])){
      let dt_object = this.timeZoneService.toUserTimeZone(parseInt(ts));

      let event_time_round_start_day = dt_object.clone().startOf('D');

      let daysdifference = event_time_round_start_day.diff(start_dt, 'days');

      data.push([daysdifference,dt_object.hour(),chartdata[0][ts]]);
    }

    const renderfn = function (params:any, api:any) {
                  var values = [api.value(0), api.value(1)];
                  var coord = api.coord(values);
                  var size = api.size([1, 1], values);
                  return {
                    type: 'sector',
                    shape: {
                      cx: params.coordSys.cx,
                      cy: params.coordSys.cy,
                      r0: coord[2] - size[0] / 2,
                      r: coord[2] + size[0] / 2,
                      startAngle: -(coord[3] + size[1] / 2),
                      endAngle: -(coord[3] - size[1] / 2)
                    },
                    style: api.style({
                      fill: api.value(2) <= 0 ? '#FFFFFF' : api.visual('color')
                    })
                  };
                }

    if(data) {
      const maxvalue = data.reduce(function (max, item:any) {
        return Math.max(max, item[2]);
      }, -Infinity);

      return {
        "maxvalue":maxvalue,
        "renderitem":renderfn,
        "data":data,
        "days":days
      };
    } 
  }


  getPolarBarChartformatter(data,eventIds){    
    return function (params:any) {
      const id = params.dataIndex;
      return (
        eventIds[id] +
        '<br>Lowest：' +
        data[id][0] +
        '<br>Highest：' +
        data[id][1] +
        '<br>Average：' +
        data[id][2]
      );
    }
  }


    getcustomPolarheatmapChartformatter(days,data){   
      return function (params:any) {
        const id = params.dataIndex;
        if(data[id][2] < 0){
          return (
            days[data[id][0]] +
            '<br>Hour：' +
            data[id][1] +
            '<br>Out of range'
          ); 
        } else {
          return (
            days[data[id][0]] +
            '<br>Hour：' +
            data[id][1] +
            '<br>Count：' +
            data[id][2]
          ); 
        }
      }
    }

    getPolarBarChartSeries(series:any,data:any){
      series[0].data = data.map(function (d) {
        return d[0];});

      series[1].data = data.map(function (d) {
        return d[1] - d[0];
      });

      series[2].data = data.map(function (d) {
        return d[2];
      });

      series[3].data = data.map(function (d) {
        if (d[1] == d[0]){
          return d[1];
        } else {
          return Math.max((d[1] - d[2])/3 ,1);
        }
      });
      return series;
    }

    getTreemapChartformater(){
      const formatUtil = echarts.format;
      return function (info:any) {
        var value = info.value;
        var treePathInfo = info.treePathInfo;
        var treePath = [];
        for (var i = 1; i < treePathInfo.length; i++) {
          treePath.push(treePathInfo[i].name);
        }
        return [
          '<div class="tooltip-title">' +
            formatUtil.encodeHTML(treePath.join('/')) +
            '</div>',
          'Count: ' + formatUtil.addCommas(value)
        ].join('');
      }
    }

    requestDashboardData(requestObj){
      this.getdashboardlanding.postrequest(requestObj).pipe(takeUntil(this.$dashboardLandingdestroy)).subscribe((data)=>{
        this.dashboardLandingData = data
      },
      (err)=>{
        this.dashboardLandingData = err
      })    
    }

    cleardashboarddata() {
      this.dashboardLandingData = "";
    }
    createDashboardreqobj(previewDivs:any,dashboard_landing_mode:boolean){
      let requestObj = {}
      let widgetkeys=[]
      requestObj = {"dashboard_landing":dashboard_landing_mode,"hosts":["all"],"userTimeZone":this.userTimeZone,"timeRange":this.requesttimerange}

      let widgets = {}
      for(let obj of previewDivs) {
        if(obj.widgetName != null) {
          let widgetkey = obj.widgetName 
          widgetkeys.push(widgetkey)
        }
      }
      requestObj["widgetkeys"] = widgetkeys;
      if(requestObj["widgetkeys"].length){
        this.requestDashboardData(requestObj);
      }
    }
  
    createTimerange(){
      var timerange = this.timeRangeCalc(this.defaultDate);
      var utctimerange:any = [this.timeZoneService.toUtcTimeZonefromUser(timerange[0]).format(this.timeformat),this.timeZoneService.toUtcTimeZonefromUser(timerange[1]).format(this.timeformat)];
      this.defaulttimerange = utctimerange[1].slice(0,10)
      this.requesttimerange =  [...utctimerange]
    }

    create_request_object_dashboard_widgets(previewDivs){
      this.createTimerange()
      this.createDashboardreqobj(previewDivs,true)
      return this.defaulttimerange
    }
    create_request_object_widgets(previewDivs){
      this.createTimerange()
      this.createDashboardreqobj(previewDivs,false)
      return this.defaulttimerange
    }

}
