
export class ErrorMsgHandler {

    constructor(){ }

    status:boolean = false;

    errmsg : string

    isError(){
        return {err:this.status,errmsg:this.errmsg};
    }

    seterror(err:boolean,errmsg:string){
        this.status = err;
        if(err){
            if(!errmsg){
                this.errmsg = 'Unable to process your request at this moment'
            }else{
                this.errmsg = errmsg;
            }
        }
    }

    setmsg(errmsg:string){
        this.errmsg = errmsg;
    }

    resetError(){
        this.status = false;
        this.errmsg = "";
    }


}