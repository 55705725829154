import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserLoginService } from '../user-login/user-login.service';
import { SettingsService } from './settings.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit,OnDestroy {

  constructor() { }

  async ngOnInit(){
    /* this.mat_spinner_settingsMenu_state=true
    await this.settingsService.getSettingsMenu({"user_name":this.userLoginService.userName})
    .pipe(takeUntil(this.$destroy))
    .toPromise()
    .then((val)=>{
    this.mat_spinner_settingsMenu_state=false
    this.menuList = val
    }) */
  }

  /* changeTab(event:number){
    this.selectedIndex = event;
    if(this.selectedIndex==0){
      this.settingsService.getRoles()
      this.userLanding.load_userList()
    }
    if(this.selectedIndex==1){
      this.roleLanding.load_roleList()
      this.settingsService.getMenuList();
      this.settingsService.getroleUserList();
    }
  } */

  ngOnDestroy(): void {
  }
}
