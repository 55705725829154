<div class="container">
  <header class="header-wrapper">
    <div class="header">
      <div class="logo" routerLink="/home">
        <img class="siem" src="assets/newLogo5.svg">
      </div>
      <div class="route-crumps">
        <ng-container *ngFor="let crump of crumpService.route_crumps; index as i; let lindex = last;let findex = first">
          <ng-container *ngIf="!findex">
            <p [ngClass]="lindex?'':'route-clickable'" (click)="crump_click(crump,i)">{{crump.disp_str}}</p>
            <p *ngIf="!lindex"> > </p>
          </ng-container>
        </ng-container>
      </div>
      <div class="menu-user" #userMenuOrigin>
        <img class="changeCustomer" src="assets/menuicons/changeCustomer.svg" (click)="openOrganization_click()"
          matTooltip="Change customer">
        <img (click)="openOverlay_click(userMenuRef,userMenuOrigin)" class="profile" src="assets/userprofile.svg"
          matTooltip="Profile">
        <img class="menu-icon" src="assets/bell.svg">
        <img class="menu-icon" src="assets/apps.svg">
      </div>
    </div>

  </header>
  <div class="body-wrapper">
    <ng-container *ngIf="mat_spinner_userProfile_state">
      <div class="body-bottom-spinner">
        <mat-spinner class="spinner" diameter=20></mat-spinner>
      </div>
    </ng-container>
    <div class="body-wrapper-navbar">
      <div class="navbar-icon-container">
        <div [ngClass]="crumpService.homeIcon.isSelected?'module-selected':'module-deselected'"
          (click)="navIconclick(crumpService.homeIcon)">
          <!-- <div class="module-deselected" (click)="navIconclick(crumpService.homeIcon)"> -->
          <img [src]="crumpService.homeIcon.img_url" [matTooltip]="crumpService.homeIcon.label" class="module-active">
        </div>
      </div>
      <ng-container *ngFor="let applist of crumpService.applist">
        <span class="divider"></span>

        <div class="navbar-icon-container">
          <ng-container *ngFor="let nav of applist">
            <div [ngClass]="nav.isSelected?'module-selected':'module-deselected'" (click)="navIconclick(nav)">
              <img [ngClass]="nav.isSelectable? 'module-active' :'module-inactive'" [src]="nav.img_url"
                [matTooltip]="nav.label">
            </div>
          </ng-container>
        </div>

      </ng-container>
    </div>
    <div class="body-wrapper-content">
      <router-outlet></router-outlet>
    </div>

    <ng-template #userMenuRef>
      <div class="user-menu-container">
        <div class="user-menu-wrapper">
          <div>
          </div>
          <div class="row-1 menu" (click)="openMyProfile_click()">
            <i class="my-profile"></i><span>My Profile</span>
          </div>
          <div class="row-3 menu" (click)="openResetPassword_click()">
            <i class="reset-password"></i><span>Reset Password</span>
          </div>
          <div class="row-4 menu" (click)="logout()">
            <i class="logout"></i><span>Logout</span>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
  <footer class="footer-wrapper">
    <p class="copyright">&copy;{{footer?.copyright}}</p>
    <p class="version">{{footer?.version}}</p>
  </footer>
</div>