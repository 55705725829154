
import { AbstractControl, AsyncValidatorFn, ValidationErrors} from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SettingsService } from '../settings.service';
  
  export class RoleNameValidator {
    static createValidator(settingService: SettingsService): AsyncValidatorFn {
      return (control: AbstractControl): Observable<ValidationErrors> => {
        return settingService
          .checkRoleNameExists(control.value)
          .pipe(
            map((result: any) =>{
              
              if(result.roleNameExists){ 
                return {roleNameExists: true }
              }else{
                return null
              } 
            })
          );
      };
    }
  }