import { Injectable } from "@angular/core";
import { FormControl } from "@angular/forms";
import { ErrorMsgHandler } from "../shared/errormanagement/handleerror";
import { UserLoginService } from "../user-login/user-login.service";

@Injectable() 

export class SettingsVarService {
    constructor(
        public userloginservice:UserLoginService
    ){
        this.userloginservice.userLoggedoff_.subscribe((val)=>{
            console.log("trigger user logout")
            this.resetUserlandingPages();
            this.resetRolelandingPages();
            this.userList.length = 0;
            this.role_list.length = 0;
            this.userPagination.setValue(this.userloginservice.metaData.studio.studioDefault.Pagination);
            this.rolePagination.setValue(this.userloginservice.metaData.studio.studioDefault.Pagination);
            this.errorhandleuserlanding.resetError();
            this.errorhandlerolelanding.resetError();
        })
    }

    commonData: any = this.userloginservice.metaData.commonData;//common data
    nodata = this.userloginservice.metaData.alert.noDatamsg[1]

    // user landing variables
    errorhandleuserlanding = new ErrorMsgHandler();
    userTableHeader:any = this.userloginservice.metaData.settings.usertableHeader;

   userList:any = [];

    pages = {   //pagination
        presentPage: 1,
        length: 1
    };

    userPagination = new FormControl(this.userloginservice.metaData.studio.studioDefault.Pagination);

    //role landing variables
    errorhandlerolelanding = new ErrorMsgHandler();
    roleTableHeader:any = this.userloginservice.metaData.settings.roletableHeader;

    role_list:any = [];

    role_pages = {   //role pagination
        presentPage: 1,
        length: 1
    };

    rolePagination = new FormControl(this.userloginservice.metaData.studio.studioDefault.Pagination);

    resetUserlandingPages(){
        this.pages.presentPage = 1;
        this.pages.length = 1;
    }

    resetRolelandingPages(){
        this.role_pages.presentPage = 1;
        this.role_pages.length = 1;
    }

}