import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DatePickerService } from '../date-picker.service';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers:[
    {
      provide:NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: DatePickerComponent
    }
  ]
})
export class DatePickerComponent implements OnInit, ControlValueAccessor {

  constructor(private datePickerService:DatePickerService) { }

  ngOnInit(): void { }

  onTouch: any = () => {}
  onChange:any = (_value:any) =>{}
  editValue:number[];
  isDisabled:boolean;

  writeValue(obj: any): void {
    if(obj){
      this.editValue = obj;
    }else{
      this.editValue = []
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled
  }

  chooseDates_click(origin:TemplateRef<any>) {
    if(!this.isDisabled){
      const dialogRef = this.datePickerService.open(origin,this.editValue)
      this.onTouch()
      dialogRef.overlayRef.backdropClick().subscribe(dat=>{
        dialogRef._dateValues.subscribe((dateValue)=>{
          this.onChange(dateValue)
          this.editValue = dateValue;
        })
        dialogRef._dateValues.unsubscribe();
        dialogRef.close_Overlay_backdrop();
      })
    }
    
  }

}
