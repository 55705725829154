<div class="dashboard-container">
    <div class="layout" [style.--cols]="previewLayout.cols">
        <ng-container *ngFor=" let obj of previewLayout.previewDivs">
            <div [id]="obj.id" [class]="obj.cls">
                <app-dashboard-widget *ngIf="obj.widgetType =='Table' || obj.widgetType =='Chart'" [widgetName]=obj.widgetName
                    [widgetType]=obj.widgetType [widgetImg]=obj.widgetImg [dialogWidth]="'60%'"
                    [moreOptionEnable]="true" [defaultDate]="defaulttimerange" (click)="$event.stopPropagation()">
                </app-dashboard-widget>
                <app-dashboard-label *ngIf="obj.widgetType=='Label'" [widgetName]=obj.widgetName
                    [widgetImg]=obj.widgetImg [defaultDate]="defaulttimerange">
                </app-dashboard-label>
            </div>
        </ng-container>
    </div>
</div>
