import { HttpClient, HttpHeaders } from "@angular/common/http"
import { Observable, Subject, Subscription } from "rxjs"

enum EmitStates {
    Fetchforce = -1,
    Fetch = 1,
    AlreadyFetched = 0
}

enum ResponseStates {
    ResponseDefault = -1,
    RespondedPending = 0
}

export class API_STATEMANAGEMENT {

    public emitstate:number = EmitStates.Fetchforce;
    private storeData:any = null;
    private requestTimeout:any;
    private refreshInterval:number = 600000;
    private isProgress_:Subject<boolean> = new Subject();
    isProgress$ = this.isProgress_.asObservable();
    private isprogress:boolean = false;
    private emitData:Subject<any> = new Subject();
    private subscription:Subscription = Subscription.EMPTY;
    private timeIntervalState:boolean = false;
    public responsestate:number = ResponseStates.ResponseDefault;

    constructor(
        private requestmethod:Function,
        public http:HttpClient,
        private destroyed:Observable<number>,
        private userloggedout:Observable<boolean>,
    ){
        this.destroyed.subscribe((val:number)=>{
            if(!this.isprogress){
                this.changeEmit(val);
            }else{
                this.emitstate = EmitStates.AlreadyFetched;
            }
        });
        this.userloggedout.subscribe((val:any)=>{
            this.cancelRequest();
            this.clearStoredData();
        });
    }

    toStringify(postData:any){
        return JSON.stringify(postData)
    }

    headers = new HttpHeaders({'content-type': 'application/json'})

    postrequest(requestobj:any){
        if(!this.isprogress){
            this.isProgress_.next(true);
            this.emitData = new Subject();
            this.responsestate = ResponseStates.RespondedPending;
            if(this.emitstate){
                this.subscription = this.requestmethod(this.toStringify(requestobj)).subscribe((dat:any)=>{
                    if(this.emitstate){
                        this.emitData.next(dat);
                        this.responsestate = EmitStates.Fetchforce;
                    }else{
                        this.storeData = dat;
                        this.responsestate = 0;
                    }
                    
                    this.isProgress_.next(false);
                    this.isprogress = false;
                    if(this.emitstate == EmitStates.Fetchforce){
                        this.emitstate = EmitStates.Fetch;
                        if(!this.timeIntervalState){
                            this.resetstoreddata();
                            this.timeIntervalState = true;
                        }
                    }
                },(err:any)=>{
                    this.emitstate = EmitStates.Fetchforce;
                    this.responsestate = ResponseStates.ResponseDefault
                    this.isprogress = false;
                    this.emitData.error(err);
                })
            }else{
                setTimeout(() => {
                    this.emitData.next(this.storeData);
                    this.isProgress_.next(false);
                    this.isprogress = false;
                    this.responsestate = ResponseStates.ResponseDefault;
                    this.storeData = null;
                    this.changeEmit(EmitStates.Fetch);
                },0);
            }
        }        
        return this.emitData.asObservable();
    }

    getemitstate(){
        return this.emitstate;
    }

    changeEmit(val:number){
        if(this.emitstate != EmitStates.Fetchforce){
            this.emitstate = val;
        }
    }

    refresh(){
        this.emitstate = -1;
        this.isProgress_.next(false);
        this.clearReset();
        this.resetstoreddata();
    }

    resetstoreddata(){
        this.requestTimeout = setTimeout(()=>{
            this.timeIntervalState = false;
            this.emitstate = EmitStates.Fetchforce;
        },this.refreshInterval);
    }

    clearReset(){
        clearTimeout(this.requestTimeout)
    }

    cancelRequest(){
        this.stopRequest();
        this.subscription.unsubscribe();
    }

    clearStoredData(){
        this.storeData = null;
    }

    getprogress(){
        return this.isprogress;
    }

    stopRequest(){
        this.emitstate = EmitStates.Fetchforce;
        this.isProgress_.next(false);
    }
}