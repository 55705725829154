<div class="assets" id="asset-container" [formGroup]="assets">
    <div class="assets-container">
        <div class="platform-panel">
            <div class="platform-fields">
                <img [ngClass]="assetsService.assetsSpinnerState ? 'disable-action':'enable-action'"
                    src="assets/asset_host/left-arrow.svg" (click)="moveplatformlist(-1)">
                <div #platform_list class="platform-list"
                    [ngClass]="data[1].disable || assetsService.assetsSpinnerState ?'disable-platform':'enable-platform'">
                    <ng-container *ngFor="let platforms of platformdata; let i = index">
                        <span tabindex="-1" (click)="selectPlatform_click(platforms)" scrolltoelem
                            [isvalue]="platform.value == platforms.value"
                            [ngClass]="{'selected-platform': platform.value == platforms.value}">
                            {{platforms.disp}}
                        </span>
                    </ng-container>
                </div>
                <img [ngClass]="assetsService.assetsSpinnerState ? 'disable-action':'enable-action'"
                    src="assets/asset_host/right-arrow.svg" (click)="moveplatformlist(1)">
            </div>
        </div>
        <!-- <div class="platform-search"> -->
        <div class="platform-hosts-selected">
            {{assetslabels[0]}}
            <div class="selected-tags-group" *ngIf="toggleControl.value">
                <ng-container *ngFor="let tag of limitNoOfItems(selectedTagsArray,5); let i = index">
                    <span class="selected-tag">
                        <span class="dot" [ngStyle]="tagChipColor(tag)"></span>
                        <span class="seltext">{{tag.disp| limitCharacter:8}}</span>
                        <span class="sellength"></span>
                        <img src="assets/alerts/close.svg" width="12" height="12" (click)="clearTagItem(tag)">
                    </span>
                </ng-container>
                <span *ngIf="selectedTagsArray.size >=6" class="showmore"
                    (click)="openMoreHosts_click(source,moreOrigin)">more..
                    <span class="more-num" #moreOrigin>5+</span>
                </span>
                <ng-template #source>
                    <div class="source-container">
                        <span *ngFor="let src of remainingItems(selectedTagsArray,5); let i = index">
                            <span class="dot" [ngStyle]="tagChipColor(src)"></span>
                            <span class="text">{{src.disp| limitCharacter:8}}</span>
                            <img src="assets/alerts/close.svg" width="10" height="10" (click)="clearTagItem(src)">
                        </span>
                    </div>
                </ng-template>
            </div>
        </div>
        <div class="platform-hosts">
            <ng-container *ngIf="assetsService.assetsSpinnerState">
                <div class="body-bottom-spinner">
                    <mat-spinner class="spinner" diameter=20></mat-spinner>
                </div>
            </ng-container>
            <ul *ngIf="!toggleControl.value && !assetsService.assetsSpinnerState">
                <ng-container>
                    <ng-container *ngFor="let host of selectedHostsArray ; let i = index" formGroupName="totalHosts">
                        <li (click)="selectHost_click(host)">
                            <label class="checkbox-container" [matTooltip]="host">{{host
                                | limitCharacter:20}}
                                <span class="dot" *ngIf="toggleControl.value" [style.background-color]="blue"></span>
                                <input type="checkbox" [formControlName]="host" (click)="$event.stopPropagation()">
                                <span class="checkmark"></span>
                            </label>
                        </li>
                    </ng-container>
                </ng-container>
                <!-- message to be shown if no result for searched values -->
                <li *ngIf="selectedHostsArray.length == 0" class="no-result">{{assetsService.lables.noresult}}</li>
            </ul>
            <ul *ngIf="toggleControl.value && !assetsService.assetsSpinnerState">

                <ng-container *ngFor="let host of tagsHost">
                    <li #hosts>
                        <label class="checkbox-container" [matTooltip]="host.hostname"
                            (click)="hostToggle_click(host)">{{host.hostname
                            | limitCharacter:20}}
                            <span class="dot" [ngStyle]="tagsStructure|colorPicker:host.member"></span>
                            <input type="checkbox" [checked]="host.selected" (click)="$event.stopPropagation()">
                            <span class="checkmark"></span>
                        </label>
                    </li>
                </ng-container>

                <li *ngIf="!tagsHost.length" class="no-result">{{assetsService.lables.noresult}}</li>
            </ul>
        </div>
        <div class="assetsSubmit">
            <!-- to close the dropdown -->
            <button mat-button class="cancel"
                [ngClass]="assetsService.assetsSpinnerState ? 'disable-action':'enable-action'" (click)="formCancel()"
                tabindex="0">{{assetsService.lables.cancel}}</button>
            <!-- to apply the selected hosts for search -->
            <button mat-button class="submit"
                [ngClass]="assetsService.assetsSpinnerState ? 'disable-action':'enable-action'" (click)="formSubmit()"
                tabindex="1">{{assetsService.lables.apply}}</button>
        </div>
    </div>
    <div class="tags-container">
        <div class="tags-header">
            <div class="tag-header-col-1">
                <p>{{assetslabels[1]}}</p>
                <label class="switch">
                    <input type="checkbox" formControlName="toggleControl">
                    <span class="slider round"></span>
                </label>
                <p>{{assetslabels[2]}}</p>
            </div>
            <p class="clear-btn" [ngClass]="assetsService.assetsSpinnerState ? 'disable-action':'enable-action'"
                (click)="clearBtn()">Clear</p>
        </div>
        <div class="tags-body">
            <div class="custom-search">
                <!-- To search hosts -->
                <img src="assets/search-small.svg">
                <input type="search" placeholder="Type to search here" formControlName="searchValue">
            </div>
            <ul *ngIf='toggleControl.value' class='tags-section'>
                <ng-container *ngIf="assetsService.assetsSpinnerState">
                    <div class="body-bottom-spinner">
                        <mat-spinner class="spinner" diameter=20></mat-spinner>
                    </div>
                </ng-container>
                <ng-container *ngIf="!assetsService.assetsSpinnerState">
                    <ng-container formGroupName="totalTags">
                        <ng-container *ngFor="let category of tagsCategoriesObj | keyvalue">
                            <li *ngIf="category.value.length>0" [formGroupName]="category.key" class="label">
                                {{category.key}}
                                <ng-container *ngFor="let tag of category.value">
                            <li (click)="selectTags_click(category.key,tag)">
                                <span class="checkbox-container" [matTooltip]="tag.disp">{{tag.disp |
                                    limitCharacter:20}}
                                    <input type="checkbox" [formControlName]="tag.disp"
                                        (click)="$event.stopPropagation()">
                                    <span class="checkmark"></span>
                                </span>
                            </li>
                        </ng-container>
                        </li>
                    </ng-container>
                    <li *ngIf="tagsCategoriesObj|novalueAsset:shownohostmsg" class="no-tags">{{assetslabels[3]}}</li>
                </ng-container>
                </ng-container>

            </ul>
            <ul *ngIf='!toggleControl.value' class='tags-section'>
                <ng-container *ngIf="assetsService.assetsSpinnerState">
                    <div class="body-bottom-spinner">
                        <mat-spinner class="spinner" diameter=20></mat-spinner>
                    </div>
                </ng-container>
                <ng-container *ngIf="!assetsService.assetsSpinnerState">
                    <ng-container *ngFor="let host of filteredHostArray; let i = index">
                        <li tabindex="-1" (click)="selectHost_click(host.value)" #hostLabel>
                            <span class="checkbox-container" [formGroup]="totalHosts"
                                [matTooltip]="host.disp">{{host.disp |
                                limitCharacter:20}}
                                <input type="checkbox" [formControlName]="host.value"
                                    (click)="$event.stopPropagation()">
                                <span class="checkmark"></span>
                            </span>
                        </li>
                    </ng-container>
                    <li *ngIf="!filteredHostArray.length" class="no-tags">{{assetslabels[4]}}</li>
                </ng-container>
            </ul>
        </div>
    </div>
</div>