import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserLoginService } from 'src/app/user-login/user-login.service';

@Component({
  selector: 'app-change-validation',
  templateUrl: './change-validation.component.html',
  styleUrls: ['./change-validation.component.scss']
})
export class ChangeValidationComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private loginService: UserLoginService) {

  }
  submit() {
    this.loginService.$customerChanged.next(this.data.customer)
  }
}
