import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name:'combineError',
})

//this pipe returns errors one by one in condition values
export class CombineError implements PipeTransform {
    transform(errorMsg:string[], conditionValueError: any, conditionGroupError:any):any {
        let allErrors = {...conditionValueError, ...conditionGroupError }
        return errorMsg[Object.keys(allErrors)[0]]
    }
}