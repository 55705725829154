import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name:'arrayLimiter'
})


export class ArrayLengthPipe implements PipeTransform{
transform(
    value:any,
    args: any):any { 
        let checkArr = []
        if(value){
          checkArr = []
          args.forEach((item)=>{
            if(item.tags === value){
                checkArr.push(item)
            }
          })
          return checkArr.length
        }
}
}