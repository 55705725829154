
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { ErrorService } from './err.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    
    constructor(private errorService:ErrorService){}
    handleError(error:CustomError) {
        //don't comment this console
        console.log(error)
        if(!(error instanceof HttpErrorResponse) && !(error.skip)){
            this.errorService.handleClientErrors(error)
        }
    }
}

interface CustomError extends Error{
    skip?:boolean
}