import { Component, Input, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/services/dashboard.service';

@Component({
  selector: 'explore-dashboard',
  templateUrl: './explore-dashboard.component.html',
  styleUrls: ['./explore-dashboard.component.scss']
})
export class ExploreDashboardComponent implements OnInit{

  @Input() previewLayout:any = null;
  defaulttimerange:any;

  constructor(
    private dashboardService:DashboardService
  ){}

  ngOnInit(): void {
    this.defaulttimerange = this.dashboardService.create_request_object_dashboard_widgets(this.previewLayout.previewDivs);
  }

}
