import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToastService } from 'src/app/shared/custom-overlay/toast/toast.service';
import { ErrorMsgHandler } from 'src/app/shared/errormanagement/handleerror';
import { UserLoginService } from 'src/app/user-login/user-login.service';
import { SettingsService } from '../settings.service';
import { SameModuleNameValidator } from '../validators/sameModuleName.validators';

@Component({
  selector: 'app-fields',
  templateUrl: './fields.component.html',
  styleUrls: ['./fields.component.scss']
})
export class FieldsComponent implements OnInit, OnDestroy {

  details;
  buttonLabels;
  fieldsMetaData;
  showErrorState: boolean = false;
  fieldsDetails: UntypedFormGroup;
  inputBoxEmitChar = this.loginService.metaData.commonData.specialCharList.charList;
  numCharList = this.loginService.metaData.commonData.specialCharList.numCharList;
  mat_spinner_fieldList_state: boolean;
  toasterMessage: string;
  nodata = this.loginService.metaData.alert.noDatamsg[1]
  errorhandlefields = new ErrorMsgHandler()
  nodatastate: boolean;
  platformlist = this.loginService.metaData.commonData.platformOptions.platformList;
  $destroy: Subject<boolean> = new Subject();

  platformField: UntypedFormGroup = this.fb.group({
    platform: [this.loginService.metaData.commonData.platformOptions.default, [Validators.required]]
  })

  constructor(
    private fb: UntypedFormBuilder,
    private loginService: UserLoginService,
    private settingsService: SettingsService,
    private toaster: ToastService
  ) { }

  newParent(val) {
    return this.fb.group({
      fieldName: [val.fieldName, Validators.required],
      childArray: this.fb.array([], [SameModuleNameValidator()])
    })
  }

  newChild(val) {
    return this.fb.group({
      fieldName: [val.fieldName, Validators.required],
      moduleRef: [val.moduleRef, Validators.required],
      fieldDisplay: [val.fieldDisplay, [Validators.required, Validators.maxLength(30), Validators.minLength(3)]],
      dataType: [val.dataType, Validators.required],
      isDisp: [val.isDisp, Validators.required],
      isFilter: [val.isFilter, Validators.required],
      dispOrder: [val.dispOrder, Validators.required],
      fieldId: [val.fieldId, Validators.required],
      platformId: [val.platformId, Validators.required]
    })
  }
  get platform() {
    return this.platformField.get('platform') as UntypedFormControl
  }
  get parentArray(): UntypedFormArray {
    return this.fieldsDetails.get('parentArray') as UntypedFormArray
  }

  childArray(parentIndex: number): UntypedFormArray {
    return this.parentArray.at(parentIndex).get('childArray') as UntypedFormArray;
  }

  ngOnInit() {
    this.fieldsMetaData = this.loginService.metaData.settings.fieldsData;
    this.buttonLabels = this.loginService.metaData.commonData.buttonLabels;
    this.errorhandlefields.status = false
    
    this.platform.valueChanges
      .pipe(takeUntil(this.$destroy))
      .subscribe((data: any) => {
        this.loadFields_list(data)
      })
    this.settingsService.fiedsDetails(this.platform.value)
      .pipe(takeUntil(this.$destroy))
      .subscribe((data: any) => {
        this.mat_spinner_fieldList_state = false
        if(data.parentArray){
          this.nodatastate = false;
          this.details = data
          this.addControl();
        } else {
          this.details = []
          this.nodatastate = true;
        }
      },
      (err)=>{
        console.log(err)
        this.mat_spinner_fieldList_state = false
        this.errorhandlefields.seterror(true,err.errmsg)
      })
    this.fieldsDetails = this.fb.group({
      parentArray: this.fb.array([])
    })
  }

  addControl() {
    for (var i = 0; i < this.details.parentArray.length; i++) {
      this.parentArray.push(this.newParent(this.details.parentArray[i]));
      for (var j = 0; j < this.details.parentArray[i].childArray.length; j++) {
        if (this.details.parentArray[i].childArray[j].moduleRef == 'c') {
          this.details.parentArray[i].childArray[j].moduleRef = "All modules"
        }
        this.childArray(i).push(this.newChild(this.details.parentArray[i].childArray[j]));
      }
    }
  }

  addChild(parentIndex: number, childIndex: number) {
    let defaultValue = JSON.parse(JSON.stringify(this.childArray(parentIndex).value[0]));
    defaultValue.moduleRef = "";
    this.childArray(parentIndex).push(this.newChild(defaultValue))
  }

  async loadFields_list(platform) {
    this.mat_spinner_fieldList_state = true
    await this.settingsService.fiedsDetails(platform)
      .pipe(takeUntil(this.$destroy))
      .toPromise()
      .then((data: any) => {
        this.mat_spinner_fieldList_state = false
        if(data.parentArray){
          this.nodatastate = false;
          this.details = data;
          this.parentArray.clear();
          this.addControl();
        } else {
          this.details = [];
          this.nodatastate = true;
        }
       
      })
      .catch((err)=>{
        console.log(err)
        this.mat_spinner_fieldList_state = false
        this.errorhandlefields.seterror(true,err.errmsg)
      })
  }

  deleteChild(parentIndex, childIndex) {
    this.childArray(parentIndex).removeAt(childIndex);
    this.childArray(parentIndex).markAsDirty();
  }


  childControl(parentIndex: any, childIndex: any) {
    return this.childArray(parentIndex).at(childIndex) as UntypedFormGroup
  }

  submit_click() {
    if (this.fieldsDetails.dirty) {
      this.showErrorState = false;
      var changedRows = [];
      for (let i = 0; i < this.parentArray.length; i++) {
        for (let j = 0; j < this.parentArray.controls[i].value.childArray.length; j++) {
          if (this.parentArray.controls[i].dirty) {
            changedRows.push(this.parentArray.controls[i].value)
          }
        }
      }
      var fieldsPostReq = { data: changedRows, platform_desc: this.platform.value }
      if (changedRows.length > 0) {
        this.settingsService.postFieldsDetails(fieldsPostReq)
          .pipe(takeUntil(this.$destroy))
          .toPromise()
          .then((result) => {
            this.toasterMessage = this.fieldsMetaData.result.success
            this.settingsService.showToaster(this.toasterMessage, 'success')
            this.loadFields_list(this.platform.value);
            changedRows = []
          })
          .catch((err) => {
            this.toasterMessage = this.fieldsMetaData.result.failure
            this.settingsService.showToaster(this.toasterMessage, 'warning')
          })
      } else {
        this.toasterMessage = this.fieldsMetaData.fieldDisplay.commonFieldErr
        this.settingsService.showToaster(this.toasterMessage, 'warning')
        this.loadFields_list(this.platform.value)
      }
    } else {
      this.showErrorState = true;
    }
  }

  cancel_click() {
    this.parentArray.clear();
    this.addControl();
  }

  specialChar(e) {
    if (e.key == '-' || e.key == 'e' || e.key == 'E' || e.key == '+' || e.key == '.') {
      e.preventDefault()
    }
  }

  ngOnDestroy(): void {
    this.$destroy.next()
    this.$destroy.unsubscribe()
  }
}
