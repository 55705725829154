import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfirmedValidator } from 'src/app/shared/validators/matchPassword.validator';
import { VerifyPasswordCombination } from 'src/app/shared/validators/passwordStrength.validator';
import { PrePasswordValidator } from 'src/app/shared/validators/prepwdAsync.validator';
import { UserLoginService } from '../user-login.service';
import { ToastService } from 'src/app/shared/custom-overlay/toast/toast.service';

@Component({
  selector: 'app-resetpwd',
  templateUrl: './reset-pwd.component.html',
  styleUrls: ['./reset-pwd.component.scss']
})
export class ResetpwdComponent implements OnInit {
  metaData;
  strongPassword:boolean;
  showPass1:boolean;
  showPass2:boolean;
  showPass3:boolean;
  pwd:UntypedFormGroup;
  errorMessage:boolean = false;
  commonData;

  $destroy: Subject<boolean> = new Subject()
  resetPasswordSpinner:boolean = false;

  constructor(public fb:UntypedFormBuilder,
    private ref:MatDialogRef<ResetpwdComponent>,
    private userService:UserLoginService,
    private toastservice: ToastService,
    @Inject(MAT_DIALOG_DATA) public data:any,
    ) { {ref.disableClose = true;}
    }

  inputPlaceholders=['Enter Old password', 'Enter New password']

  validationData = this.userService.metaData.commonData.userDetailvalidationData

  ngOnInit(): void {
    this.metaData = this.userService.metaData.userLogin.resetPwd;
    this.strongPassword=this.metaData.passwordStrength.strength;
    this.commonData = this.userService.metaData.commonData;
    this.pwd = this.fb.group({
      oldpassword:[null,[Validators.required, 
      ]],
      newpassword:['',[
        Validators.required,
        VerifyPasswordCombination(this.strongPassword),
        Validators.minLength(this.validationData.password.minlength),
        Validators.maxLength(this.validationData.password.maxlength)],
        [PrePasswordValidator.createValidator(this.userService)]
      ],
      reenterpassword:['',[
        Validators.required,
        Validators.minLength(this.validationData.password.minlength),
        Validators.maxLength(this.validationData.password.maxlength),]]
    },{
       validators:ConfirmedValidator('newpassword','reenterpassword')
    })
 }
  
  get oldpassword(){
    return this.pwd.get('oldpassword');
  }

  get passwordnew(){
    return this.pwd.get('newpassword');
  }

  get reEnterPwd(){
    return this.pwd.get('reenterpassword');
  }

  get resetPwd(){
    return this.pwd.controls;
  }

  cancel_click(){
    this.ref.close();
  }

  submit_click(){
    if(this.pwd.valid){
      this.resetPasswordSpinner = true;
      this.errorMessage=false;
      this.userService.postResetPwd(this.pwd.value)
      .pipe(takeUntil(this.$destroy))
      .subscribe((res)=>{
        this.resetPasswordSpinner = false;
        this.ref.close();
        if(res['status']){
          this.toastservice.show({
            type:"success",
            text:res['status']
          })
        } else {
          this.toastservice.show({
            type:"failure",
            text:"Reset password failed"
          })
        }
        
      },
      (err)=>{
        this.resetPasswordSpinner = false;
      })
    }
    else{
      this.errorMessage=true;
    }
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
  }
}
