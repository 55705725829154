import { Directive, ElementRef, Input, OnInit} from '@angular/core';

@Directive({
  selector: '[scrolltoelem]'
})
export class ScrollDirective implements OnInit{

    @Input() isvalue:boolean = false;

    constructor(private elemref:ElementRef<HTMLElement>) {}

    ngOnInit(): void {
        if(this.isvalue){
            setTimeout(()=>{
                this.elemref.nativeElement.scrollIntoView({behavior:'smooth'});
            },0)
        }
        
    }


}