import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { ToastService } from "../shared/custom-overlay/toast/toast.service";
import { UserLoginService } from "../user-login/user-login.service";
import { AuthService } from '../auth/auth.service';
import { of } from "rxjs";

@Injectable({
    providedIn:'root'
})

export class ErrorService implements OnInit{

    offlineError = false;
    clientError = false;
    errorArray=[]
    serverErrorArray=[]
    headers = new HttpHeaders({'content-type': 'application/json','charset':'utf-8'});
    offlineSubscription:any;
    httpSubscription:any;
    httpErrors = 0
    
    constructor(private Toaster:ToastService,
        private loginService:UserLoginService,
        private http:HttpClient,
        private route:Router,
        private authService:AuthService
    ) {}

    ngOnInit() {}

    errmsg = {
        browerr_msg : "Unable to process your request",
        browerr_offline : 'You are currently offline',
        browerr_online: 'You are back online',
        cli_offline : [999,1],
        cli_online : [999,1],
        client_err : [999, 1],
        server_down : [999, 1],
        server_err : [999, 1],
        production_status:false
    }

    modulenames = {
        rule :'Rule',
        alert : 'Alert',
        logsearch : 'Logsearch',
        dashboard : 'Dashboard',
        studio : 'Studio'
    }

    checkErrors(errors){
        return errors
    }

    checkProductionErrors(errors){
        if(errors.severity_code != 3){
            return errors
        }else{
            return null
        }
    }

    errorCheck={
        check:this.checkErrors
    }

    getErrorMetadata(){
        this.http.post(environment.apiUrl+'/error/errorMetadata/get',{},{headers:this.headers})
        .subscribe((val:any)=>{
            this.errmsg = {...val.errmsg,...val.refCode,production_status:val.production_status};
            if(this.errmsg.production_status){
                this.errorCheck.check = this.checkProductionErrors
            }
        })
    }

    userOffline(status:boolean){
        if(this.offlineError === false){
            this.showError(this.errmsg.browerr_offline,'failure');
            this.offlineError = true
            if(status){
                this.errorArray.push(this.clientObjectBuilder(1,this.errmsg.browerr_offline))
                localStorage.setItem('offlineErrors',JSON.stringify(this.errorArray))
            }
            this.offlineSubscription = setTimeout(()=>{ clearTimeout(this.offlineSubscription),this.offlineError = false},5000)
        }
    }

    userOnline(){
        const errorObj = this.clientObjectBuilder(2, this.errmsg.browerr_online)
        this.postOfflineError(JSON.stringify([errorObj]))
        .subscribe(val=>{
        })
    }

    handleHttpErrors(error:HttpErrorResponse,showstate:Number){
        if(error.error?.refcode){
            if(error.status==419){
                if(this.httpErrors<1 && showstate){
                    this.showError(this.addModuleName(error.error.errmsg,error),'failure')
                    this.httpErrors++
                }
                this.postOnlineErrors(this.httpobjectBuilder(1,1,error),0)
                this.authService.logout().subscribe((val)=> this.route.navigateByUrl('login'))
            }
            else if(error.status == 418){
                if(this.httpErrors<1 && showstate){
                    this.showError(this.addModuleName(error.error.errmsg,error),'failure')
                    this.httpErrors++
                }              
                this.postOnlineErrors(this.httpobjectBuilder(1,1,error),1)
            }
        }else if(error.status==503 || error.status==0){
            if(this.httpErrors<1 && showstate){
                this.showError(this.addModuleName(this.errmsg.browerr_msg,error),'failure')
                this.httpErrors++
            }
            this.saveServerError(error)
        }
        else{
            if(this.httpErrors<1 && showstate){
                this.showError(this.errmsg.browerr_msg,'failure')
                this.httpErrors++
            }
            this.postOnlineErrors(this.httpobjectBuilder(1,0,error),1)
        }
        this.httpSubscription = setTimeout(()=>{ clearTimeout(this.httpSubscription),this.httpErrors = 0},5000)
        
    }

    addModuleName(errmsg:string,error:HttpErrorResponse){
        const sperateurl = error.url.split(environment.apiUrl);
        let modulenamesplit = ''
        if(sperateurl.length && sperateurl[1]){
            const modsp = sperateurl[1].split('/');
            if(modsp.length && modsp[1]){
                modulenamesplit = modsp[1]
            }
        }
        if(this.modulenames[modulenamesplit]){
            return this.modulenames[modulenamesplit] + ': ' +errmsg
        }else{
            return modulenamesplit ? modulenamesplit + ': '+errmsg : errmsg;
        }
        
    }

    handleClientErrors(error:any){
        this.postOnlineErrors(this.clientObjectBuilder(0,error),1)
    }

    showError(message:string,type:any){
        this.Toaster.show({
            text:message,
            type:type
        })
    }

    saveServerError(error:any){
        this.serverErrorArray.push(this.httpobjectBuilder(0,0,error))
        localStorage.setItem('serverErrors',JSON.stringify(this.serverErrorArray))
    }

    handleOfflineError(){
        const offlineErrors = localStorage.getItem('offlineErrors')
        if(offlineErrors){
            this.postOfflineError(offlineErrors).subscribe(val=>{
                this.errorArray=[]
                localStorage.removeItem('offlineErrors')
                this.userOnline()
            })
        }
    }

    handleServerDown(){
        const error = localStorage.getItem('serverErrors')
        localStorage.removeItem('serverErrors')
        this.postOfflineError(error).subscribe(val=>{})
    }

    postOfflineError(errorObj:any){
        let errObj = []
        JSON.parse(errorObj)
        .forEach((obj:any)=>{
            if(this.errorCheck.check(obj)){
                errObj.push(obj)
            }
        })
        if(errObj.length){
            return this.http.post(environment.apiUrl+'/error/offline/errorLog/post',errorObj,{headers:this.headers})
        }else{
            return of(true)
        }
    }

    httpobjectBuilder(errorState:Number,customState:Number,error:any){
        if(errorState){
            if(customState){
                const httperrorObj = {
                    user_name:this.loginService.userName,
                    errmsg:error.error.errmsg,
                    errdesc:error.error.errdesc ? error.error.errdesc :'',
                    errorTime:new Date(),
                    machine:2,
                    ref_code:error.error.refcode,
                    epoch_time: error.error.epoch_time,
                    url:error.url,
                    severity_code:error.error.severity_code
                }
                return httperrorObj
            }else{
                const httperrorObj = {
                    user_name:this.loginService.userName,
                    errmsg:error,
                    errdesc:error,
                    errorTime:new Date(),
                    machine:2,
                    ref_code:this.errmsg.server_err[0].toString() + Math.floor(1000 + Math.random() * 9000).toString(),
                    epoch_time:new Date().getTime().toString(),
                    url:error?.url,
                    severity_code: this.errmsg.server_err[1].toString()
                }
                return httperrorObj
            }
        }else{
            const serverErrObj = {
                user_name:this.loginService.userName,
                errmsg:error,
                errdesc:error,
                errorTime:new Date(),
                machine:2,
                ref_code:this.errmsg.server_down[0].toString() + Math.floor(1000 + Math.random() * 9000).toString(),
                epoch_time: new Date().getTime().toString(),
                url:null,
                severity_code:this.errmsg.server_down[1]
            }
            return serverErrObj
        }
    }

    clientObjectBuilder(errorstate:Number,error){

        if(errorstate==1){
            const errorObj = {
                user_name:this.loginService.userName,
                error_msg:error,
                errdesc:error,
                errorTime:new Date(),
                machine:3,
                ref_code:this.errmsg.cli_offline[0].toString() +Math.floor(1000 + Math.random() * 9000).toString(),
                epoch_time:new Date().getTime().toString(),
                url:null,
                severity_code:this.errmsg.cli_offline[1]
            }
            return errorObj

        }else if(errorstate ==2){
            const clienterrObj = {
                user_name:this.loginService.userName,
                error_msg:error.toString(),
                errdesc:error.toString(),
                errorTime:new Date(),
                machine:3,
                ref_code: this.errmsg.cli_online[0].toString() +Math.floor(1000 + Math.random() * 9000).toString(),
                epoch_time:new Date().getTime().toString(),
                url:null,
                severity_code: this.errmsg.cli_online[1]
            }
            return clienterrObj

        }else if(errorstate == 0){

            const clienterrObj = {
                user_name:this.loginService.userName,
                error_msg:error.toString(),
                errdesc:error.toString(),
                errorTime:new Date(),
                machine:3,
                ref_code: this.errmsg.client_err[0].toString() +Math.floor(1000 + Math.random() * 9000).toString(),
                epoch_time:new Date().getTime().toString(),
                url:null,
                severity_code: this.errmsg.client_err[1]
            }
            return clienterrObj
        }
    }

    postOnlineErrors(err:any,severestate:Number){
        let error = this.errorCheck.check(err)
        if(error){
            error = JSON.stringify(error)

            if(this.loginService.$userLogged.getValue() && severestate){
                this.http.post(environment.apiUrl+'/error/online/errorLog/post',error,{headers:this.headers})
                .subscribe(val=>val)
            }else{
                this.http.post(environment.apiUrl+'/error/errorLog/post',error,{headers:this.headers})
                .subscribe(val=>val)
            }
        }
    }
}