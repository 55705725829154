import { HttpRequest, HttpEvent, HttpInterceptor, HttpResponse, HttpHandler, HttpClient } from "@angular/common/http";
import { Injectable, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { EMPTY, Observable, of, throwError } from "rxjs";
import { filter, tap, delay } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ErrorService } from "../error/err.service";
import { AuthService } from "./auth.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private authService: AuthService, private router: Router, private errorService: ErrorService) { }

    /* If the Http Client Module imported on multiple modules requires multiple interceptor. Typical interceptor
    only access the global Http Client Module. Avoid importing httpClientModule in each module to avoid this problem.
    */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        ///user/newPwd/post
        if(
            req.url == environment.apiUrl + '/user/secretQtn/get' || 
            req.url == environment.apiUrl + '/user/authCode/get' || 
            req.url == environment.apiUrl + '/user/secretValue/post' ||
            req.url == environment.apiUrl + '/user/newPwd/post' ||
            req.url == environment.apiUrl + '/offline/errorLog/post' || 
            req.url == environment.apiUrl + '/online/errorLog/post' ||
            // req.url == environment.apiUrl + '/userlogin/logout/post' || 
            req.url == environment.apiUrl + '/userlogin/check/userpwd' ||
            req.url == environment.apiUrl + '/error/errorLog/post' ||
            req.url == environment.apiUrl + '/error/errorMetadata/get'
        )
        {
            return next.handle(req)
        }
        else if(
            req.url == environment.apiUrl + '/userlogin/login/post'
        ){
            return next.handle(req).pipe(
                filter((e)=>e.type!==0),
                tap((event: any) => {
                    if (event instanceof HttpResponse) {
                        if (event.headers.has('SPL')) {
                            let payload = event.headers.get('SPL');
                            sessionStorage.setItem('SPL', payload)
                        }
    
                        if (event.headers.has('SRID')) {
                            let refreshToken = event.headers.get('SRID')
                            sessionStorage.setItem('SRID', refreshToken)
                        }
                    }
                })
            );

        }else if(
            req.url == environment.apiUrl + '/login/auth/refreshToken/get'
        ){
            if (req instanceof HttpRequest) {
                const payloadObj = sessionStorage.getItem("SPL");
                if (payloadObj) {
                    const cloned = req.clone({
                        headers: req.headers.set("SPL", payloadObj),
                        withCredentials: true,
                    });
                    return next.handle(cloned).pipe(
                        filter((e)=>e.type!==0),
                        tap((event: HttpResponse<HttpEvent<any>>) => {
                            if (event.status === 200 && sessionStorage.getItem('serverErrors')) {
                                this.errorService.handleServerDown()
                            }
                        })
                    );  
                }
                else {
                    this.authService.logout().subscribe(() => {
                        this.router.navigateByUrl('login')
                        return of(null)
                    })
                }
            }
            else{
                return next.handle(req).pipe(
                    filter((e)=>e.type!==0),
                    tap((event: any) => {
                        if (event instanceof HttpResponse) {
                            if (event.headers.has('SPL')) {
                                let payload = event.headers.get('SPL');
                                sessionStorage.setItem('SPL', payload)
                            }
        
                            if (event.headers.has('SRID')) {
                                let refreshToken = event.headers.get('SRID')
                                sessionStorage.setItem('SRID', refreshToken)
                            }
                        }
                    })
                );
            }
        }
        else{
            //return next.handle(req)
            const payloadObj = sessionStorage.getItem("SPL");
            if (payloadObj) {
                const cloned = req.clone({
                    headers: req.headers.set("SPL", payloadObj),
                    withCredentials: true,
                });
                return next.handle(cloned).pipe(
                    filter((e)=>e.type!==0),
                    tap((event: HttpResponse<HttpEvent<any>>) => {
                        if (event.status === 200 && sessionStorage.getItem('serverErrors')) {
                            this.errorService.handleServerDown()
                        }
                    })
                );  
            }
            else {
                this.authService.logout().subscribe(() => {
                    this.router.navigateByUrl('login')
                    return of(null)
                })
            }
        }
    }
}