import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Form, FormControl, FormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { minSelectedCheckboxes } from 'src/app/shared/validators/minimumChecked.validator';
import { UserLoginService } from 'src/app/user-login/user-login.service';
import { SettingsService } from '../settings.service';
import { RoleNameValidator } from '../validators/rolenameAsync.validator';
import { RoleSourceValidator } from '../validators/rolesource.validator';

@Component({
  selector: 'app-add-role',
  templateUrl: './add-role.component.html',
  styleUrls: ['./add-role.component.scss']
})
export class AddRoleComponent implements OnInit, OnDestroy {

  roleMetaData = this._service.metaData.settings.addeditrole;
  sourceList = this._service.metaData.commonData.platformOptions.platformList;
  showSettingsmenu: boolean = false
  showErrors: boolean = false
  inputBoxEmitChar = this._service.metaData.commonData.specialCharList.charList;
  mat_spinner_role_state: boolean
  $destroy: Subject<boolean> = new Subject()
  label = ["Search", "Manage", "Operate"]


  //injecting mat-dialog, settingservice and formbuilder

  constructor(private ref: MatDialogRef<AddRoleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private settingService: SettingsService,
    private fb: UntypedFormBuilder,
    private _service: UserLoginService) {
  }


  addRoleForm = this.fb.group({
    role_name: ['', [Validators.required], [RoleNameValidator.createValidator(this.settingService)]],
    product: new UntypedFormControl(),
    source: new UntypedFormControl([], RoleSourceValidator()),
    assigned_user: new UntypedFormControl(),
    menu_list: new FormGroup({
    }, { validators: [minSelectedCheckboxes()] })
  })

  ngOnInit(): void {
    this.addCheckboxes();
    if (this.data.editdata) {
      console.log(this.data.editdata)
      this.setformValues(this.data.editdata);
      this.roleName.clearAsyncValidators();

    } else {
      this.product.setValue('SIEM');
    }
    this.product.disable();
  }


  setformValues(editdata: any) {
    this.addRoleForm.patchValue(editdata);
    editdata.menu_list.forEach((menu: string) => {
      this.menu_list.get(menu).setValue(true);
    })
  }

  addCheckboxes() {
    this.data.menulist.forEach((category: any) => {
      category.menu.forEach((menu: any) => {
        this.menu_list.addControl(menu.menu_id, new FormControl(false));
      })
    })
  }

  //returning formcontrols and formArray from the form group on their respective names.

  get menu_list() {
    return this.addRoleForm.get('menu_list') as FormGroup;
  }

  get roleName() {
    return this.addRoleForm.get('role_name')
  }

  get users() {
    return this.addRoleForm.get('assigned_user')
  }

  get source() {
    return this.addRoleForm.get('source')
  }

  get product() {
    return this.addRoleForm.get('product')
  }
  //closes mat-dialog.

  close() {
    this.ref.close()
  }

  getMenuList(menu_obj: any) {
    const menu_arr = [];
    for (let menu in menu_obj) {
      if (menu_obj[menu]) {
        menu_arr.push(menu);
      }
    }
    return menu_arr;
  }

  /*Submit method checks if the form is valid. If it's invalid it triggres and shows the error messages for 
  each formControl. Also, calls the post method and sends the newly created roleObj as a body when the form is valid.*/

  submitRole_click() {
    if (this.addRoleForm.valid) {
      this.mat_spinner_role_state = true;
      var newRoleObject = {
        role_name: this.roleName.value,
        product: this.product.value,
        menu_authorized: this.getMenuList(this.menu_list.value),
        modified_dt: new Date(),
        created_by: this._service.userName,
        source: this.source.value,
        assigned_user: this.users.value
      }
      if (!this.data.editdata) {
        this.settingService.postNewrole(newRoleObject)
          .pipe(takeUntil(this.$destroy))
          .subscribe((val) => {
            this.mat_spinner_role_state = false;
            this.ref.close(val);
            this.settingService.showToaster(this.roleMetaData.result.success, 'success');
          }, (err) => {
            this.mat_spinner_role_state = false;
            // this.settingService.showToaster('Role post failed', 'failure');
          })
      }
      if (this.data.editdata) {
        var newRoleClone = { ...newRoleObject }
        let editedMiniObj = {
          created_by: this.data.editdata.created_by,
          role_id: this.data.editdata.role_id,
          role_type: this.data.editdata.role_type
        }
        let editedObj = { ...newRoleClone, ...editedMiniObj }
        this.settingService.postRoleedit(editedObj)
          .pipe(takeUntil(this.$destroy))
          .subscribe(val => {
            this.mat_spinner_role_state = false;
            this.ref.close(val)
            this.settingService.showToaster(this.roleMetaData.result.changes, 'success');

          }, (err) => {
            this.mat_spinner_role_state = false;
            // this.settingService.showToaster('Role post failed', 'failure')
          })
      }
    } else {
      this.showErrors = true;
    }
  }

  cancel() {
    this.ref.close()
  }

  ngOnDestroy(): void {
    this.$destroy.next(true)
  }

}
