import { Overlay } from '@angular/cdk/overlay';
import { Inject, Injectable, Injector } from '@angular/core';
import { ToastRef } from './toast.ref';
import { ToastConfig, ToastData, TOAST_CONFIG_TOKEN } from './toast.config';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { ToastComponent } from './toast.component';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  private toastref:ToastRef;

  constructor(
    private overlay: Overlay,
    private parentInjector: Injector,
    @Inject(TOAST_CONFIG_TOKEN) private toastConfig: ToastConfig
  ) { }

  show(data:ToastData){
    const positionStrategy = this.getPositionStrategy();
    const overlayRef = this.overlay.create({ positionStrategy ,panelClass:'toast-pannel'});
    const toastRef = new ToastRef(overlayRef);
    this.toastref = toastRef;
    const injector = this.getInjector(data, toastRef, this.parentInjector);
    const toastPortal = new ComponentPortal(ToastComponent, null, injector);
    overlayRef.attach(toastPortal);
    return toastRef;
  }

  getPositionStrategy() {
    return this.overlay.position()
      .global()
      .top(this.getPosition())
      .right(this.toastConfig.position.right + 'px');
  }

  getPosition(){
    const lastToastIsVisible = this.toastref && this.toastref.isVisible();
    const position = lastToastIsVisible 
      ? this.toastref.getPosition().bottom +20
      : this.toastConfig.position.top;
    return position + 'px';
  }

  getInjector(data: ToastData, toastRef: ToastRef, parentInjector: Injector) {
    const tokens = new WeakMap();
    tokens.set(ToastData, data);
    tokens.set(ToastRef, toastRef);

    return new PortalInjector(parentInjector, tokens);
  }
}
