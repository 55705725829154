import { Injectable, Injector, ComponentRef } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { cdkOverlayRef } from './cdk-overlay-ref';
import { CDK_OVERLAY_DROPDOWN, FILE_PREVIEW_DIALOG_DATA} from './cdk-overlay-injector';
import { DropdownOverlayComponent } from './dropdown-overlay/dropdown-overlay.component';

/*
overlay with transparent backtrop is created 
config for the overlay and other value to the component are sent through injectors
component to be displayed on the overlay is attached,
when click on the backdrop outside the component, the overlay will be closed
*/

interface cdkOverlayConfig {
  panelClass?: string;
  hasBackdrop?: boolean;
  backdropClass?: string;
  width?:string;
  height?:string,
  selectedValue?:any, //user selected value from overlay dropdown
  data?:any,  // user given inputs
  hasMultiSelect?:boolean,
  style?:any,
  valueColumn?:any,
  displayColumn?:any
  
}
interface FilePreviewDialogConfig {
  panelClass?: string;
  hasBackdrop?: boolean;
  backdropClass?: string;
  height?: string;
  width?: string;
  data?: any;
}

//default configuration for overlay
const DEFAULT_CONFIG: cdkOverlayConfig = {
  hasBackdrop: true,
  backdropClass: 'cdk-overlay-transparent-backdrop', //'dark-backdrop',
  panelClass: 'tm-file-preview-dialog-panel',
  width:'auto',
  selectedValue:'',
  hasMultiSelect:false,
  style:"{'font-size':'9px'}",
  valueColumn:'',
  displayColumn:'',
}

const DEFAULT_PREVIEW_CONFIG:FilePreviewDialogConfig= {
  hasBackdrop:true,
  backdropClass:'cdk-overlay-transparent-backdrop',
  panelClass:'tm-file-preview-dialog-panel'
}

const MORE_OPTIONS: cdkOverlayConfig = {
  hasBackdrop: true,
  backdropClass: 'cdk-overlay-transparent-backdrop', //'dark-backdrop',
  panelClass: 'cdk-overlay-pane-moreoption',
  width:'auto',
  height:'auto'
  
}
@Injectable({
  providedIn: 'root'
})
export class CdkOverlyayService {

  constructor(
    private overlay: Overlay,
    private injector:Injector
  ) { }

  open(config:FilePreviewDialogConfig,origin:any,component:any){
    const dialogConfig = {...DEFAULT_PREVIEW_CONFIG,...config};
    const overlayRef = this.createOverlay(dialogConfig,origin);
    const dialogRef = new cdkOverlayRef(overlayRef);
    const Injector = this.createOpenInjector(config,dialogRef)
    const filePreviewPortal = new ComponentPortal(component,null,Injector);
    overlayRef.attach(filePreviewPortal);
    overlayRef.backdropClick().subscribe(()=>dialogRef.close())
    return dialogRef
  }

  private createOverlay(config: FilePreviewDialogConfig, origin) {
    const overlayConfig = this.getOverlayConfig(config, origin);
    return this.overlay.create(overlayConfig);
  }

  private createOpenInjector(config:FilePreviewDialogConfig,dialogRef:cdkOverlayRef):PortalInjector{
    const injectionTokens = new WeakMap();
    injectionTokens.set(cdkOverlayRef, dialogRef);
    injectionTokens.set(FILE_PREVIEW_DIALOG_DATA, config.data);
    return new PortalInjector(this.injector, injectionTokens);
  }


  
  // used in overlay-select to create a overlay dropdown
  openDropdown(config: cdkOverlayConfig = {},origin?) {
    DEFAULT_CONFIG.width = origin.offsetWidth;  //by default width of the dropdown is equal to the width of the origin given by user
    if (config.data.data?.length > 7) config.height='30vh';  //if no. of dropdown records exceeds then height will be fixed for dropdown
    const dialogConfig = { ...DEFAULT_CONFIG, ...config }; // Override default configuration
    const dialogRef = this.createOverlayComp(DropdownOverlayComponent,dialogConfig,origin)
    return dialogRef;
  }

  //used in display widget for  color palatte and date filter
  openPreview(config: cdkOverlayConfig = {},overlayComp,origin?) {
    const dialogConfig = { ...DEFAULT_CONFIG, ...config };
    const dialogRef = this.createOverlayComp(overlayComp,dialogConfig,origin)
    return dialogRef;
  }
  
  //used in create rule/user_profile_menu
  openMenu(config: cdkOverlayConfig = {},overlayComp,origin?) {
    DEFAULT_CONFIG.width = origin.offsetWidth; 
    const dialogConfig = { ...DEFAULT_CONFIG, ...config };
    const dialogRef = this.createOverlayComp(overlayComp,dialogConfig,origin)
    return dialogRef;
  }

  createOverlayComp (overlayComp,dialogConfig,origin){
    const overlayConfig = new OverlayConfig({
      width: dialogConfig.width,
      height: dialogConfig.height,
      hasBackdrop: dialogConfig.hasBackdrop,
      backdropClass: dialogConfig.backdropClass,
      panelClass: dialogConfig.panelClass,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy : this.overlay.position()
      .flexibleConnectedTo(origin)
      .withPositions([{
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
      },
      {
        originX: 'start',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'bottom',
      },
      {
        originX: 'end',
        originY: 'top',
        overlayX: 'end',
        overlayY: 'bottom',
      },
      {
        originX: 'end',
        originY: 'bottom',
        overlayX: 'end',
        overlayY: 'top',
      }
      ])
    });
  
    
    const overlayRef = this.overlay.create(overlayConfig); //create overlay
    const dialogRef = new cdkOverlayRef(overlayRef);  // Instantiate remote control - (create an object using this as class)
    const overlayComponent = this.attachDialogContainer(overlayComp, dialogConfig,overlayRef,dialogRef)
    overlayRef.backdropClick().subscribe(()=>dialogRef.close())  //overlayRef.backdropClick()  returns an observable
    
    return dialogRef;
  }

  dashboard_moreoptions(config: cdkOverlayConfig = {},overlayComp,origin?) {
    const dialogConfig = { ...DEFAULT_CONFIG, ...config };
    const dialogRef = this.createMoreOptions(overlayComp,dialogConfig,origin)
    return dialogRef;
  }

  createMoreOptions (overlayComp,dialogConfig,origin){
    const overlayConfig = new OverlayConfig({
      width: dialogConfig.width,
      height: dialogConfig.height,
      hasBackdrop: dialogConfig.hasBackdrop,
      backdropClass: dialogConfig.backdropClass,
      panelClass: dialogConfig.panelClass,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy : this.overlay.position()
      .flexibleConnectedTo(origin)
      .withPositions([{
        originX: 'end',
        originY: 'bottom',
        overlayX: 'end',
        overlayY: 'bottom',
      },
      {
        originX: 'start',
        originY: 'top',
        overlayX: 'end',
        overlayY: 'top',
      }
        
      ])
    });
    const overlayRef = this.overlay.create(overlayConfig); //create overlay
    const dialogRef = new cdkOverlayRef(overlayRef);  // Instantiate remote control - (create an object using this as class)
    const overlayComponent = this.attachDialogContainer(overlayComp, dialogConfig,overlayRef,dialogRef)
    overlayRef.backdropClick().subscribe(()=>dialogRef.close())  //overlayRef.backdropClick()  returns an observable
    
    return dialogRef;
  }
 
  //attach created overlay component to the portal along with injected dependencies(values from parent compponent)
  private attachDialogContainer(componentRef, config:cdkOverlayConfig,overlayRef:OverlayRef,dialogRef:cdkOverlayRef){
  //create dpendency injector - to create depencies of the component which is using this service
  const Injector= this.createInjector(config,dialogRef)

  // Create ComponentPortal that can be attached to a PortalHost
  const containerPortal = new ComponentPortal(componentRef, null,Injector);

  // Attach ComponentPortal to PortalHost
  const containerRef: ComponentRef<any> = overlayRef.attach(containerPortal);

  return containerRef.instance;
  }
// to inject value to the created overlay component
  private createInjector(config: cdkOverlayConfig, dialogRef: cdkOverlayRef): PortalInjector {
    // Instantiate new WeakMap for our custom injection tokens
    const injectionTokens = new WeakMap();

    // Set custom injection tokens
    injectionTokens.set(cdkOverlayRef, dialogRef);
    injectionTokens.set(CDK_OVERLAY_DROPDOWN, 
      { 
        DATA:config.data,
        MULTISELECT:config.hasMultiSelect,
        STYLE: config.style,
        SELECTED_VALUE: config.selectedValue,
        VALUE_COLUMN: config.valueColumn,
        DISPLAY_COLUMN: config.displayColumn
      });
    
    // Instantiate new PortalInjector
    return new PortalInjector(this.injector, injectionTokens);
  }

   getOverlayConfig(config: FilePreviewDialogConfig, origin): OverlayConfig {

    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo(origin)
      .withPositions([{
        originX: 'end',
        originY: 'bottom',
        overlayX: 'end',
        overlayY: 'top',
        panelClass: 'dropdown-container',
      }, {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
        panelClass: 'dropdown-container',
      }
      ])
    const overlayConfig = new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      width: config.width,
      height: config.height,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy
    });

    return overlayConfig;
  }
 
}
