import { Injectable } from '@angular/core';
import { UserLoginService } from '../user-login/user-login.service';
import  * as moment from 'moment-timezone';

@Injectable({
  providedIn: 'root'
})
export class TimeZoneService {

  constructor(
    private userloginservice:UserLoginService,
  ) { }

  //https://gisgeography.com/world-time-zone-map/

  toUserTimeZone(timestring:any){
    const timezone = this.userloginservice.timezone;
    if (typeof timestring === 'string') {
      return moment.utc(timestring).tz(timezone);
    }else if( typeof timestring === 'number'){
      return moment.unix(timestring).tz(timezone);
    }
  };

  toUtcTimeZonefromUser(timestring:any){
    const timezone = this.userloginservice.timezone;
    if (typeof timestring === 'string') {
      return moment.tz(timestring,timezone).utc()
    }else if( typeof timestring === 'number'){
      return moment.unix(timestring).utc();
    }
  }

  getcurrentuserTime(){
    const timezone = this.userloginservice.timezone;
    return moment.tz(timezone);
  }

  setcurrentuserTime(timestring:any){
    const timezone = this.userloginservice.timezone;
    return moment.tz(timestring,timezone);
  }

  getuserTimegivenepoch(timestring:any){
    const timezone = this.userloginservice.timezone;
    return moment.unix(timestring).tz(timezone)
  }

  getUtcTime(){
    return moment().utc()
  }

  usertimezone = 'Asia/Kolkata';
  servertimezone = 'Asia/Tokyo';

  toservertimezonefromuser(timestring:any){
    if (typeof timestring === 'string') {
      return moment.tz(timestring,this.usertimezone).tz(this.servertimezone);
    }else if( typeof timestring === 'number'){
      return moment.unix(timestring).tz(this.servertimezone);
    }
  }


}
