import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ErrorService } from "./err.service";

@Injectable({
    providedIn:'root'
})

export class ErrorInterceptor implements HttpInterceptor{

    constructor(private errorService:ErrorService){}

    /* grabs error response on each httpResponse. whole error object returned when the request is login/logout.
    Otherwise the error interceptor throws user status in error object. */

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if(!navigator.onLine){
            this.errorService.userOffline(false)
            return throwError({error:{errmsg:'You are offline'},skip:true})
        }
        return next.handle(req)
        ?.pipe(
            catchError((error:HttpErrorResponse)=>{
                if (
                    req.url == environment.apiUrl+'/error/offline/errorLog/post' ||
                    req.url == environment.apiUrl+'/error/online/errorLog/post' ||
                    req.url == environment.apiUrl+'/error/errorLog/post' ||
                    req.url == environment.apiUrl+'/error/errorMetadata/get'
                )
                {
                    return throwError(error)
                }else if(
                    req.url == environment.apiUrl + '/user/secretQtn/get' || 
                    req.url == environment.apiUrl + '/user/authCode/get' || 
                    req.url == environment.apiUrl + '/user/secretValue/post' || 
                    req.url == environment.apiUrl + '/user/newPwd/post'
                ){
                    this.errorService.handleHttpErrors(error,1);
                }else if( 
                    req.url == environment.apiUrl + '/rule/name/check' ||
                    req.url == environment.apiUrl + '/userlogin/check/userpwd'||
                    req.url == environment.apiUrl+'/settings/check/roleName' ||
                    req.url == environment.apiUrl+'/studio/widget-builder/name/check'
                ){
                    this.errorService.handleHttpErrors(error,0);
                }
                else{
                    this.errorService.handleHttpErrors(error,1);
                }
                return throwError(error)
            })
        )
    }

}