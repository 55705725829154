import { AbstractControl, ValidatorFn } from "@angular/forms";

export function RoleSourceValidator():ValidatorFn {
    return (control:AbstractControl): {[key:string]:any} | null => {
        const errmsg={}
        const sourcelist = control.value;
        if(!sourcelist.length){
            errmsg['sourcelength'] = true;
            return errmsg;
        }else{
            return null;
        }
        
        
    }
}