import { AbstractControl, ValidatorFn } from "@angular/forms";

export function VerifyFullName():ValidatorFn {
    
    return (control:AbstractControl): {[key:string]:any} | null => {

        if(control.value){
            const regex = new RegExp("^[a-zA-Z]+$")
            if(!regex.test(control.value)){
                return { onlystring:true }
            }

        }else{
            return null
        }

        
    }   
}