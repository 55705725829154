<div class="container">
    <div class="header">
        <p>Change customer</p>
        <img src="assets/alerts/close.svg" mat-dialog-close>
    </div>
    <div class="content">
        <!-- <p>Changing customer will reset all data. Are you sure?</p> -->
        <p>Changing customer is currently not allowed. </p>
    </div>
    <div class="footer">
        <button class="submit" mat-dialog-close>Ok</button>
        <!-- <button class="submit" mat-dialog-close (click)="submit()">yes</button>
        <button class="cancel" mat-dialog-close>no</button> -->
    </div>
</div>