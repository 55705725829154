<!-- <div *ngIf="widget$| async as widgetDetail"> -->
<div class="dashboard-label-container" [ngClass]="spinnerState? 'progress':''" >
    <div class="body-wrapper" *ngIf="!spinnerState && !errorhandlelabeldwidget.status && widgetDetail" [ngClass]="widgetDetail.widgetLabelData?.className">
        <div class="body-wrapper-left">
            <div class="header-wrapper">
                <div class="header-left">
                    
                </div>
                <div class="header-right">
                    <label>{{widgetDetail.widgetLabelData?.contentValue}}</label>
                </div>
            </div>
            <div class="footer-wrapper">
                <p>{{widgetDetail.widgetLabelData?.subTitle}}</p>
            </div>
        </div>
        <div class="body-wrapper-right">
            <img *ngIf="labelhaserror" [title]="widgetDetail.error?.error.errmsg" src="assets/widgets/asterisk-solid.svg">
        </div>
        
    </div>
    <!-- mat spinner is loaded until widget details loaded from api -->
    <ng-container *ngIf="spinnerState">
        <mat-spinner class="spinner" diameter=20></mat-spinner>

    </ng-container>
    <ng-container *ngIf="errorhandlelabeldwidget.status">
        <div class="dashboardwidget-nodata">
            <div class="nodata-img">
                <img  src="assets/widgets/empty-data.svg">
            </div>
            <div>
                <p>{{errorhandlelabeldwidget.errmsg | limitCharacter:18}}</p>
            </div>
        </div>
    </ng-container>
</div>
<!-- </div> -->