import { AfterViewInit, Component, ContentChild, ContentChildren, ElementRef, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { TagAutocompleteContentDirective } from './tag-autocomplete-content.directive';
import { TagOptionComponent } from './tagoption/tag-option.component';


@Component({
  selector: 'tag-autocomplete',
  template: `
    <ng-template #root>
      <div class="autocomplete">
        <ng-container *ngTemplateOutlet="content.tpl"></ng-container>
      </div>
    </ng-template>
  `,
  exportAs: 'tagautocomplete',
  styleUrls: ['./tag-autocomplete.component.scss']
})
export class TagAutocompleteComponent {
  @ViewChild('root') rootTemplate: TemplateRef<any>;

  @ContentChild(TagAutocompleteContentDirective)
  content: TagAutocompleteContentDirective;

  @ContentChildren(TagOptionComponent)
  options: QueryList<TagOptionComponent>;
  
}
