import { Component, OnInit, Inject, TemplateRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-box',
  templateUrl: './dialog-box.component.html',
  styleUrls: ['./dialog-box.component.scss']
})

export class DialogBoxComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DialogBoxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  htmlContent: TemplateRef<HTMLElement> = this.data.template;
  headContent: TemplateRef<HTMLElement> | string = this.data.head;
  footContent: TemplateRef<HTMLElement> = this.data.foot;
  subHeadContent: TemplateRef<HTMLElement> = this.data.subHead;
  ngOnInit(): void {
  }

}
