import { UntypedFormArray, ValidatorFn } from '@angular/forms';
    
export function SameModuleNameValidator():ValidatorFn{

    function checkValue(arrayControls:UntypedFormArray, val:any,index:number){
            for(let i=0;i< arrayControls.controls.length;i++){
      
                if((arrayControls.controls[i].value.moduleRef == val.moduleRef) && (index !=i) && (arrayControls.controls[i].value.moduleRef != "")){
                    return true 
                }
            }
       
    }
    return (arrayControls: UntypedFormArray) => {
        for(let i=0;i< arrayControls.controls.length;i++){
           if(checkValue(arrayControls,arrayControls.controls[i].value,i)){
                return {alreadyFound:true}
           }
           
        }
    }
}