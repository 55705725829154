import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight'
})
export class DateHighLighter implements PipeTransform {

    transform(date:number, datearr:number[]) {
        if(datearr.includes(date)){
            return 'highlighted'
        }
    }
}
