import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SettingsRoutingModule } from './settings-routing.module';
import { SettingsComponent } from './settings.component';
import { MatTabsModule } from '@angular/material/tabs';
import { UserLandingComponent } from './user-landing/user-landing.component'
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { UserComponent } from './user/user.component';
import { PendingControlPipe } from './pipes/managependingState.pipe';
import { LimitString } from './pipes/limit-string.pipe';
import { CombineError } from './pipes/combineerror.pipe'
import { RoleLandingComponent } from './role-landing/role-landing.component';
import { AddRoleComponent } from './add-role/add-role.component';
import { FieldsComponent } from './fields/fields.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SettingsVarService } from './settings-var.service';
import { OrganizationComponent } from './organization/organization.component';
import { ChangeValidationComponent } from './organization/change-validation/change-validation.component';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [
    SettingsComponent,
    UserLandingComponent,
    UserComponent,
    PendingControlPipe,
    LimitString,
    CombineError,
    RoleLandingComponent,
    AddRoleComponent,
    FieldsComponent,
    OrganizationComponent,
    ChangeValidationComponent
  ],
  imports: [
    CommonModule,
    MatTabsModule,
    SettingsRoutingModule,
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatDialogModule,

  ],
  providers: [
    SettingsVarService
  ]
})
export class SettingsModule { }
