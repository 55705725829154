<div class="my-profile-container" [formGroup]="myProfileForm">
    <div class="header">
        <h4 class="my-profile-title">{{metadata.labels[0]}}</h4>
        <img (click)="close()" class="close-img" src="assets/close-dialog.svg">
    </div>
    <div class="body">
        <div class="body-top">
            <p class="mandatory-field">* mandatory</p>
            <div class="body-top-row-1">
                <div class="body-top-row-1-col-1">
                    <label>
                        {{metadata.labels[1]}}<span>*</span>
                    </label>

                </div>
                <div class="body-top-row-1-col-2">
                    <div class="input-box-type">
                        <input type="text" specialCharOmit [charList]="inputBoxEmitChar" placeholder="Enter UserName" disabled formControlName="username">
                    </div>
                    <div class="alert-danger">
                        <div *ngIf="username?.touched">{{metadata.username|oneError :username?.errors}}</div>
                    </div>
                </div>
            </div>
            <div class="body-top-row-2">
                <div class="body-top-row-2-col-1">
                    <label>
                        {{metadata.labels[2]}}<span>*</span>
                    </label>

                </div>
                <div class="body-top-row-2-col-2">
                    <div class="input-box-type">
                        <input type="text" specialCharOmit [charList]="emailchar" placeholder="Enter Email Id" formControlName="email">
                    </div>
                    <div class="alert-danger">
                        <div *ngIf="email?.touched||showerror">{{validationData.email.errors |oneError :email?.errors}}</div>
                    </div>
                </div>

            </div>
            <div class="body-top-row-3">
                <div class="body-top-row-3-col-1">
                    <label>
                        {{metadata.labels[3]}}<span>*</span>
                    </label>

                </div>
                <div class="body-top-row-3-col-2">
                    <div class="input-box-type">
                        <input type="text" placeholder="Enter Mobile Number" formControlName="mobile">
                    </div>
                    <div class="alert-danger">
                        <div *ngIf="mobile?.touched||showerror">{{validationData.mobile.errors |oneError: mobile?.errors}}</div>
                    </div>
                </div>

            </div>
        </div>
        <ng-container *ngIf="mat_spinner_userProfile_state" >
            <div class ="body-bottom-spinner">
                <mat-spinner class="spinner" diameter=20></mat-spinner>
            </div>
        </ng-container>
        <div class="body-bottom">
            <div class="body-bottom-row-1">
                <div class="body-bottom-row-1-col-1">
                    <label>
                        {{metadata.labels[4]}}<span>*</span>
                    </label>
                </div>
                <div class="body-bottom-row-1-col-2">
                </div>

            </div>
            <div class="body-bottom-row-2">
                <div class="body-bottom-row-2-col-0">
                </div>
                <div class="body-bottom-row-2-col-1">
                    <div class="body-bottom-row-2-col-1-row-1">
                        1.
                    </div>
                    <div class="body-bottom-row-2-col-1-row-2">
                        2.
                    </div>
                </div>
                <div class="body-bottom-row-2-col-2">
                    <div class="body-bottom-row-2-col-2-row-1">
                        <div class="body-bottom-row2-col-2-row-1-row-1" #operatorOrigin>
                            <app-overlay-select formControlName="qtn1" [data]="qtnList" [origin]="operatorOrigin"
                                [style]="{'font-size':'13px'}">
                            </app-overlay-select>
                        </div>
                        <div>
                        </div>
                    </div>

                    <div class="body-bottom-row-2-col-2-row-2">
                        <div class="body-bottom-row2-col-2-row-2-row-1" #operatorOrigin2>
                            <app-overlay-select formControlName="qtn2" [data]="qtnList" [origin]="operatorOrigin2"
                                [style]="{'font-size':'13px'}">
                            </app-overlay-select>
                        </div>
                        <div class="alert-danger" *ngIf="Ques2?.touched||showerror">
                            <div *ngIf="Ques2?.invalid && Ques2?.errors.samequestionerror">
                                {{metadata.samequestionerror.error}}</div>
                        </div>
                    </div>
                </div>
                <div class="body-bottom-row-2-col-3">
                    <div class="body-bottom-row-2-col-3-row-1">
                        <div class="input-box-type-small">
                            <input type="text" specialCharOmit [charList]="inputBoxEmitChar" placeholder="Type your answer here" [type]="showans1 ? 'text':'password'"
                                formControlName="ans1">
                        </div>
                        <div class="alert-danger">
                            <div *ngIf="ans1?.touched||showerror">{{validationData.secret_ques.errors |oneError : ans1?.errors}}</div>
                        </div>
                    </div>
                    <div class="body-bottom-row-2-col-3-row-2">
                        <div class="input-box-type-small">
                            <input type="text"specialCharOmit [charList]="inputBoxEmitChar" placeholder="Type your answer here" [type]="showans2 ? 'text':'password'"
                                formControlName="ans2">
                        </div>
                        <div class="alert-danger">
                            <div *ngIf="ans2?.touched||showerror">{{validationData.secret_ques.errors |oneError : ans2?.errors}}</div>
                        </div>
                    </div>
                </div>
                <div class="body-bottom-row-2-col-4">
                    <div class="body-bottom-row-2-col-4-row-1">
                        <img class="preview-hide-1" src="assets/preview_hide.svg" (click)="showans1 =! showans1"
                            *ngIf="!showans1">
                        <img class="preview-1" src="assets/preview.svg" (click)="showans1 =! showans1" *ngIf="showans1">
                    </div>
                    <div class="body-bottom-row-2-col-4-row-2">
                        <img class="preview-hide-2" src="assets/preview_hide.svg" (click)="showans2 =! showans2"
                            *ngIf="!showans2">
                        <img class="preview-2" src="assets/preview.svg" (click)="showans2 =! showans2" *ngIf="showans2">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer">
        <button class="cancel-button" (click)="cancel()">{{commonData?.buttonLabels.cancel}}</button>
        <button class="submit-button" (click)="submitProfile_click()">{{commonData?.buttonLabels.submit}}</button>
    </div>
</div>