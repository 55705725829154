import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { RoutecrumpsService } from 'src/app/services/routecrumps.service';
import { UserLoginService } from 'src/app/user-login/user-login.service';
import { ChangeValidationComponent } from './change-validation/change-validation.component';
import { SettingsService } from '../settings.service';
@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss']
})
export class OrganizationComponent implements OnInit {
  constructor(
    private fb: UntypedFormBuilder,
    public loginService: UserLoginService,
    private rcrumpService: RoutecrumpsService,
    private matdialog: MatDialog,
    private settingsService: SettingsService

  ) { }
  preview_state: boolean
  pages = {   //pagination
    presentPage: 1,
    itemPerPage: 10,
    length: 0
  };
  pagination = this.fb.group({ itemPerPage: [] }) //items per page form control

  metaData = this.loginService.metaData; // All meta data details as object
  nodata = this.loginService.metaData.alert.noDatamsg[1]
  headers = [
    // { "disp": "S.NO", "value": "sno" },
    { "disp": "CREATED ON", "value": "createdDt" },
    { "disp": "SERVICE PROVIDER", "value": "serviceProvider" },
    { "disp": "CUSTOMER", "value": "customerFullName" },
    // { "disp": "Short Name", "value": "customerShortName" },
    // { "disp": "TYPE", "value": "type" },
    { "disp": "LICENSE TYPE", "value": "licenseType" },
    { "disp": "LICENSE VALIDITY", "value": "licenseValidity" },
    { "disp": "", "value": "edit_data" },

  ]
  customerList: any = [
    {
      "createdDt": "2024-11-07",
      "serviceProvider": "Sangeetha Mobiles",
      "customerFullName": "Sangeetha Mobiles Pvt ltd",
      "customerShortName": "SM",
      "licenseValidity": "-",
      "licenseType": "-"
    },
    {
      "createdDt": "2024-11-13",
      "serviceProvider": "SUMA",
      "customerFullName": "Suma Soft",
      "customerShortName": "SS",
      "licenseValidity": "-",
      "licenseType": "-"
    }
  ];

  ngOnInit(): void {
    console.log(this.metaData.studio.studioTableHeader)
    this.createCrumps()
    this.preview_state = false;
    this.getCustomerList().then((customerList) => {
      this.customerList = customerList
    }).catch((err) => {
      console.log("error")
    })
  }
  getCustomerList() {
    return new Promise((resolve, reject) => {
      this.settingsService.getCustomers(this.loginService.userName).
        subscribe((data: any) => {
          resolve(data.customerList);
        },
          (error) => {
            reject(error);
          })
    })
  }
  pagination_click(direction: string) {
    if (this.pages.length > 1) {
      if (direction == "left") {
        if (this.pages.presentPage > 1) {
          this.pages.presentPage--;
          // this.load_designList();
        }
      } else {
        if (this.pages.presentPage < this.pages.length) {
          this.pages.presentPage++;
          // this.load_designList();
        }
      }
    }
  }
  createCrumps() {
    this.rcrumpService.route_crumps[1] = {
      disp_str: "Organization",
      routeTo: "organization",
      callback: null,
      isParent: false
    };
    this.rcrumpService.route_crumps.length = 2;
  }
  changeCustomer(customer) {
    const addeventsdialogRef = this.matdialog.open(ChangeValidationComponent, {
      width: "30vw", height: "30vh", data: { "customer": customer.customerFullName }, autoFocus: false,
      disableClose: true
    })
    // this.loginService.$customerChanged.next(customer)
  }
}
