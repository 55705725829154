import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ToastService } from '../shared/custom-overlay/toast/toast.service';

@Injectable({
  providedIn: 'root'
})

export class UserLoginService implements OnDestroy {

  public forcePwd = false;
  headers = new HttpHeaders({ 'content-type': 'application/json', 'charset': 'utf-8' });
  metaData: any;
  public userName;
  private _menuSubject = new Subject<any>();
  $menu_array = this._menuSubject.asObservable();
  $userLogged: BehaviorSubject<boolean> = new BehaviorSubject(false);
  $destroy: Subject<boolean> = new Subject();
  $metaData: Subject<any> = new Subject();
  $metaDataUpdated: BehaviorSubject<any> = new BehaviorSubject({});
  $userLoggedoff: Subject<boolean> = new Subject();
  userLoggedoff_ = this.$userLoggedoff.asObservable();
  $customerChanged: Subject<any> = new Subject();
  customerChanged_ = this.$customerChanged.asObservable();
  timerange: any;
  timezone: string;
  commonPlatformFields: any[] = [];
  hostlist: string[] = [];
  platformMap = {};
  dashboard_preview: any = null;
  mat_spinner_login_state: boolean = false;

  constructor(
    private http: HttpClient,
    private toaster: ToastService
  ) { }

  getUser(user_id: string, pwd: string) {
    return new Promise(async (resolve, reject) => {
      const request = JSON.stringify({ user_name: user_id, password: pwd })
      await this.http.post<any>(environment.apiUrl + '/userlogin/login/post', request, { headers: this.headers, withCredentials: true })
        .pipe(takeUntil(this.$destroy))
        .toPromise()
        .then((val) => {
          if (val.error) {
            reject(val.errmsg)
          } else if (val.menuList) {
            this.forcePwd = val.forcePwd;
            this._menuSubject.next(val.menuList);
            this.$userLogged.next(true)
            resolve(true)
          }
        })
        .catch(err => {
          reject('Something went wrong in the server');
        })
    })
  }

  getMenu(): Observable<any> {
    return this.$menu_array
  }

  getMetaData(reqobj: any) {
    let request = JSON.stringify(reqobj)
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiUrl + '/app/metadata/get', request, { headers: this.headers })
        .pipe(takeUntil(this.$destroy)).toPromise()
        .then((meta: any) => {
          this.metaData = meta;
          this.getUserPlatform({ "user_name": this.userName }).toPromise()
            .then(async (platformList: any) => {
              this.metaData.commonData.platformOptions = platformList;
              this.metaData.commonData.platformOptions.platformList.map((platform: any) => {
                this.platformMap[platform.value] = platform.disp;
              })
              this.getTimerange({ "metakey": "all" }).toPromise()
                .then(async (timerange) => {
                  this.timerange = timerange;
                  await this.getCommonFields({ platform: meta.commonData.platformOptions.default }).toPromise()
                    .then(async (val: any) => {
                      this.commonPlatformFields = val.platform_fields;
                      await this.getHostsList({ platform: meta.commonData.platformOptions.default }).toPromise()
                        .then(async (hostlist) => {
                          await this.getPerview({ design_name: ["explore_dashboard", "alert_dashboard", "search_dashboard"] }).toPromise()
                            .then(dashboard_preview => {
                              this.dashboard_preview = dashboard_preview;
                              if (hostlist.hosts) {
                                this.hostlist.length = 0;
                                hostlist.hosts.forEach((host: any) => {
                                  this.hostlist.push(host.value)
                                })
                              }
                              // this.metaData = meta;
                              // this.metaData.commonData.platformOptions = platformList;
                              // this.metaData.commonData.platformOptions.platformList.map((platform: any) => {
                              //   this.platformMap[platform.value] = platform.disp;
                              // })
                              this.mergeUserMeta();
                              this.$metaData.next(this.metaData);
                              this.$metaDataUpdated.next(this.metaData);
                              resolve(true);
                            })
                        })
                    })
                })
            })
        })
        .catch((err) => {
          reject()
        })
    })
  }

  mergeUserMeta() {
    this.metaData.rule.ruledefault.context.platform = this.metaData.commonData.platformOptions.default;
    this.metaData.alert.alertDefaults.external.platform = this.metaData.commonData.platformOptions.default;
  }

  getUserPlatform(reqobj: any) {
    const request = JSON.stringify(reqobj);
    return this.http.post(environment.apiUrl + '/assets/platforms/get', request, { headers: this.headers });
  }

  getTimerange(reqobj: any) {
    const request = JSON.stringify(reqobj);
    return this.http.post(environment.apiUrl + '/common/tdbtimerange/get', request, { headers: this.headers });
  }

  getTimezone(reqobj: any) {
    const request = JSON.stringify(reqobj);
    return this.http.post(environment.apiUrl + '/user/timezone/get', request, { headers: this.headers });
  }

  getUserSecretQuestions(userObj) {
    const request = JSON.stringify(userObj);
    return this.http.post(environment.apiUrl + '/user/secretQtn/get', request, { headers: this.headers });
  }

  sendAuthCode(userObj) {
    const request = JSON.stringify(userObj);
    return this.http.post(environment.apiUrl + '/user/authCode/get', request, { headers: this.headers });
  }

  postSecretQA(verifyObj) {
    const request = JSON.stringify(verifyObj);
    return this.http.post(environment.apiUrl + '/user/secretValue/post', request, { headers: this.headers });
  }

  postNewpwd(userObj) {
    const request = JSON.stringify(userObj);
    return this.http.post(environment.apiUrl + '/user/newPwd/post', request, { headers: this.headers });
  }

  getUserProfile(reqObj: any) {
    let request = JSON.stringify(reqObj);
    return this.http.post(environment.apiUrl + '/login/userProfile/get', request, { headers: this.headers });
  }

  postUserProfile(myProfileForm: any) {
    return this.http.post(environment.apiUrl + '/login/userProfile/post', myProfileForm, { headers: this.headers });
  }

  checkOldPassword(password: any) {
    return this.http.post(environment.apiUrl + '/userlogin/check/userpwd', JSON.stringify({ user_name: this.userName, password: password }), { headers: this.headers });
  }

  getHostsList(request: any) {
    return this.http.post<any>(environment.apiUrl + "/assets/hosts/get", request, { headers: this.headers });
  }

  postResetPwd(resetPwd: any) {
    return this.http.post(environment.apiUrl + '/login/resetPwd/post', resetPwd, { headers: this.headers })
  }

  showToaster(msg: string, type: any) {
    this.toaster.show({
      text: msg,
      type: type
    })
  }

  getCommonFields(platform: any) {
    return this.http.post(environment.apiUrl + '/common/platformFields/get', JSON.stringify(platform), { headers: this.headers })
      .pipe(takeUntil(this.$destroy))
  }

  getPerview(reqobj: any) {
    return this.http.post(environment.apiUrl + '/explorelogs/dashboard-preview/get', JSON.stringify(reqobj), { headers: this.headers })
  }

  ngOnDestroy(): void {
    this._menuSubject.unsubscribe()
    this.$userLogged.unsubscribe()
    this.$destroy.next(true)
    this.$destroy.unsubscribe()
  }
}
