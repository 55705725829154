<div class="add-role-container" [formGroup]="addRoleForm">
    <div class="header">
        <h4>{{data.header}}</h4>
        <img (click)="close()" class="close-img" src="assets/close-dialog.svg" alt="close">
    </div>
    <ng-container *ngIf="mat_spinner_role_state">
        <div class ="body-bottom-spinner">
            <mat-spinner class="spinner" diameter=20></mat-spinner>
        </div>
    </ng-container>
    <div class="body-wrapper">
        <div class="mandatory-field">* Mandatory</div>
        <div class="body-row-1">
            <div class="body-row-1-col-1">
                <div class="input-label">
                    <label>
                        {{roleMetaData.labels[0]}}<span class="text-danger">*</span>
                    </label>
                </div>
                <div class="body-row-1-col-1-col-2">
                    <div class="input-box-type">
                        <input type="text" specialCharOmit [charList]="inputBoxEmitChar" placeholder="Enter Role Name" formControlName="role_name">
                    </div>
                    <div class="alert-danger" >
                        <ng-container *ngIf="roleName.touched || showErrors">
                            {{roleMetaData.roleName|oneError: roleName.errors}}
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="body-row-1-col-2">
                <div class="input-label">
                    <label>
                        {{roleMetaData.labels[1]}}
                    </label>
                </div>
                <div class="body-row-1-col-2-col-2">
                    <div class="input-box-type">
                        <input type="text" disabled formControlName="product">
                    </div>
                </div>

            </div>
        </div>
        <div class="body-row-2">
            <div class="body-row-2-col-1">
                <div class="body-row-2-col-1-col-1">
                    <label>{{roleMetaData.labels[2]}}<span class="text-danger">*</span></label>
                </div>
                <div class="body-row-2-col-1-col-2" #roleorigin>
                        <app-overlay-select [hasMultiSelect]=true [data]="sourceList" formControlName="source"
                            [origin]="roleorigin" [style]="{'font-size':'13px'}" placeHolder="Source">
                        </app-overlay-select>
                    <div class="alert-danger" >
                        <ng-container *ngIf="(source.touched || showErrors) && source.errors">
                            {{roleMetaData.source.sourcelength}}
                        </ng-container>
                        
                    </div>
                </div>
            </div>
            <div class="body-row-2-col-2">
                <div class="body-row-2-col-2-col-1">
                    <label>{{roleMetaData.labels[3]}}</label>
                </div>
                <div class="body-row-2-col-2-col-2" #userorigin>
                        <app-overlay-select [hasMultiSelect]=true [data]="data.userlist" formControlName="assigned_user"
                            [origin]="userorigin" [style]="{'font-size':'13px'}" placeHolder="user List">
                        </app-overlay-select>
                    <div class="alert-danger" >
                        <ng-container *ngIf="users.touched||showErrors">
                            {{roleMetaData.assignedUsers|oneError: users.errors}}
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <div class="body-row-3">
            <div class="body-row-3-col-1-row-1">
                <label>{{roleMetaData.labels[4]}}<span class="text-danger">*</span></label>
            </div>
            <div class="body-row-3-col-1-row-2" formGroupName="menu_list">
                <ng-container  *ngFor="let menu of data.menulist;let i=index">
                    <div class="checkbox-custom">
                        <label class="checkbox-container">{{data.menulist[i].menu_name}}
                            <input type="checkbox" (click)="$event.stopPropagation()" [formControlName]="menu.menu_id">
                            <span class="checkmark"></span>
                        </label>
                    </div>
                </ng-container>
            </div>
            <div class="menu-error-message" *ngIf="menu_list.touched||showErrors">
                {{roleMetaData.choosenMenu|oneError:menu_list.errors}}
            </div>
        </div>
    </div>
    <div class="footer">
        <button class="cancel-button" (click)="cancel()">{{data.buttonLabels[1]}}</button>
        <button class="submit-button" (click)="submitRole_click()">{{data.buttonLabels[0]}}</button>
    </div>
</div>