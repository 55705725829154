import { Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { CDK_OVERLAY_DROPDOWN } from '../cdk-overlay-injector';
import { cdkOverlayRef } from '../cdk-overlay-ref';

@Component({
  selector: 'app-template-dropdown',
  templateUrl: './template-dropdown.component.html',
  styleUrls: ['./template-dropdown.component.scss']
})
export class TemplateDropdownComponent implements OnInit {

  bodyContent: TemplateRef<HTMLElement> = this.DropDown.DATA.template;

  constructor( public dialogRef: cdkOverlayRef,
    @Inject(CDK_OVERLAY_DROPDOWN) public DropDown: any,
    ) { }


  ngOnInit(): void {
  }

}
