<div class="new-password-container">
    <div class="header">
        <h4>{{metaData.labels[0]}}</h4>
        <img mat-dialog-close src="assets/close-dialog.svg" (click)="close_click()">
    </div>
    <div class="body-wrapper" [formGroup]="pwd">
        <p class="mandatory-field">* mandatory</p>
        <div class="body-row-1">
            <label>
                {{metaData.labels[1]}} <span>*</span>
            </label>
            <div class="body-row-1-col-2">
                <div class="input-box-type">
                    <input formControlName="newpassword" [type]="showPass1?'text':'password'" [placeholder]="inputPlaceholders[0]">
                </div>
                <div class="alert-danger" *ngIf="newPwd.touched||errorMessage">
                    <div *ngIf="newPwd.invalid">{{ validationData.password.errors |oneError:newPwd.errors}}</div>
                </div>
            </div>
            <div class="body-row-1-col-3">
                <img class="preview-hide-1" src="assets/preview_hide.svg" (click)="showPass1 =! showPass1"
                    *ngIf="!showPass1">
                <img class="preview-1" src="assets/preview.svg" (click)="showPass1 =! showPass1" *ngIf="showPass1">
            </div>
        </div>
        <div class="body-row-2">
            <label>
                {{metaData.labels[2]}}<span>*</span>
            </label>
            <div class="body-row-2-col-2">
                <div class="input-box-type">
                    <input formControlName="reenterpassword" [type]="showPass2?'text':'password'" [placeholder]="inputPlaceholders[1]">
                </div>
                <div class="alert-danger" *ngIf="reEnterPwd.touched||errorMessage">
                    <div *ngIf="reEnterPwd.invalid">{{validationData.password.errors |oneError:reEnterPwd.errors}}</div>
                    <div *ngIf="reEnterPwd.invalid && reEnterPwd.errors.confirmedValidator">
                        {{validationData.password.errors.pwdnotmatch}}</div>
                </div>
            </div>
            <div class="body-row-2-col-3">
                <img class="preview-hide-2" src="assets/preview_hide.svg" (click)="showPass2 =! showPass2"
                    *ngIf="!showPass2">
                <img class="preview-2" src="assets/preview.svg" (click)="showPass2 =! showPass2" *ngIf="showPass2">
            </div>
        </div>
    </div>
    <div class="footer">
        <div class="footer-buttons">
            <button class="cancel-button" mat-dialog-close (click)="cancel_click()">{{commonData.buttonLabels.cancel}}</button>
            <button class="submit-button" (click)="submit_click()">{{commonData.buttonLabels.submit}}</button>
        </div>
    </div>
</div>