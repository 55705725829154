import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { RuleAutocompleteService } from '../rule-autocomplete-content.service';

@Component({
  selector: 'rule-option',
  template: `
    <div *ngIf="show" class="rule-option" [ngClass] = "highlight ? 'highlight':''">
      <ng-content></ng-content>
    </div>
  `,
  //changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./rule-option.component.scss']
})
export class RuleOptionComponent implements OnInit {

  @Input() value: any;
  @Input() display: string;
  @Input() indexnumber:number;
  show:boolean=true;
  highlight:boolean = false;
  click$: Observable<any>;

  constructor(
    private host: ElementRef,
    private ruleautocompleteservice:RuleAutocompleteService,
    ) {
  }


  ngOnInit() {
    this.click$ = fromEvent(this.element, 'click').pipe(mapTo({disp:this.display,value:this.value}));
    this.initializeshow(this.ruleautocompleteservice.inputval);
    this.ruleautocompleteservice.navnumber.subscribe(navnumber=>{
      if(navnumber === this.indexnumber){
        this.highlight = true;
        this.host.nativeElement.scrollIntoView({behavior: "instant",block: "center",inline:"center"});
      }else{
        this.highlight = false;
      }
    })
  }

  initializeshow(disp:string){
    if(!this.display.toLowerCase().includes(disp.toLowerCase())){
      this.show = false;
    }else{
      this.show = true;
    }
  }



  get element() {
    return this.host.nativeElement;
  }
}
