import { Component, OnInit } from '@angular/core';
import { UserLoginService } from 'src/app/user-login/user-login.service';
import { SettingsService } from '../settings.service';
import { UntypedFormBuilder } from '@angular/forms';
import { TemplateDropdownComponent } from 'src/app/shared/custom-overlay/template-dropdown/template-dropdown.component';
import { CdkOverlyayService } from 'src/app/shared/custom-overlay/cdk-overlyay.service';
import { AddRoleComponent } from '../add-role/add-role.component';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RoutecrumpsService } from 'src/app/services/routecrumps.service';
import { SettingsVarService } from '../settings-var.service';

@Component({
  selector: 'app-role-landing',
  templateUrl: './role-landing.component.html',
  styleUrls: ['./role-landing.component.scss']
})
export class RoleLandingComponent implements OnInit {

  $destroy: Subject<boolean> = new Subject()
  searchUserList: string = ""
  mat_spinner_roleList_state: boolean = false;
  mat_spinner_roleList_editState: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private userloginService: UserLoginService,
    private settingsService: SettingsService,
    private cdkverlayDialog: CdkOverlyayService,
    private matdialog: MatDialog,
    private rcrumpService: RoutecrumpsService,
    public settingsVarService: SettingsVarService
  ) { }

  userName: any = this.userloginService.userName
  platformMap = this.userloginService.platformMap;

  ngOnInit(): void {
    this.createCrumps();
    this.load_roleList();
    this.settingsService.getMenuList();
    this.itemPerPage.valueChanges.pipe(takeUntil(this.$destroy))
      .subscribe((paginationValue) => {
        setTimeout(() => {
          this.settingsVarService.role_pages.presentPage = 1;
          if (this.settingsVarService.role_list.length) {
            this.load_roleList();
          }
        }, 0)
      })
  }

  ngAfterViewInit(): void {
    this.load_roleList()
  }

  createCrumps() {
    this.rcrumpService.route_crumps[1] = {
      disp_str: "Role",
      routeTo: "role",
      callback: null,
      isParent: false
    };
    this.rcrumpService.route_crumps.length = 2;
  }

  get itemPerPage() {
    return this.settingsVarService.rolePagination;
  }

  pagination_click(direction) {
    if (this.settingsVarService.role_pages.length > 1) {
      if (direction == "left") {
        if (this.settingsVarService.role_pages.presentPage > 1) {
          this.settingsVarService.role_pages.presentPage--;
          this.load_roleList();
        }
      } else {
        if (this.settingsVarService.role_pages.presentPage < this.settingsVarService.role_pages.length) {
          this.settingsVarService.role_pages.presentPage++;
          this.load_roleList();
        }
      }

    }
  }

  getRoleUserList() {
    return new Promise((resolve, reject) => {
      this.settingsService.getroleUserList()
        .subscribe((data) => {
          resolve(data);
        },
          (error) => {
            reject(error);
          })
    })
  }

  getMenuList() {
    return new Promise((resolve, reject) => {
      this.settingsService.getMenuList()
        .subscribe((menulist) => {
          resolve(menulist);
        },
          (error) => {
            reject(error);
          })
    })
  }

  getRoleList(userReq: any) {
    this.settingsVarService.role_list.length = 0;
    this.settingsService.providerolelist.postrequest(userReq)
      .pipe(takeUntil(this.$destroy))
      .subscribe((rolelist) => {
        this.mat_spinner_roleList_state = false;
        if (rolelist['roleList']) {
          this.settingsVarService.role_list = rolelist['roleList'];
          this.settingsVarService.role_pages.length = parseInt(rolelist['no_of_pages']);
        } else {
          this.settingsVarService.resetRolelandingPages();
          this.settingsVarService.errorhandlerolelanding.seterror(true, this.settingsVarService.nodata);
        }
      },
        (error) => {
          this.settingsVarService.resetRolelandingPages();
          this.mat_spinner_roleList_state = false;
          this.settingsVarService.errorhandlerolelanding.seterror(true, error.errmsg);
        })
  }

  load_roleList() {
    this.mat_spinner_roleList_state = true
    var userReq = { "userId": "all", "page_no": this.settingsVarService.role_pages.presentPage, "items_per_page": this.itemPerPage.value }
    if (this.settingsService.providerolelist.getemitstate()) {
      this.getRoleList(userReq);
    } else {
      if (!this.settingsService.providerolelist.responsestate) {
        this.getRoleList(userReq);
      } else {
        setTimeout(() => {
          this.mat_spinner_roleList_state = false;
          this.settingsService.providerolelist.emitstate = 1;
        }, 0)
      }
    }
  }

  openSourceMenu_click(template: any, origin: any) {
    let config = {
      data: {
        "template": template,
      }
    }
    this.cdkverlayDialog.openMenu(config, TemplateDropdownComponent, origin);
  }

  addNewRole() {
    this.mat_spinner_roleList_editState = true;
    this.getRoleUserList()
      .then((userlist) => {
        this.getMenuList()
          .then((menulist) => {
            this.mat_spinner_roleList_editState = false;
            let dialogRef = this.matdialog.open(AddRoleComponent,
              {
                width: "48%",
                height: "84vh",
                data: { "header": "Add Role", "buttonLabels": ["Submit", "Cancel"], "editdata": null, userlist, menulist }, autoFocus: false,
                disableClose: true
              }
            );
            dialogRef.afterClosed().subscribe((dat) => {
              if (dat) {
                this.load_roleList()
              }
            });
          })
          .catch((error) => {
            this.mat_spinner_roleList_editState = false;
          })
      })
      .catch((error) => {
        this.mat_spinner_roleList_editState = false;
      })
  }

  deleteUser(roleName, event) {
    event.stopPropagation()
    let reqObj = { role_name: roleName }
    this.settingsService.deleteRole(reqObj)
      .pipe(takeUntil(this.$destroy))
      .subscribe(
        (val) => {
          this.settingsService.showToaster(this.settingsVarService.roleTableHeader.toastmsg.success, 'success')
          this.settingsVarService.role_pages.presentPage = this.settingsService.handledeletePagination(this.settingsVarService.role_list, this.settingsVarService.role_pages.presentPage);
          this.load_roleList()
        },
        (err) => {

        })
  }

  editRole(role_name: any, event) {
    event.stopPropagation()
    this.mat_spinner_roleList_editState = true;
    this.getRoleUserList()
      .then((userlist) => {
        this.getMenuList()
          .then((menulist) => {
            this.settingsService.getRoleedit(role_name)
              .pipe(takeUntil(this.$destroy))
              .toPromise()
              .then((val) => {
                this.mat_spinner_roleList_editState = false;
                let dialogRef = this.matdialog.open(AddRoleComponent,
                  {
                    width: "48%",
                    height: "84vh",
                    data: { "header": "Edit Role", "buttonLabels": ["Update", "Cancel"], "editdata": val, "RoleName": role_name, userlist, menulist }, autoFocus: false,
                    disableClose: true
                  }
                );
                dialogRef.afterClosed().subscribe((dat: any) => {
                  if (dat) {
                    this.load_roleList()
                  }
                });
              })
              .catch((err: any) => {
                this.mat_spinner_roleList_editState = false;
              })
          })
          .catch((error) => {
            this.mat_spinner_roleList_editState = false;
          })
      })
      .catch((error) => {
        this.mat_spinner_roleList_editState = false;
      })


  }

  ngOnDestroy(): void {
    this.$destroy.next();
    this.settingsService.rolelandingComponentDestroyed.next(0);
  }

}
