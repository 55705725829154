import { Component, DoCheck, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { cdkOverlayRef } from '../cdk-overlay-ref';
import { CdkOverlyayService } from '../cdk-overlyay.service';

@Component({
  selector: 'app-overlay-select',
  templateUrl: './overlay-select.component.html',
  styleUrls: ['./overlay-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: OverlaySelectComponent
    }
  ]
})
export class OverlaySelectComponent implements OnInit, ControlValueAccessor, DoCheck {
  /*
  ---------------@Input()  - except data field all other are optional
    data - data(array/object) from parent in which this overlay dropdown is used
    valueColumn - column name of actual value for the option //column name which have the value of the displayed list 
    displayColumn - column name of  display name of the option //column name which have to be displayed on the list 
    origin - origin of the dropdown
    style - style of the overlyay component sent from parent
    hasMultiSelect - true/false, this overlay has multi select option or not
    placeHolder - place holder in the overlay select text
  ----------------@Output() 
    SelectionChange - emit the selected 
  --------------variables used
    valueArray-array of actual values derived from @Input() data 
    displayArray-array of displayed values, only displayArray will be sent to dropdown overlay
    selectedItem-actual value of this custom - formControl
    displayItem-displayed value of this custom - formControl
    selectedList - actual value selected are sent as an array(only for multiselect) 
  */
  @Input() data;
  @Input() valueColumn;
  @Input() displayColumn;
  @Input() origin;
  @Input() style;
  @Input() hasMultiSelect: boolean;
  @Input() placeHolder;
  @Input() label?;
  @Input() isSearch:boolean = false;
  @Output() SelectionChange: EventEmitter<any> = new EventEmitter<any>();

  valueArray;
  displayArray;
  selectedItem;
  displayItem;
  selectedList;
  @ViewChild('select') selectRef: ElementRef;
  index: number = 0;

  //for controlValue Accessor
  onChange = (selectedItem: string) => { }; // this value will be sent to form
  onTouched = () => { };
  disabled: boolean = false;
  overlay_state: boolean = false;
  constructor(private _Service: CdkOverlyayService) { }
  //the below 4 mothods are implemented for interface ControlValueAccessor 
  //- which is used to make custom component as custom html tag

  registerOnChange(onChange: any): void { // to form - send to form which contains this control
    this.onChange = onChange;
  }
  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }
  writeValue(value: any) { //from form - form which contains this control will write here  //example:default value from form
    this.selectedItem = value;
    if (this.data && value != null) {
      if (typeof (this.data[0]) == 'object') {
        if (typeof (value) == 'object' && this.hasMultiSelect) {
          value.forEach(element => {
            if (element != null) {
              let index = this.valueArray.findIndex((elem) => elem.toString() == element.toString()); //get display value of the given value
              if (index >= 0)
                if (this.displayItem) this.displayItem = this.displayItem + ',' + this.displayArray[index]
                else this.displayItem = this.displayArray[index]
            }
          });
        }
        else {
          let index = this.valueArray.findIndex((elem) => elem.toString() == value.toString()); //get display value of the given value
          if (index >= 0) this.displayItem = this.displayArray[index]
        }
      }
      else
        this.displayItem = value;
    }
    else
      this.displayItem = null;


  }
  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  ngDoCheck(): void {
    //convert array of objects into array of display column names(string/nunber) and value column
    if (this.data) {
      if (typeof (this.data[0]) == 'object') {
        if (!this.displayColumn) this.displayColumn = 'disp' //default name of display column in the object
        if (!this.valueColumn) this.valueColumn = 'value'   //default name of value column in the object
        this.displayArray = this.data.map(a => a[this.displayColumn])
        this.valueArray = this.data.map(a => a[this.valueColumn])
      }
      else {
        this.displayArray = this.data;
        this.valueArray = this.data;
      }
    }
  }

  ngOnInit(): void {
    this.displayItem = "";
  }

  //navigation to the dropdown
  onKeyDown(event, origin) {
    switch (event.key) {
      case "ArrowLeft":
      case "ArrowUp":
        event.stopPropagation();
        if (this.index > 0) this.index = this.index - 1;
        else this.index = this.data.length - 1;
        this.displayItem = this.displayArray[this.index];
        this.selectedItem = this.valueArray[this.index];
        this.onChange(this.selectedItem)
        break;
      case "ArrowRight":
      case "ArrowDown":
        event.stopPropagation();
        if (this.index < this.data.length - 1) this.index = this.index + 1;
        else this.index = 0;
        this.displayItem = this.displayArray[this.index];
        this.selectedItem = this.valueArray[this.index];
        this.onChange(this.selectedItem)
        break;
      case "Enter":
        this.dropdownOverlay(origin);
    }
  }

  //to set config values and open the overlay dropdown component
  dropdownOverlay(origin) {
    this.overlay_state = false
    if (this.displayArray && this.disabled == false) { // dropdown will be open only there is value for dropdown
      this.onTouched();
      if (this.origin) origin = this.origin;
      let dropdownRef: cdkOverlayRef;
      let config;
      config = {
        data: {data:this.displayArray,isSearch:this.isSearch},//this.data,
      }
      if (this.displayItem == undefined) this.displayItem = '';
      if (this.displayItem != '') config.selectedValue = this.displayItem; //default value
      if ((this.style)) config.style = this.style
      if ((this.hasMultiSelect)) config.hasMultiSelect = this.hasMultiSelect
      if ((this.valueColumn)) config.valueColumn = this.valueColumn;//column of the object not seen by user which is value of the html tag 
      if ((this.displayColumn)) config.displayColumn = this.displayColumn; //column of the object seen by user which is displayed in the html tag
      let list;
      //open the dropdown overlay under thi component       
      dropdownRef = this._Service.openDropdown(config, origin);
      dropdownRef.$closed.subscribe(data => {
        this.overlay_state = data
      })
      //subscribe to the values selected from dropdown (string/comma separated string)
      //if it is multiselect the return value is array of strings
      //else the return value is string
      dropdownRef.$selectedValue.subscribe((val) => {
        //if dropdown has multi select then we have to return value as array
        if (this.hasMultiSelect) {
          this.displayItem = val;
          // the comma separated display value from dropdown is converted to array
          list = this.displayItem.split(',');
          //initialise the display list
          this.selectedList = [];
          this.selectedList.length = 0;
          if (list[0]) {
            //find the equallent actual value for all the display value and make it is array and return
            list.forEach(element => {
              let index = this.displayArray.findIndex((elem) => elem == element && element != 'All');
              if (index >= 0) this.selectedList.push(this.valueArray[index])
            });
          }
          if (list[0] == 'All') this.displayItem = 'All';
          this.onChange(this.selectedList);
        }
        else {
          this.displayItem = val;  //display value
          let index = this.displayArray.findIndex((elem) => elem == val);
          this.selectedItem = this.valueArray[index] //actual value
          this.onChange(this.selectedItem)
        }
        this.selectRef.nativeElement.focus();
        this.SelectionChange.emit();
      });
    }
  }
}