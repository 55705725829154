<div class="home-container">
    <div class="header-container">
        <div class="col-1-header">
            <h4>{{label[0]}}</h4>
        </div>
        <div class="col-2-header">
            <h4>{{label[1]}}</h4>
        </div>
        <div class="col-3-header">
            <h4>{{label[2]}}</h4>
        </div>
    </div>
    <div class="applist-container">
        <div class="col-1">
            <ng-container *ngFor="let module of applist[0]">
                <div class="module-chip" [ngClass]="module.isSelectable ? 'module-active' :'module-inactive'"
                    (click)="chip_click(module)">
                    <div class="module-chip-left">
                        <img [src]="module.img_url">
                    </div>
                    <div class="module-chip-right">
                        <label class="header">{{module.label}}</label>
                        <p class="content">{{module.content}}</p>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="col-2">
            <ng-container *ngFor="let module of applist[1]">
                <div class="module-chip" [ngClass]="module.isSelectable ? 'module-active' :'module-inactive'"
                    (click)="chip_click(module)">
                    <div class="module-chip-left">
                        <img [src]="module.img_url">
                    </div>
                    <div class="module-chip-right">
                        <label class="header">{{module.label}}</label>
                        <p class="content">{{module.content}}</p>
                    </div>
                </div>
            </ng-container>

        </div>
        <div class="col-3">
            <ng-container *ngFor="let module of applist[2]">
                <div class="module-chip" [ngClass]="module.isSelectable ? 'module-active' :'module-inactive'">
                    <div class="module-chip-left">
                        <img [src]="module.img_url">
                    </div>
                    <div class="module-chip-right">
                        <label class="header">{{module.label}}</label>
                        <p class="content">{{module.content}}</p>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>