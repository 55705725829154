import { AbstractControl, ValidatorFn } from "@angular/forms";

export function VerifyUser():ValidatorFn {
    
    return (control:AbstractControl): {[key:string]:any} | null => {

        const fsname = control.value.firstname;
        const lsname = control.value.lastname;
        let errmsg = {}
        if(control.value){
            if(fsname && lsname){
                if(fsname === lsname){
                    errmsg["same"]=false
                }
            }
        }
        const fromtime = control.value.fromTime;
        const totime = control.value.toTime;
        if((fromtime && !totime) || (!fromtime && totime)){
            if(fromtime){
                const toTime = control.get('toTime')
                toTime.setErrors({fromtorequired:true})
            }else if(totime){
                const fromTime = control.get('fromTime')
                fromTime.setErrors({fromtorequired:true})
            }
        }

        return errmsg
    }   
}