<div class="login-container">
    <div class="body-wrapper">
        <div *ngIf="forgotPwdState==0" class="body-left" [formGroup]="loginForm">
            <div>
            </div>
            <div class="body-left-row2">
                <p> Welcome to</p>
            </div>
            <div class="body-left-row3">
                <div class="body-left-row3-col1">
                    <img src="assets/user-login/logo.svg">
                </div>
                <div class="body-left-row3-col2">
                    <img src="assets/user-login/siem.svg">
                </div>
            </div>
            <div class="body-left-row4">
                <p>Log in to explore security events and alerts with ease and perform indepth analysis</p>
            </div>
            <div class="body-left-row5">
                <input type="text" placeholder="Username" formControlName="userName" (keydown)="onKeyDown_UName($event)"
                    #UName>
            </div>
            <div class="body-left-row5">
                <input type="password" placeholder="Password" formControlName="pwd" (keydown)="onKeyDown_pwd($event)"
                    #pwd>
            </div>

            <div class="body-left-row7">
                <button class="signin" (click)="login()" #signIn>SIGN IN</button>
            </div>

            <div class="forgot-password">
                <span (click)="forgotPassword_click()">Forgot password?</span>
            </div>

            <div class="alert-message-row8">
                <span class="error-message">{{errorMessage}}</span>
            </div>


        </div>
        <div *ngIf="forgotPwdState==1" class="body-left-forgot" [formGroup]="pwdVerify">

            <div>

            </div>
            <div class="body-left-row2">
                <p align="center"> Welcome to</p>
            </div>

            <div class="body-left-row3">
                <div class="body-left-row3-col1">
                    <img src="assets/user-login/logo.svg">
                </div>
                <div class="body-left-row3-col2">
                    <img src="assets/user-login/siem.svg">
                </div>
            </div>
            <div>

            </div>
            <div class="body-left-row4">
                <label>{{qtnList.qtn1}}
                    <span>*</span>
                </label>
                <div class="input-box-type">
                    <input type="text" placeholder="Answer the question" formControlName="ans1"
                        (keydown)="clearErrormsg()">
                </div>
                <div class="alert-danger">
                    <div *ngIf="ans1.touched||showForgotError"> {{ validationData.secret_ques.errors | oneError :
                        ans1.errors }}
                    </div>
                </div>
            </div>
            <div class="body-left-row5">
                <label>{{qtnList.qtn2}}
                    <span>*</span>
                </label>
                <div class="input-box-type">
                    <input type="text" placeholder="Answer the question" formControlName="ans2"
                        (keydown)="clearErrormsg()">
                </div>
                <div class="alert-danger">
                    <div *ngIf="ans2.touched||showForgotError">
                        {{ validationData.secret_ques.errors | oneError : ans2.errors }}
                    </div>
                </div>
            </div>
            <div class="body-left-row6">
                <div class="body-left-row6-row1">
                    <label>{{forgotPwdmetaData.labels[2]}}</label>
                    <p *ngIf="timerState">{{forgotPwdmetaData.labels[3]}}</p>
                    <div class="body-left-row6-row1-row1" *ngIf="!timerState">
                        <p>{{forgotPwdmetaData.labels[3]}}</p>
                        <span *ngIf="display!=''"> OTP expire in</span>
                    </div>
                </div>

                <div class="body-left-row6-row2">
                    <div>
                        <div class="input-box-type">
                            <input type="text" placeholder="Enter code" formControlName="verificationCode"
                                (keydown)="clearErrormsg()">

                        </div>
                        <div class="alert-danger">
                            <div
                                *ngIf="verificationCode && verificationCode.errors && (verificationCode.dirty || verificationCode.touched)||showForgotError">
                                {{ forgotPwdmetaData.verifyCode | oneError : verificationCode.errors }}
                            </div>
                        </div>
                    </div>
                    <button *ngIf="timerState" class="resend-btn" (click)="resend_click()">
                        Resend
                    </button>
                    <div class="otp-container" *ngIf="!timerState">
                        <span class="timer">
                            {{display}}
                        </span>
                    </div>
                </div>
            </div>

            <ng-container *ngIf="mat_spinner_forgotpwd_state">
                <div class="body-bottom-spinner">
                    <mat-spinner class="spinner" diameter=20></mat-spinner>
                </div>
            </ng-container>

            <div class="body-left-row7">
                <div class="submit-cancel">
                    <button class="cancel-btn" (click)="cancel_click()" mat-dialog-close>
                        Cancel
                    </button>
                    <button class="submit-btn" (click)="load_setNewPassword()">
                        Submit
                    </button>
                </div>
            </div>
            <div class="alert-message-row8">
                <span class="error-message">{{errorMessage}}</span>
            </div>
        </div>

        <div *ngIf="forgotPwdState==2" class="body-left-new-pwd" [formGroup]="setPwd">

            <div>

            </div>
            <div class="body-left-row2">
                <p align="center"> Welcome to</p>
            </div>

            <div class="body-left-row3">
                <div class="body-left-row3-col1">
                    <img src="assets/user-login/logo.svg">
                </div>
                <div class="body-left-row3-col2">
                    <img src="assets/user-login/siem.svg">
                </div>
            </div>
            <div>

            </div>

            <div class="body-left-row4">
                <label class="body-left-row4-row1">{{newPwdmetaData.labels[1]}} <span>*</span> </label>
                <div class="body-left-row4-row2">
                    <div class="body-left-row4-row2-col1">
                        <input [type]="showPass1?'text':'password'" placeholder="Enter password"
                            formControlName="newPwd">
                    </div>
                    <div class="body-left-row4-row2-col2">
                        <img class="preview-hide-1" src="assets/preview_hide.svg" (click)="showPass1 =! showPass1"
                            *ngIf="!showPass1">
                        <img class="preview-1" src="assets/preview.svg" (click)="showPass1 =! showPass1"
                            *ngIf="showPass1">
                    </div>

                </div>
                <div class="alert-danger" id="alertdanger">
                    <div *ngIf="newPwd.touched||showNewPassError">{{validationData.password.errors
                        |oneError:newPwd.errors}}
                    </div>
                </div>
            </div>


            <div class="body-left-row5">
                <label class="body-left-row5-row1">{{newPwdmetaData.labels[2]}}
                    <span>*</span>
                </label>
                <div class="body-left-row5-row2">

                    <div class="body-left-row5-row2-col1">
                        <input [type]="showPass2?'text':'password'" placeholder="Reenter password"
                            formControlName="reEnterPwd">
                    </div>
                    <div class="body-left-row5-row2-col2">
                        <img class="preview-hide-2" src="assets/preview_hide.svg" (click)="showPass2 =! showPass2"
                            *ngIf="!showPass2">
                        <img class="preview-2" src="assets/preview.svg" (click)="showPass2 =! showPass2"
                            *ngIf="showPass2">
                    </div>

                </div>
                <div class="alert-danger" style="margin:0.5% 0 0 0" *ngIf="reEnterPwd.touched || showNewPassError">
                    <div *ngIf="reEnterPwd.invalid">{{validationData.password.errors |oneError:reEnterPwd.errors}}</div>
                    <div *ngIf="reEnterPwd.invalid && reEnterPwd.errors.confirmedValidator">
                        {{validationData.password.errors.pwdnotmatch}}</div>
                </div>
            </div>
            <div class="body-left-row6">
            </div>

            <div class="body-left-row7">
                <div class="submit-cancel">
                    <button class="cancel-btn" (click)="cancel_click()" mat-dialog-close>
                        Cancel
                    </button>
                    <button class="submit-btn" (click)="submit_click()">
                        Submit
                    </button>
                </div>
            </div>


            <div class="alert-message-row8">
                <span class="error-message">{{errorMessage}}</span>
            </div>


        </div>

        <div class="body-right">
            <!-- <img src="assets/user-login/lines.jpg"> -->
        </div>
    </div>

</div>
<ng-container *ngIf="_service.mat_spinner_login_state">
    <div class="spinner-background">
        <div class="inner-spinner">
            <mat-spinner class="spinner" diameter="40"></mat-spinner>
            <p>Loading...</p>
        </div>
    </div>
</ng-container>