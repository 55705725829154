import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RoutecrumpsService } from 'src/app/services/routecrumps.service';
import { UserLoginService } from 'src/app/user-login/user-login.service';
import { SettingsService } from '../settings.service';
import { UserComponent } from '../user/user.component';
import { SettingsVarService } from '../settings-var.service';

@Component({
  selector: 'app-user-landing',
  templateUrl: './user-landing.component.html',
  styleUrls: ['./user-landing.component.scss']
})
export class UserLandingComponent implements OnInit, OnDestroy {

  searchUserList: any;
  mat_spinner_userList_state: boolean;
  mat_spinner_userList_editState: boolean;

  $destroy: Subject<boolean> = new Subject()

  
  // User details
  constructor(
    private fb: UntypedFormBuilder,
    private userloginService: UserLoginService,
    private settingsService: SettingsService,
    private matdialog: MatDialog,
    private rcrumpService:RoutecrumpsService,
    public settingsVarService:SettingsVarService
  ) { }


  userName: any = this.userloginService.userName
 
  ngOnInit(): void {
    this.createCrumps();
    this.load_userList();
    this.itemPerPage.valueChanges.pipe(takeUntil(this.$destroy))
    .subscribe((paginationValue) => {
      setTimeout(()=>{
        this.settingsVarService.pages.presentPage = 1;
        if(this.settingsVarService.userList.length){
          this.load_userList();
        }
      },0)
     
    })
  }

  createCrumps(){
    this.rcrumpService.route_crumps[1] = {
      disp_str:"User",
      routeTo:"user",
      callback:null,
      isParent:false
    };
    this.rcrumpService.route_crumps.length = 2;
  }

  get itemPerPage() {
    return this.settingsVarService.userPagination;
  }

  pagination_click(direction: string) {
    if(this.settingsVarService.pages.length > 1) {
      if (direction == "left") {
        if (this.settingsVarService.pages.presentPage > 1){
          this.settingsVarService.pages.presentPage--;
          this.load_userList();
        } 
      } else {
        if (this.settingsVarService.pages.presentPage < this.settingsVarService.pages.length) {
          this.settingsVarService.pages.presentPage++;
          this.load_userList();
        } 
      }
     
    }
  }

  getUserList(userReq:any){
    this.settingsVarService.userList.length = 0;
    this.settingsService.provideuserlist.postrequest(userReq)
    .pipe(takeUntil(this.$destroy))
    .subscribe((val)=>{
      this.mat_spinner_userList_state = false;
      if(val['list']){
        this.settingsVarService.userList = [...val['list']]
        this.settingsVarService.pages.length = val['no_of_pages'] // Math.floor(this.userData.length/userReq.items_per_page) //this.userData.dataLength;
      } else {
        this.settingsVarService.resetUserlandingPages();
        this.settingsVarService.errorhandleuserlanding.seterror(true,this.settingsVarService.nodata);
      }
    },
    (error)=>{
      this.settingsVarService.resetUserlandingPages();
      this.mat_spinner_userList_state = false;
      this.settingsVarService.errorhandleuserlanding.seterror(true,error.errmsg)
    })
  }

  load_userList() {
    this.mat_spinner_userList_state = true;
    this.settingsVarService.errorhandleuserlanding.status = false;
    var userReq = { "userId": "all", "page_no": this.settingsVarService.pages.presentPage, "items_per_page": this.itemPerPage.value }
    if(this.settingsService.provideuserlist.getemitstate()){
      this.getUserList(userReq);
    } else {
      if(!this.settingsService.provideuserlist.responsestate){
        this.getUserList(userReq);
      } else {
        setTimeout(()=>{
          this.mat_spinner_userList_state = false;
          this.settingsService.provideuserlist.emitstate = 1;
        },0)
      }
    }
  }

  getRoleList(){
    return new Promise((resolve,reject)=>{
      this.settingsService.getRoles().
      subscribe((data:any)=>{
        resolve(data.roleList);
      },
      (error)=>{
        reject(error);
      })
    })
  }

  // Add new user
  addNewUser() {
    this.mat_spinner_userList_editState = true;
    this.getRoleList()
    .then((rolelist)=>{
      this.mat_spinner_userList_editState = false;
      const dialogRef = this.matdialog.open(UserComponent, {
        width: "55%", height: "95vh", data: { editState: false, rolelist }, autoFocus: false,
        disableClose: true
      })
      dialogRef.afterClosed()
      .pipe(takeUntil(this.$destroy))
      .subscribe((dat: any) => {
        if (dat) {
          if (dat.status) {
            this.load_userList()
          }
        }
      })
    })
    .catch((err)=>{
      this.mat_spinner_userList_editState = false;
    })
    
  }

  // Edit user
  async editUser(userDetails: string) {
    this.mat_spinner_userList_editState = true;
    this.getRoleList()
    .then((rolelist)=>{
      this.settingsService.getUserEdit(userDetails)
      .pipe(takeUntil(this.$destroy))
      .toPromise()
      .then((val: any) => {
        this.mat_spinner_userList_editState = false
        const dialogRef = this.matdialog.open(UserComponent, {
          width: "55%", height: "95vh", data: { editState: true, editdata: val, rolelist }, autoFocus: false,
          disableClose: true
        })
        dialogRef.afterClosed().subscribe((dat: any) => {
          if (dat) {
            if (dat.status) {
              this.load_userList()
            }
          }
        })
      })
      .catch((err:any)=>{
        this.mat_spinner_userList_editState = false;
      })
    })
    .catch((err)=>{
      this.mat_spinner_userList_editState = false;
    })
    
  }
  // Delete user
  deleteUser(username: string) {
    this.settingsService.deleteUser(username)
      .pipe(takeUntil(this.$destroy))
      .subscribe((val: any) => {
        if (val.status) {
          this.settingsService.showToaster(this.settingsVarService.userTableHeader.toastmsg.success, 'success')
          this.settingsVarService.pages.presentPage = this.settingsService.handledeletePagination(this.settingsVarService.userList,this.settingsVarService.pages.presentPage)
          this.load_userList()
        }
      },
      (err:any) => {
        
      })
  }

  ngOnDestroy(): void {
    this.$destroy.next(true)
    this.settingsService.userlandingComponentDestroyed.next(0);
  }

}
