<div class="panel">
    <!-- <ul #list id="list" *ngIf="dataType =='object'">
        <li [style]="style" *ngFor="let item of data;let i = index" tabindex="-1" (keydown)="onKeyDown($event,i)"
            (click)="onClick(item[displayColumn], i)">
            <input *ngIf="DropDown.MULTISELECT" type='checkbox' name='checkList' id="{{'chk'+i }}"
                (click)="$event.stopPropagation">
            {{item[displayColumn]}}
        </li> -->
    <!-- [checked]="item['checked']" -->
    <!-- </ul>
     <ul #list id="list" *ngIf="dataType !='object'"> -->
    <ul #list id="list">
        <li [style]="style" *ngFor="let item of data;let i = index" tabindex="-1" (keydown)="onKeyDown($event,i)"
            (click)="onClick(item, i)">
            <!-- <input *ngIf="DropDown.MULTISELECT" type='checkbox' name='checkList' id="{{'chk'+i }}" (click)="check(i)"> {{item}}-->
            <ng-container *ngIf="DropDown.MULTISELECT">
                <label class="checkbox-container"> {{item}}
                    <input type="checkbox" name='checkList' id="{{'chk'+i }}">
                    <span class="checkmark"></span>
                </label>
            </ng-container>
            <ng-container *ngIf=" !DropDown.MULTISELECT">
                {{item}}

            </ng-container>
        </li>
    </ul>
</div>