import { KeyValue } from '@angular/common';
import { AfterViewInit, Component, Input, OnInit, ViewChild, Renderer2, ElementRef } from '@angular/core';
@Component({
  selector: 'app-display-table',
  templateUrl: './display-table.component.html',
  styleUrls: ['./display-table.component.scss']
})
export class DisplayTableComponent implements OnInit, AfterViewInit {

  //table inputs received here
  @Input()
  headers: any

  @Input()
  rows: any


  constructor() { }


  ngOnInit(): void {

  }

  ngAfterViewInit() {

  }
  //unsort the key value from the array of objects-hari
  originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  }

}
