
import { Component, Inject, OnInit,HostListener, AfterViewInit, QueryList, ViewChildren, ElementRef, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CDK_OVERLAY_DROPDOWN} from '../cdk-overlay-injector';
import { cdkOverlayRef } from '../cdk-overlay-ref'; 

const ESCAPE = 27;
@Component({
  selector: 'app-dropdown-overlay',
  templateUrl: './dropdown-overlay.component.html',
  styleUrls: ['./dropdown-overlay.component.scss']
})
export class DropdownOverlayComponent implements OnInit {
  @ViewChildren("list") 
  listItemsRef: QueryList<ElementRef>;
  data;
  displayColumn;
  valueColumn;
  hasMultiSelect;
  dataType:string;
  searchValue = new FormControl('');
  isSearch:boolean = false;

 //escape-key
 @HostListener('document:keydown',['$event'])
 private handleKeydow(event:KeyboardEvent){
   if (event.key=='Escape'){
     this.dialogRef.close();
   }
 }
 
 constructor(
   public dialogRef: cdkOverlayRef,
   @Inject(CDK_OVERLAY_DROPDOWN) public DropDown: any,
  
 ) { }
 

 ngOnInit(): void {
   //convert comma separated string into array
    if(this.DropDown.SELECTED_VALUE) this.DropDown.SELECTED_VALUE = this.DropDown.SELECTED_VALUE.toString().split(',');
    this.data = this.DropDown.DATA.data;
    this.isSearch = this.DropDown.DATA.isSearch;

    this.searchValue.valueChanges.subscribe((searchstr) => {
      this.data = [
        ...this.DropDown.DATA.data.filter((item: any) => {
          return item.toLowerCase().includes(searchstr.toLowerCase())
        }),
      ]
    })
  }

 ngAfterViewInit(): void {
 
  
  if (this.listItemsRef != null && this.listItemsRef.first != null && this.DropDown.SELECTED_VALUE) {
    
      // MULTISELECT- load previously checked values
    if (this.DropDown.SELECTED_VALUE.length>0 && this.DropDown.MULTISELECT){
         // the data list has 'All' option and 'All' is selected, then select all items by default
      if (this.DropDown.DATA.data[0]=='All' && this.DropDown.SELECTED_VALUE.includes('All')){
        for (var i = 0; i < this.listItemsRef.first.nativeElement.children.length; i++) {
            this.listItemsRef.first.nativeElement.children[i].children[0].children[0].checked=true;
          }
       }
      else{
       
        for (var i = 0; i < this.listItemsRef.first.nativeElement.children.length; i++) {
             if (this.DropDown.SELECTED_VALUE.includes(this.DropDown.DATA.data[i].toString()))
            this.listItemsRef.first.nativeElement.children[i].children[0].children[0].checked=true;
        }
      }
      
    }
    //Single selection
    if (this.DropDown.SELECTED_VALUE.length=1 ){
       //focus on selected value 
          let i=this.data.findIndex((elem)=> elem==this.DropDown.SELECTED_VALUE[0])
          if(i>=0) this.listItemsRef.first.nativeElement.children[i].focus()
     }
  } 
 }
 
 closeOverLay(){
   this.dialogRef.close();
 }

  onClick(itemValue,index){
  
    if (this.DropDown.MULTISELECT){
    //toggle check/uncheck
   
   
      this.listItemsRef.first.nativeElement.children[index].children[0].children[0].checked=!this.listItemsRef.first.nativeElement.children[index].children[0].children[0].checked;
      let checkedData=[];
        
      // Check all the values when 'All' option is checked // Uncheck all the values when 'All' option is unchecked
      //uncheck 'All' option, if any option is unchecked
      if (this.data[0]=='All' && index==0) {
      for (var i = 0; i < this.listItemsRef.first.nativeElement.children.length; i++)
        (this.listItemsRef.first.nativeElement.children[i].children[0].children[0]).checked=(this.listItemsRef.first.nativeElement.children[0].children[0].children[0]).checked;
      }else if (this.data[0]=='All' && index != 0 &&  (this.listItemsRef.first.nativeElement.children[index].children[0].children[0]).checked == false)
          (this.listItemsRef.first.nativeElement.children[0].children[0].children[0]).checked = false
    
      //create an array of selected values
      for (var i = 0; i < this.listItemsRef.first.nativeElement.children.length; i++) {
        var checkbox = this.listItemsRef.first.nativeElement.children[i].children[0].children[0];
        if (checkbox.checked) checkedData.push(this.data[i]);
      }
      //convert array into comma separated string
      this.dialogRef.submit(checkedData.toString());
      
    }
    else { 
     this.dialogRef.submit(itemValue)
      this.dialogRef.close();
    }
 
  }
 
 onKeyDown(event: KeyboardEvent, index: number) {
  
   let i = index
   let last = this.listItemsRef.first.nativeElement.children.length-1;
  
   if (this.listItemsRef != null && this.listItemsRef.first != null) {
     switch (event.key) {
       case "ArrowUp":
           event.stopPropagation();
           if (index > 0)  i = index-1;
           else  i = last;
           this.listItemsRef.first.nativeElement.children[i].focus();
           break;
     
       case "ArrowDown":
           event.stopPropagation();
           if (index < last) i = index+1;
           else i =0
           this.listItemsRef.first.nativeElement.children[i].focus();
           break;
       
       case "Enter":
        
       case " ":
             this.onClick(this.listItemsRef.first.nativeElement.children[i].innerText,i)
             if (this.DropDown.MULTISELECT) this.listItemsRef.first.nativeElement.children[i].focus();
             break;
     }
   } 
  }
}
