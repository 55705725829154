<div class="role-landing-continer">

    <div class="header-wrapper">
        <div class="header-col-1">
            <div class="custom-search">
                <img src="assets/settings/search-small.svg">
                <input type="search" id="input" placeholder="Type to search here" [(ngModel)]="searchUserList"
                    (click)="$event.stopPropagation()">
                <!--<span class="searchline-separtor"></span>
                <button type="button"><img src="assets/settings/search-small.svg"></button>-->
            </div>
        </div>

        <div class="header-col-2">
            <div class="add-user-btn" (click)="addNewRole()">
                <img src="assets/settings/user-role.svg" alt="">
                <p>Add New Role</p>
            </div>
        </div>

    </div>
    <div class="body-wrapper">
        <div class="body-top">
            <div class="body-top-col-1">
                <img src="assets/settings/left-arrow.svg"
                    [ngClass]="settingsVarService.role_pages.length > 1 ? 'enable-action':'disable-action'"
                    (click)="pagination_click('left')">
                <p>{{settingsVarService.role_pages.presentPage}} of {{settingsVarService.role_pages.length >0 ?
                    settingsVarService.role_pages.length:'1'}} </p>
                <img src="assets/settings/right-arrow.svg"
                    [ngClass]="settingsVarService.role_pages.length > 1 ? 'enable-action':'disable-action'"
                    (click)="pagination_click('right')">
            </div>

            <div class="body-top-col-2">
                <ng-container *ngFor="let pagedat of settingsVarService.commonData.pagination | keyvalue">
                    <p>{{pagedat.key}}</p>
                    <app-overlay-select [formControl]="settingsVarService.rolePagination" [data]="pagedat.value"
                        [style]="{'font-size':'12px'}">
                    </app-overlay-select>
                </ng-container>

            </div>
        </div>
        <div class="body-bottom">
            <ng-container *ngIf="!mat_spinner_roleList_state">
                <ng-container *ngIf="!settingsVarService.errorhandlerolelanding.status">
                    <table>
                        <thead>
                            <tr>
                                <th *ngFor="let header of settingsVarService.roleTableHeader.headers">{{header}}</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr *ngFor="let users of settingsVarService.role_list |searchFilter: searchUserList"
                                (click)="editRole(users.role_name,$event)">
                                <td> {{users.role_name}} </td>
                                <td> {{users.role_type}}</td>
                                <td #menuOrgin> {{users.authorized_menu[0]}}
                                    <button (click)=" $event.stopPropagation()"
                                        (click)="openSourceMenu_click(menu,menuOrgin)"
                                        [disabled]="users.authorized_menu.length == 1">
                                        <span
                                            *ngIf="users.authorized_menu.length > 1">{{users.authorized_menu.length}}</span>
                                    </button>

                                </td>
                                <ng-template #menu>
                                    <div class="source-container">
                                        <p *ngFor="let menu of users.authorized_menu">{{menu}}</p>
                                    </div>
                                </ng-template>
                                <td> {{users.last_modified}} </td>
                                <td [matTooltip]="users.assigned_user"> {{users.assigned_user | limitCharacter:35}}
                                </td>
                                <td> {{users.created_by}} </td>
                                <td #sourceOrgin> {{platformMap[users.src[0]]}}
                                    <button (click)="$event.stopPropagation()"
                                        (click)="openSourceMenu_click(source,sourceOrgin)"
                                        [disabled]="users.src.length == 1">
                                        <span *ngIf="users.src.length > 1">{{users.src.length}}</span>
                                    </button>

                                </td>
                                <ng-template #source>
                                    <div class="source-container">
                                        <p *ngFor="let src of users.src">{{platformMap[src]}}</p>
                                    </div>
                                </ng-template>
                                <td>

                                    <img class="show-btn" (click)="editRole(users.role_name,$event)"
                                        src="assets/settings/edit-row.svg">
                                    <img *ngIf="users.role_type == 'Unreserved'" class="show-btn"
                                        (click)="deleteUser(users.role_name,$event)" src="assets/settings/delete.svg">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ng-container>
                <ng-container class="nodata-container" *ngIf="settingsVarService.errorhandlerolelanding.status">
                    <div class="rolelist-nodata">
                        <div class="nodata-img">
                            <img src="assets/designer/empty-data.svg">
                        </div>
                        <div>
                            <p>{{settingsVarService.errorhandlerolelanding.errmsg}}</p>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="mat_spinner_roleList_state">
                <div class="body-bottom-spinner">
                    <mat-spinner class="spinner" diameter=20></mat-spinner>
                </div>
            </ng-container>
            <ng-container *ngIf="mat_spinner_roleList_editState">
                <div class="body-bottom-spinner_edit">
                    <mat-spinner class="spinner" diameter=20></mat-spinner>
                </div>
            </ng-container>
        </div>
    </div>
</div>