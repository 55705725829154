import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'limitString'
})
export class LimitString implements PipeTransform {

  transform(value:any, limit:number) {

    if(value){
      let val = value
      if(typeof(val)=='number'){
        val = val.toString()
      }      
      if(val.length <= limit){
        return val.slice(0,limit);
      }else if(val.length > limit){
        return val.slice(0,limit)+'...';
      }
      
    }
    
  }
}
