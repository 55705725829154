import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { DashboardService } from 'src/app/services/dashboard.service';
import { UserLoginService } from 'src/app/user-login/user-login.service';
import { ErrorMsgHandler } from 'src/app/shared/errormanagement/handleerror';


@Component({
  selector: 'app-dashboard-label',
  templateUrl: './dashboard-label.component.html',
  styleUrls: ['./dashboard-label.component.scss']
})

export class DashboardLabelComponent implements OnInit, OnDestroy {

  @Input() widgetName: string;
  @Input() widgetImg: string;
  @Input() defaultDate:any;

  widget$: Observable<any>;
  widgetDetail:any;
  spinnerState:boolean = true
  widgetrequestinstance:any;
  $destroy:Subject<boolean> = new Subject();
  errorhandlelabeldwidget = new ErrorMsgHandler()
  labelhaserror:boolean = false;
  // widget; 
  constructor(
    private dashboardService: DashboardService,
    private loginService: UserLoginService,

  ) { }

  labelRequestObj = {
    'hosts': ['all'],
    'widget_type': 'Label',
    'widget_name': '',
    'timeRange': ['']
  }

  noOfDays = this.loginService.metaData.dashboard.dateFilter_default.default_fromDt
  fromDate
  toDate
  widgetRequestObj:any = {};
  defaulttimerange:any;

  ngOnInit(): void {
    this.dashboardService.getdashboardlanding.isProgress$.pipe(takeUntil(this.$destroy)).subscribe(progress=>{

      if(!progress){
        if(this.dashboardService.dashboardLandingData['isData']){
          // this.setWidgetDetails(this.dashboardService.dashboardLandingData['res_obj'][this.widgetName+this.defaultDate])
          this.setWidgetDetails(this.dashboardService.dashboardLandingData['res_obj'][this.widgetName])

        } else {
          this.setErrormsg(this.dashboardService.dashboardLandingData)
        }
        this.spinnerState = false
      } else {
        // this.spinnerState = true
      }
    })


  }

  
  setWidgetDetails(labeldetails:any){
    if(labeldetails && labeldetails.hasOwnProperty('widgetLabelData')){
      this.widgetDetail = labeldetails;
      if (labeldetails.hasOwnProperty('error')){
        this.labelhaserror  = true;
      } 
    } else if(labeldetails){
      this.setErrormsg(labeldetails);
    }
    
  }

  setErrormsg(err:any){
    this.errorhandlelabeldwidget.seterror(true,err.error?.errmsg);
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
  }

}
