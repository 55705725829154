import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserLoginService } from '../user-login.service';

@Component({
  selector: 'app-terminate-session',
  templateUrl: './terminate-session.component.html',
  styleUrls: ['./terminate-session.component.scss']
})
export class TerminateSessionComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public loginService:UserLoginService,
    private ref:MatDialogRef<TerminateSessionComponent>,
  ){}
  ngOnInit():void {

  }
  terminate_session_alert(){
    this.ref.close(true);
  }
  cancel(){
    this.ref.close(false);
  }

}
