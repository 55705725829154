import { InjectionToken, TemplateRef } from '@angular/core';

export class ToastData {
    type: ToastType;
    text?: string;
    template?: TemplateRef<any>;
    templateContext?: {};
}

export type ToastType = 'warning' | 'failure' | 'success' | 'neutral';

export interface ToastConfig {
    position?: {
        top: number;
        right: number;
    };
}

export const defaultToastConfig: ToastConfig = {
    position: {
        top: 60,
        right: 20,
    }
};

export const TOAST_CONFIG_TOKEN = new InjectionToken('toast-config');