import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name:'novalueAsset'
})
export class NoValueAssetPipe implements PipeTransform{
    transform(obj:any) {
        const arr=[]
        for(let key in obj){
            if(obj[key].length){
                arr.push(true)
            }else{
                arr.push(false)
            }
        }
        return !arr.some(elem=>elem==true)
    }
}