<div class="tab-container">
    <ul class="nav nav-tabs">
        <li *ngFor="let tab of tabs; let i = index" (click)="selectTab(tab,i)" [ngClass]="clickchange ? 'enable-action':'disable-action'" [class.active]="tab.active">
          <a>{{tab.title}}</a>
        </li>
    </ul>
    <!-- <div class="close-icon">
        <button (click)="back_click()" class="cancel">Back</button>
    </div> -->
</div>

<ng-content></ng-content>
