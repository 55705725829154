import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { CDK_OVERLAY_DROPDOWN } from '../../custom-overlay/cdk-overlay-injector';
import { DateOverlayRef } from '../date-picker.ref';

@Component({
  selector: 'app-date-picker-overlay',
  templateUrl: './date-picker-overlay.component.html',
  styleUrls: ['./date-picker-overlay.component.scss']
})
export class DatePickerOverlayComponent implements OnInit {

  constructor(
    private fb:UntypedFormBuilder,
    private dialogRef:DateOverlayRef,
    @Inject(CDK_OVERLAY_DROPDOWN) public data:any
  ) { }

  dateArr:any = [...Array(32).keys()].splice(1)

  dates = this.fb.array([])

  ngOnInit(): void {

    for(let date of this.data){
      this.dates.push(new UntypedFormControl(date))
    }
    this.dialogRef._dateValues.next(this.dates.value)
  }

  date_click(date:number){
    const index = this.dates.value.indexOf(date)
    if( index >= 0){
      this.dates.removeAt(index)
    }else{
      this.dates.push(new UntypedFormControl(date))
    }
    this.dialogRef._dateValues.next(this.dates.value)
  }

}