<div class="display-table-container">
  <table id="displayTable">
    <thead>
      <tr>
        <ng-container *ngFor="let column of headers | keyvalue:originalOrder">
          <th>{{column.value}}</th>
        </ng-container>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of rows, let i=index" id="tab{{i}}">
        <td *ngFor="let column of headers | keyvalue:originalOrder, let j=index" id="cell{{i}}{{j}}">{{row[column.key]}}</td>
      </tr>
    </tbody>
  </table>
</div>
