import { AfterViewInit, Component, Inject, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ConfirmedValidator } from 'src/app/shared/validators/matchPassword.validator';
import { SameQuestionValidator } from 'src/app/shared/validators/samequestion.validator';
import { UserLoginService } from '../user-login.service';

@Component({
  selector: 'app-userprofile',
  templateUrl: './userprofile.component.html',
  styleUrls: ['./userprofile.component.scss']
})
export class UserprofileComponent implements OnInit {

  qtnList
  metadata
  public showans1: boolean
  public showans2: boolean
  showerror: boolean = false
  userStatus
  myProfileRecieved
  myProfileForm: UntypedFormGroup
  commonData
  inputBoxEmitChar =  this.userService.metaData.commonData.specialCharList.charList;
  emailchar =  this.userService.metaData.commonData.specialCharList.emailCharList;
  $destroy:Subject<boolean>=new Subject()
  mat_spinner_userProfile_state:boolean

  constructor(
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private ref: MatDialogRef<UserprofileComponent>,
    private userService: UserLoginService) {
      {ref.disableClose = true;}
     }

  validationData = this.userService.metaData.commonData.userDetailvalidationData;
  

  ngOnInit(): void {
    this.qtnList = this.userService.metaData.userLogin.qtnList
    this.metadata = this.userService.metaData.userLogin.editProfile
    this.commonData = this.userService.metaData.commonData
    
    this.myProfileForm = this.fb.group({
      username: [''],
      email: ['', [Validators.required, Validators.email, Validators.maxLength(this.validationData.email.maxlength)]],
      mobile: ['', [Validators.required, Validators.pattern("^[0-9]{10}$")]],
      qtn1: ['', [Validators.required]],
      qtn2: ['', [Validators.required]],
      ans1: ['', [Validators.required, Validators.maxLength(this.validationData.secret_ques.maxlength),Validators.minLength(this.validationData.secret_ques.minlength)]],
      ans2: ['', [Validators.required, Validators.maxLength(this.validationData.secret_ques.maxlength),Validators.minLength(this.validationData.secret_ques.minlength)]],
      ans1Change: [false],
      ans2Change: [false],
      mobileChange: [false],
      emailChange: [false]
    }, {
      validators: SameQuestionValidator('qtn1', 'qtn2')
    })

    this.myProfileRecieved = this.data.userProfile
    this.myProfileForm.controls.mobile.patchValue((this.myProfileForm.controls.mobile.value).toString());
    this.userStatus = this.myProfileRecieved.status
    this.myProfileForm.patchValue(this.data.userProfile, { emitEvent: false })
    if (!this.myProfileRecieved.qtn1 || !this.myProfileRecieved.qtn2) {
        this.myProfileForm.controls.qtn1.setValue(this.qtnList[0])
        this.myProfileForm.controls.qtn2.setValue(this.qtnList[1])
    }

    this.myProfileForm.controls.email.valueChanges.subscribe(email => {
      if (this.myProfileRecieved.email == email|| email==='') {
        this.myProfileForm.controls.emailChange.setValue(false)
      }else {
        this.myProfileForm.controls.emailChange.setValue(true)
      }
    })

    this.myProfileForm.controls.mobile.valueChanges.subscribe(mobile => {
      if ((this.myProfileRecieved.mobil === mobile.toString) || mobile ==='') {
        this.myProfileForm.controls.mobileChange.setValue(false);
      }else{
        this.myProfileForm.controls.mobileChange.setValue(true);
      }
    })

    this.myProfileForm.controls.qtn1.valueChanges.subscribe(val => {
      if (this.myProfileRecieved.qtn1 != val) {
        this.myProfileForm.controls.ans1.reset()
      }
    })

    this.myProfileForm.controls.ans1.valueChanges.subscribe(val => {
      let answer1 = this.myProfileForm.controls.ans1.value
      if (this.myProfileRecieved.ans1 != val && !answer1?.includes('**')) {
        this.myProfileForm.controls.ans1Change.setValue(true)
      } else {
        this.myProfileForm.controls.ans1Change.setValue(false)
      }
    })

    this.myProfileForm.controls.ans2.valueChanges.subscribe(val => {
      let answer2 = this.myProfileForm.controls.ans2.value
      if (this.myProfileRecieved.ans2 != val && !answer2?.includes('**')) {
        this.myProfileForm.controls.ans2Change.setValue(true)
      } else {
        this.myProfileForm.controls.ans2Change.setValue(false)
      }
    })

    this.myProfileForm.controls.qtn2.valueChanges.subscribe(val => {
      if (this.myProfileRecieved.qtn2 != val) {
        this.myProfileForm.controls.ans2.reset()
      }
    })

    this.username.disable();
  }

  close() {
    this.ref.close()
  }

  mobileDataChange(val:any,state:number){
    if(state){
      Number(val.mobile)
    }else{
      val.mobile.toString()
    }
    return val
  }

  get Ques1() {
    return this.myProfileForm.get('qtn1')
  }

  get Ques2() {
    return this.myProfileForm.get('qtn2')
  }

  get ans1() {
    return this.myProfileForm.get('ans1')
  }

  get ans2() {
    return this.myProfileForm.get('ans2')
  }

  get username() {
    return this.myProfileForm.get('username')
  }

  get email() {
    return this.myProfileForm.get('email')
  }

  get mobile() {
    return this.myProfileForm.get('mobile')
  }

  get myProfile() {
    return this.myProfileForm.controls
  }

  submitProfile_click() {
    if(this.myProfileForm.dirty){
      if (this.myProfileForm.valid) {
        this.mat_spinner_userProfile_state=true
        this.showerror = false
        if (this.myProfileForm.controls.ans1Change.value || this.myProfileForm.controls.ans2Change.value || this.myProfileForm.controls.mobileChange.value || this.myProfileForm.controls.emailChange.value) {
          this.mobileDataChange(this.myProfileForm.value,1);
          this.userService.postUserProfile({ myprofileForm: this.myProfileForm.getRawValue(), status: this.userStatus })
          .pipe(takeUntil(this.$destroy))
          .toPromise()
          .then((val:any)=>{
            this.mat_spinner_userProfile_state=false
            this.userService.showToaster(val.status,'success')
            this.ref.close();
          })
          .catch(()=>{
            this.ref.close()
          })
        }
      }else {
        this.showerror = true;
      }
    }else{
      this.ref.close()
    }
  }

  cancel() {
    this.ref.close();
  }

  neOnDestroy():void{
    this.$destroy.next();
    this.$destroy.unsubscribe();
  }
}