import { AbstractControl, ValidatorFn } from "@angular/forms";

export function VerifyPasswordCombination(error:boolean):ValidatorFn {
    
    return (control:AbstractControl): {[key:string]:any} | null => {

        if(error && control.value){
            const regex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])")
            if(!regex.test(control.value)){
                return { invalidcombination:true }
            }

        }else{
            return null
        }

        
    }   
}