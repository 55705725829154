import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name:'oneError',
})

export class OnlyOneError implements PipeTransform {
    transform( errorMsg: any, operatorErrors:any):any {
        if(operatorErrors){
            return errorMsg[Object.keys(operatorErrors)[0]];
        }
        return null;
    }
}