import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AfterViewInit, Injectable, OnInit } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { BehaviorSubject, of, Subject } from "rxjs";
import { UserLoginService } from "src/app/user-login/user-login.service";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class AssetDropdownService implements OnInit, AfterViewInit {

    assetsSpinnerState: boolean = true;

    assetshostdata: any;
    assetstagdata: any;

    platformSelected: UntypedFormControl = new UntypedFormControl('')

    _savedAssets: Subject<any> = new Subject();

    //defaultSearch:string = 'windows'
    headers = new HttpHeaders({ 'content-type': 'application/json', 'charset': 'utf-8' });

    assetsMetaData: any;
    lables: any;

    constructor(
        private http: HttpClient,
        private loginService: UserLoginService,
    ) {
        this.loginService.userLoggedoff_.subscribe((val: boolean) => {
            this.platformSelected.setValue('');
            this.assetshostdata = null;
            this.assetstagdata = null;
        })

        this.loginService.customerChanged_.subscribe(() => {
            this.platformSelected.setValue('');
            this.assetshostdata = null;
            this.assetstagdata = null;
        })
    }

    ngOnInit(): void {
    }


    ngAfterViewInit(): void {
    }

    loadmetaData() {
        console.log("====>loadmetaData", this.loginService.metaData.logsearch)
        this.assetsMetaData = JSON.parse(JSON.stringify(this.loginService.metaData.logsearch));
        this.lables = this.assetsMetaData.log_search_common.lables;
        return this.assetsMetaData;
    }

    loadlabels() {
        return JSON.parse(JSON.stringify(this.loginService.metaData.commonData.assetlabels));
    }

    loadplatformdata() {
        return JSON.parse(JSON.stringify(this.loginService.metaData.commonData.platformOptions.platformList));
    }

    getHostsList(request: any) {
        return this.http.post<any>(environment.apiUrl + "/assets/hosts/get", JSON.stringify(request), { headers: this.headers });
    }

    getHostsCategories(request: any) {
        return this.http.post(environment.apiUrl + '/assets/commonData/get', request, { headers: this.headers });
    }

    getCommonPlatformFields(platform: any) {
        return this.http.post(environment.apiUrl + '/common/platformFields/get', JSON.stringify(platform), { headers: this.headers })
    }

} 