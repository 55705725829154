import { OverlayRef } from '@angular/cdk/overlay';
import { Subject } from 'rxjs';

export class cdkOverlayRef {

  private _selectValue = new Subject<void>();
  $selectedValue = this._selectValue.asObservable();

  private _closed = new Subject<boolean>();
  $closed = this._closed.asObservable();

  constructor(private overlayRef: OverlayRef) {
  }

  close(): void {
    this._selectValue.complete();
    this._closed.next(true)
    this.overlayRef.dispose();
  }

  closeoverlay(){
    this.overlayRef.dispose();
  }

  submit(val){
    this._selectValue.next(val);
  }

  isVisible() {
    return this.overlayRef && this.overlayRef.overlayElement;
  }

  getPosition() {
    return this.overlayRef.overlayElement.getBoundingClientRect()
  }
}