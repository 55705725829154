import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable({
    providedIn:'root'
})

export class TagAutocompleteService{
    
    _moreclicked:Subject<HTMLElement>= new Subject()
    $moreclicked:Observable<HTMLElement> = this._moreclicked.asObservable();

}