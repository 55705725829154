import { Injectable, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { UserLoginService } from '../user-login/user-login.service';

export interface Crump {
  disp_str: string,
  routeTo: string,
  callback: Subject<null> | null,
  isParent: boolean
}

@Injectable({
  providedIn: 'root'
})
export class RoutecrumpsService implements OnInit {

  // label:any = [];
  constructor(
    private userloginService: UserLoginService,
  ) {
    this.userloginService.$menu_array.subscribe((val) => {
      this.applist = val
      this.setMenuList()
    },
      (err) => {
        // console.log(err);
    })
    // this.userloginService.$metaDataUpdated.subscribe((val)=>{
    //   console.log("val",val)
    //   this.label = val.commonData;
    // }, (err)=>{
    //   console.log(err)
    // })
  }
  ngOnInit(): void {
   console.log(this.userloginService.metaData)
  }
  setMenuList() {
    this.applist.forEach((category, i) => {
      category.forEach((element, j) => {
        this.applist[i][j] = { ...element, ...{ "isSelected": false } }
      });
    });
  }
  resetMenuList() {
    this.applist.forEach((category, i) => {
      category.forEach((element, j) => {
        this.applist[i][j]["isSelected"] = false
      });
    });
  }
  route_crumps: Crump[] = [];
  label = ["Search", "Manage", "Operate"]
  homeIcon = {
    img_url: "assets/menuicons/home.svg",
    label: "Home",
    url: "home",
    isSelectable: true,
    isSelected: false
  }
  // applist = [
  //   [
  //     {
  //       label: "Explore logs",
  //       url: "explorelogs",
  //       content: "Quickly find logs, events, and incidents across the system",
  //       img_url: "assets/menuicons/explore.svg",
  //       isSelectable: true
  //     },
  //     {
  //       label: "Rule",
  //       url: "rule",
  //       content: "Define and manage security policies to automate threat detection",
  //       img_url: "assets/menuicons/rule.svg",
  //       isSelectable: true
  //     },
  //     {
  //       label: "Dashboard",
  //       url: "dashboard",
  //       content: "Real-time insights into security events and system health",
  //       img_url: "assets/menuicons/dashboard.svg",
  //       isSelectable: true
  //     },
  //     {
  //       label: "Reports",
  //       url: "",
  //       content: "Analyze outliers reported by machine learning models",
  //       img_url: "assets/menuicons/report.svg",
  //       isSelectable: false
  //     }
  //   ],
  //   [
  //     {
  //       label: "User",
  //       url: "user",
  //       content: "Manage user accounts and access privileges",
  //       img_url: "assets/menuicons/user.svg",
  //       isSelectable: true
  //     },
  //     {
  //       label: "Role",
  //       url: "role",
  //       content: "Define and assign roles to control system access",
  //       img_url: "assets/menuicons/role.svg",
  //       isSelectable: true
  //     },
  //     {
  //       label: "Studio",
  //       url: "designer",
  //       content: "Create and customize interactive dashboards for security insights",
  //       img_url: "assets/menuicons/studio.svg",
  //       isSelectable: true
  //     }
  //   ],
  //   [
  //     {
  //       label: "Fleet Management",
  //       url: "",
  //       content: "Understand and manage the health of the log sources",
  //       img_url: "assets/menuicons/fleet.svg",
  //       isSelectable: false
  //     },
  //     {
  //       label: "Monitoring",
  //       url: "",
  //       content: "Tracking and analyzing user actions for suspicious behavior",
  //       img_url: "assets/menuicons/monitor.svg",
  //       isSelectable: false
  //     },
  //     {
  //       label: "Deploy",
  //       url: "",
  //       content: "Install and configure agents to monitor and collect data from endpoints",
  //       img_url: "assets/menuicons/deploy.svg",
  //       isSelectable: false
  //     }
  //   ]
  // ]

  applist = []
}
