import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";

@Injectable({
    providedIn:'root'
})

export class RuleAutocompleteService{

    inputval:string = ''
    navnumber:BehaviorSubject<number> = new BehaviorSubject(0)
}