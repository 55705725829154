import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { UserLoginService } from "../user-login/user-login.service";

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private accessTokentimeOut
    private refreshTokentimeOut
    headers = new HttpHeaders().set('Content-Type', 'application/json');

    constructor(
        private http: HttpClient,
        private userLoginservice: UserLoginService
    ) { }

    requestAccessToken() {

        //Pressing login byutton initiates the timer to ask new access token and refresh token.

        this.startAccessTokenTimer()
        this.startRefreshTokenTimer()
    }
    accessToken(request = { "change_status": false, "customer": "" }) {
        if (sessionStorage.getItem('SRID')) {
            let refreshToken = sessionStorage.getItem('SRID')
            this.http.post(environment.apiUrl + '/login/auth/accessToken/get', request, { headers: { 'SRID': refreshToken }, observe: "response", withCredentials: true })
                .subscribe((response: any) => {
                    if (response.headers.has('SPL')) {
                        let payload = response.headers.get('SPL')
                        sessionStorage.setItem('SPL', payload)
                    }
                    if (response.headers.has('SRID')) {
                        let refreshToken = response.headers.get('SRID')
                        sessionStorage.setItem('SRID', refreshToken)
                    }
                    this.startAccessTokenTimer()
                }),
                (err) => {
                    console.log(err)
                }
        }
    }

    refreshToken() {
        if (sessionStorage.getItem('SRID')) {
            let refreshToken = sessionStorage.getItem('SRID')
            this.http.post(environment.apiUrl + '/login/auth/refreshToken/get', {}, { headers: { 'SRID': refreshToken }, observe: "response", withCredentials: true })
                .subscribe((response: any) => {
                    if (response.headers.has('SPL')) {
                        let payload = response.headers.get('SPL')
                        sessionStorage.setItem('SPL', payload)
                    }
                    if (response.headers.has('SRID')) {
                        let refreshToken = response.headers.get('SRID')
                        sessionStorage.setItem('SRID', refreshToken)
                    }
                    this.startRefreshTokenTimer()
                }),
                (err) => {
                    console.log(err)
                }
        }
    }

    startRefreshTokenTimer() {
        let refreshToken = JSON.parse(sessionStorage.getItem('SRID'))
        let refreshTokenSplitted = refreshToken.split('.')
        let refreshPayload = atob(refreshTokenSplitted[1])
        let refreshPayloadObj = JSON.parse(refreshPayload)
        let expire = new Date((refreshPayloadObj.exp - refreshPayloadObj.iat) * 1000)
        let timer = expire.getTime() - (10 * 60 * 1000)
        this.refreshTokentimeOut = setTimeout(() => this.refreshToken(), timer)
    }

    startAccessTokenTimer() {
        let payloadObj = atob(JSON.parse(sessionStorage.getItem('SPL')))
        let payload = JSON.parse(payloadObj)
        let expire = new Date((payload.exp - payload.iat) * 1000)
        let timer = expire.getTime() - (60 * 1000)
        this.accessTokentimeOut = setTimeout(() => this.accessToken(), timer)
    }

    logout() {
        if (this.userLoginservice.$userLogged.getValue()) {
            clearTimeout(this.accessTokentimeOut)
            clearTimeout(this.refreshTokentimeOut)
            this.userLoginservice.$userLogged.next(false)
            this.userLoginservice.$userLoggedoff.next(true)
            const username = this.userLoginservice.userName;
            // this.userLoginservice.userName = null;
            return this.http.post(environment.apiUrl + '/userlogin/logout/post',
                { "user_name": username }, { headers: this.headers, withCredentials: true })
        }
    }

    closePreview() {
        if (this.userLoginservice.previewRef) {
          this.userLoginservice.previewRef?.close()
        }
      }

    terminate_session(){
        if (this.userLoginservice.$userLogged.getValue()) {
            clearTimeout(this.accessTokentimeOut)
            clearTimeout(this.refreshTokentimeOut)
            this.userLoginservice.$userLogged.next(false)
            this.userLoginservice.$userLoggedoff.next(true)
            this.closePreview()
            // this.userLoginservice.userName = null;
        }
    }

}