import { HttpClient, HttpHeaders } from "@angular/common/http"
import { Observable, Subject, Subscription } from "rxjs"
import { ErrorService } from "src/app/error/err.service";


export class LongPollingStatelessProgress {

    private isProgress_:Subject<boolean> = new Subject();
    isProgress$ = this.isProgress_.asObservable();
    private isprogress:boolean = false;
    private stoppolling:boolean = false;
    private emitData:Subject<any>= new Subject();
    private subscription:Subscription = Subscription.EMPTY;
    private timeIntervalState:boolean = false;
    private maxCount = 0;
    private token:string;

    constructor(
        private requestmethod:Function,
        public http:HttpClient,
        private userloggedout:Observable<boolean>,
        private errorservice :ErrorService,
        private url:string,
        private moduleName:string,
    ){
        this.userloggedout.subscribe((val:any)=>{
            this.cancelRequest();
        });
    }

    toStringify(postData:any){
        return JSON.stringify(postData)
    }

    headers = new HttpHeaders({'content-type': 'application/json'})

    postrequest(requestobj:any){
        if(!this.isprogress){
            this.isprogress = true;
            this.isProgress_.next(true);
            this.stoppolling = false;
            this.emitData = new Subject();
            this.subscription = this.requestmethod(this.toStringify({"request_type": "handshake"})).subscribe((dat:any)=>{
                if(dat){
                    this.token = dat.token;
                    this.startLongPolling({...requestobj,"token":dat.token,"request_type":dat.request_type})
                }else{
                    console.log("request returned null")
                    this.errorReset();
                    this.emitData.error({});
                }
            },(err:any)=>{
                this.errorReset()
                this.emitData.error(err);
            })
        }        
        return this.emitData.asObservable();
    }

    startLongPolling(requestobj:any){
        this.subscription = this.requestmethod(this.toStringify(requestobj)).subscribe((dat:any)=>{
            if(dat){
                if(dat.isData){
                    this.emitData.next(dat.res_obj);
                    this.completeReset();
                }else if(dat.isError){
                    this.showError(dat.error.errmsg,'failure')
                    this.errorReset();
                    this.errorservice.postOnlineErrors(this.createErrorObject(dat,1,1),1);
                    this.emitData.error(dat);
                }else{
                   this.continuousRequest({"token":dat.token,"request_type":dat.request_type}) 
                }
            }else{
                console.log("request returned null")
                this.errorReset();
                this.emitData.error({});
            }
            
        })
    }

    continuousRequest(requestobj:any){
        if(this.maxCount < 3 && !this.stoppolling){
            this.subscription = this.requestmethod(this.toStringify(requestobj)).subscribe((dat:any)=>{
                if(dat){
                    if(dat.isData){
                        this.emitData.next(dat.res_obj);
                        this.completeReset();
                    }else if(dat.isError){
                        this.showError(dat.error.errmsg,'failure')
                        this.errorReset();
                        this.errorservice.postOnlineErrors(this.createErrorObject(dat,1,1),1);
                        this.emitData.error(dat);
                    }else{
                        this.maxCount++;
                       this.continuousRequest({"token":dat.token,"request_type":dat.request_type})
                    }
                }else{
                    console.log("request returned null")
                    this.errorReset();
                    this.emitData.error({});
                }
            })
        }else{
            this.emitData.error({error:{errmsg:"Server takes long time then expected"}});
            this.maxCount = 0;
            this.errorReset();
            this.showError('Server takes long time then expected','failure');
            this.errorservice.postOnlineErrors(this.createErrorObject('Maximum number of api request exceeded',1,0),1);
        }
        
    }

    completeReset(){
        this.isProgress_.next(false);
        this.isprogress = false;
        this.maxCount = 0;
       
    }

    errorReset(){
        this.isProgress_.next(false);
        this.isprogress = false;
    }

    showError(errormsg:string,type:string){
        this.errorservice.showError(this.moduleName +" :"+ errormsg,type);
    }

    createErrorObject(val:any,errorState:number,customstate:number){
        const errobj = this.errorservice.httpobjectBuilder(errorState,customstate,val);
        errobj.url = this.url;
        return errobj;
    }

    refresh(){
        this.isProgress_.next(false);
    }

    cancelRequest(){
        this.isProgress_.next(false);
        this.subscription.unsubscribe();
        if(this.isprogress){
            this.isprogress = false;
            this.cancelCall();
            this.token = "";
            this.stoppolling = true;
        }
    }

    cancelCall(){
        let reqobj = {"token":this.token,"request_type":"cancel"}
        this.requestmethod(reqobj).subscribe((canceled)=>{})
    }

    getprogress(){
        return this.isprogress;
    }
}