import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { SharedModule } from "./shared/shared.module";
import { MatMenuModule } from '@angular/material/menu';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { UserLoginComponent } from './user-login/user-login.component';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthInterceptor } from './auth/auth.interceptor';
import { AuthService } from './auth/auth.service';
import { ErrorInterceptor } from './error/err.interceptor';
import { ResetpwdComponent } from './user-login/reset-pwd/reset-pwd.component';
import { NewPwdComponent } from './user-login/new-pwd/new-pwd.component';
import { UserprofileComponent } from './user-login/userprofile/userprofile.component';
import { GlobalErrorHandler } from './error/error.handler';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip'
import { HomeComponent } from './home/home.component';
import { SettingsModule } from './settings/settings.module';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions } from '@angular/material/tooltip';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { TerminateSessionComponent } from './user-login/terminate-session/terminate-session.component';


export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 1000,
  hideDelay: 100,
  touchendHideDelay: 1000,
  position: 'below',

};


@NgModule({
  declarations: [
    AppComponent,
    UserLoginComponent,
    ResetpwdComponent,
    NewPwdComponent,
    UserprofileComponent,
    HomeComponent,
    TerminateSessionComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    MatMenuModule,
    HttpClientModule,
    CommonModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    SettingsModule,
    MatTooltipModule,
    ClipboardModule
  ],
  providers: [
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }