import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name:'disableAction'
})

export class DisableActionPipe implements PipeTransform {
    transform(pagenumber:number,pagelength:number, direction:number, isAction=false) {
        if (pagenumber === null){
            return null;
        }

        if (direction === 1) {
            if(pagelength > pagenumber && !isAction){
                return 'pagination-enable-action';
            } else {
                return 'pagination-disable-action';
            }
        } else if (direction === -1){
            if (pagenumber > 1 && !isAction) {
                return 'pagination-enable-action';
            } else {
                return 'pagination-disable-action';
            }
        }       
    }
}