import { Injectable, Injector, ComponentRef, TemplateRef} from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef, ConnectedPosition } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { DateOverlayRef } from './date-picker.ref';
import { CDK_OVERLAY_DROPDOWN } from '../custom-overlay/cdk-overlay-injector';
import { DatePickerOverlayComponent } from './date-picker-overlay/date-picker-overlay.component';

interface PickDateOverlayConfig{
  hasBackdrop: boolean,
  backdropClass: string,
  data?:any
}

const DEFAULT_CONFIG: PickDateOverlayConfig = {
  hasBackdrop: true,
  backdropClass: 'cdk-overlay-transparent-backdrop',
}

@Injectable({
  providedIn: 'root'
})
export class DatePickerService {

  constructor(public overlay:Overlay, private injector:Injector) { }

  position:ConnectedPosition[]=[
    {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top',
    },
    {
      originX: 'start',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'bottom',
    }
  ]

  open(origin:TemplateRef<any>, data:any){

    const overlayconfig = this.getOverlayConfig(DEFAULT_CONFIG, origin);
    const overlayRef = this.overlay.create(overlayconfig);
    const dialogRef= new DateOverlayRef(overlayRef);

    /* overlayRef.backdropClick().subscribe((dat)=>{
      overlayRef.dispose()
    }) */

    this.attachDialogContainer(dialogRef,data,overlayRef);
    return dialogRef;

  }

  getOverlayConfig(config:PickDateOverlayConfig, origin:any){

    const positionStrategy = this.overlay.position()
    .flexibleConnectedTo(origin)
    .withPositions(
      this.position
    )
    const overlayConfig = new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy
    });
    return overlayConfig;

  }

  attachDialogContainer(dialogRef:DateOverlayRef,data:any,overlayRef:OverlayRef){

    const injector = this.createInjector(dialogRef,data)
    const datePickerPortal = new ComponentPortal(DatePickerOverlayComponent,null,injector)
    overlayRef.attach(datePickerPortal);
  }

  createInjector(dialogRef:DateOverlayRef, config:any) {
    const injectionTokens = new WeakMap();

    injectionTokens.set(DateOverlayRef,dialogRef)
    injectionTokens.set(CDK_OVERLAY_DROPDOWN,config)

    return new PortalInjector(this.injector, injectionTokens)
  }

}
