import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { RoutecrumpsService } from 'src/app/services/routecrumps.service';
import { UserLoginService } from 'src/app/user-login/user-login.service';
import { ChangeValidationComponent } from './change-validation/change-validation.component';
import { SettingsService } from '../settings.service';
import { SettingsVarService } from '../settings-var.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss']
})
export class OrganizationComponent implements OnInit {
  mat_spinner_organizationList_state: boolean = false;
  constructor(
    private fb: UntypedFormBuilder,
    public loginService: UserLoginService,
    private rcrumpService: RoutecrumpsService,
    private matdialog: MatDialog,
    private settingsService: SettingsService,
    public settingsVarService: SettingsVarService

  ) { }
  $destroy: Subject<boolean> = new Subject()

  preview_state: boolean
  pages = {   //pagination
    presentPage: 1,
    itemPerPage: 10,
    length: 0
  };
  pagination = this.fb.group({ itemPerPage: [] }) //items per page form control

  metaData = this.loginService.metaData; // All meta data details as object
  nodata = this.loginService.metaData.alert.noDatamsg[1]
  headers = [
    // { "disp": "S.NO", "value": "sno" },
    { "disp": "CREATED ON", "value": "createdDt" },
    { "disp": "SERVICE PROVIDER", "value": "serviceProvider" },
    { "disp": "CUSTOMER", "value": "customerFullName" },
    // { "disp": "Short Name", "value": "customerShortName" },
    // { "disp": "TYPE", "value": "type" },
    { "disp": "LICENSE TYPE", "value": "licenseType" },
    { "disp": "LICENSE VALIDITY", "value": "licenseValidity" },
    { "disp": "", "value": "edit_data" },

  ]


  ngOnInit(): void {
    this.createCrumps()
    this.preview_state = false;
    this.load_organizationList()
    // this.getCustomerList().then((customerList) => {
    //   this.settingsVarService.organization_list = customerList
    // }).catch((err) => {
    //   console.log("error")
    // })
  }
  get itemPerPage() {
    return this.settingsVarService.organizationPagination;
  }
  getCustomerList() {
    return new Promise((resolve, reject) => {
      this.settingsService.getCustomers(this.loginService.userName).
        subscribe((data: any) => {
          resolve(data.customerList);
        },
          (error) => {
            reject(error);
          })
    })
  }

  pagination_click(direction) {
    if (this.settingsVarService.organization_pages.length > 1) {
      if (direction == "left") {
        if (this.settingsVarService.organization_pages.presentPage > 1) {
          this.settingsVarService.organization_pages.presentPage--;
          this.load_organizationList();
        }
      } else {
        if (this.settingsVarService.organization_pages.presentPage < this.settingsVarService.organization_pages.length) {
          this.settingsVarService.organization_pages.presentPage++;
          this.load_organizationList();
        }
      }

    }
  }
  load_organizationList() {
    this.mat_spinner_organizationList_state = true
    this.settingsService.getCustomers(this.loginService.userName)
      .pipe(takeUntil(this.$destroy))
      .subscribe((val) => {
        this.mat_spinner_organizationList_state = false
        this.settingsVarService.organization_list = [...val['customerList']];
        console.log(this.itemPerPage.value)
        this.settingsVarService.organization_pages.length = Math.ceil(this.settingsVarService.organization_list.length / this.itemPerPage.value)
      }, (err) => {
        this.mat_spinner_organizationList_state = false
      })
  }
  createCrumps() {
    this.rcrumpService.route_crumps[1] = {
      disp_str: "Organization",
      routeTo: "organization",
      callback: null,
      isParent: false
    };
    this.rcrumpService.route_crumps.length = 2;
  }
  changeCustomer(customer, event) {
    event.stopPropagation();
    if(!customer.isCurrent){
      const addeventsdialogRef = this.matdialog.open(ChangeValidationComponent, {
        width: "30vw", height: "30vh", data: { "customer": customer.customerFullName }, autoFocus: false,
        disableClose: true
      })
    }
    
    // this.loginService.$customerChanged.next(customer)
  }
}
