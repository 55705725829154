<ng-container *ngIf="!preview_state">
    <div class="designer-container">
        <div class="header-wrapper">
            <!-- studio Top Menu is loaded as metadata -->
            <div class="add-new-service-provider">
                <!-- + Dashboard -->
                <img src="assets/Plus-icon-vector-14.svg" alt="+">
                <p>Service Provider</p>
            </div>
            <div class="add-new-customer">
                <!-- + Widget -->
                <img src="assets/Plus-icon-vector-14.svg" alt="+">
                <p>Customer</p>
            </div>
        </div>
        <div class="body-wrapper">
            <div class="body-top">
                <div class="body-top-col-1">
                    <img src="assets/settings/left-arrow.svg"
                        [ngClass]="settingsVarService.organization_pages.length > 1 ? 'enable-action':'disable-action'"
                        (click)="pagination_click('left')">
                    <p>{{settingsVarService.organization_pages.presentPage}} of
                        {{settingsVarService.organization_pages.length >0 ?
                        settingsVarService.organization_pages.length:'1'}} </p>
                    <img src="assets/settings/right-arrow.svg"
                        [ngClass]="settingsVarService.organization_pages.length > 1 ? 'enable-action':'disable-action'"
                        (click)="pagination_click('right')">
                </div>

                <div class="body-top-col-2" [formGroup]="pagination">
                    <ng-container *ngFor="let page of metaData.commonData.pagination | keyvalue">
                        <p>{{page.key}}</p>
                        <div class="pagination-col2-1-1" #origin>
                            <app-overlay-select [formControl]="settingsVarService.organizationPagination"
                                [data]="page.value" [origin]="origin" [style]="{'font-size':'12px'}">
                            </app-overlay-select>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="body-bottom">
                <!-- <ng-container>
                    <div class="body-bottom-spinner">
                        <mat-spinner class="spinner" diameter=20></mat-spinner>
                    </div>
                </ng-container> -->
                <table *ngIf="settingsVarService.organization_list.length">
                    <thead>
                        <tr>
                            <ng-container *ngFor="let column of settingsVarService.organizationTableHeader">
                                <th>{{column.disp}}</th>
                            </ng-container>
                        </tr>
                    </thead>
                    <tbody>
                        <tr (click)="changeCustomer(customer, $event)" *ngFor="let customer of settingsVarService.organization_list, let i=index" id="tab{{i}}"
                            [ngClass]="customer.isCurrent ? 'disable' : 'enable'">
                            <ng-container *ngFor="let column of headers">
                                <td *ngIf="column.value != 'edit_data';else hoverData"> {{customer[column.value]}}</td>
                                <ng-template #hoverData>
                                    <td *ngIf="column.value == 'edit_data' ">
                                        <span class="edit-data">
                                            <img src="assets/settings/changeCustomer.svg"
                                                (click)="changeCustomer(customer, $event)" matTooltip="Change customer" />
                                        </span>
                                    </td>
                                </ng-template>
                            </ng-container>
                        </tr>
                    </tbody>
                </table>
                <div class="studio-list-nodata" *ngIf="!settingsVarService.organization_list.length">
                    <div class="nodata-img">
                        <img src="assets/designer/empty-data.svg">
                    </div>
                    <div>
                        <!-- <p>{{errorhandlestudiolist.status ? errorhandlestudiolist.errmsg:nodata}}</p> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="preview_state">
    <app-designer-build *ngIf="(designType=='Dashboard' || designType=='Report')" [mode]="mode_tochild"
        [editDetails]="previewDetails_tochild" [designType]="designType" [createdDt]='createdDt'
        [widgetsList_toChild]="widgetDetails" [templates]="templatesDetails"
        (cancelDesigner)=cancelDesigner_fromChild($event)>
    </app-designer-build>
    <app-preview *ngIf="designType=='preview'" class="previewContainer" [previewLayout]="previewDetails_tochild"
        [designType]=designType (cancelPreview)=cancelPreview_fromChild()>
    </app-preview>
    <ng-container *ngIf="designType=='Widget'">
        <widget-builder (cancel_widgetbuilder)="cancelWidget_fromChild($event)"
            (spinnercontrol_parent)=controlSpinner($event)>
        </widget-builder>
    </ng-container>
</ng-container>

<!-- <ng-container>
    <div class="body-edit-spinner">
        <mat-spinner class="spinner" diameter=20></mat-spinner>
    </div>
</ng-container> -->

<ng-container *ngIf="mat_spinner_organizationList_state">
    <div class="body-edit-spinner">
        <mat-spinner class="spinner" diameter=20></mat-spinner>
    </div>
</ng-container>



<ng-container *ngIf="loginService.mat_spinner_login_state">
    <div class="spinner-background">
        <div class="inner-spinner">
            <mat-spinner class="spinner" diameter="40"></mat-spinner>
            <p>Loading...</p>
        </div>
    </div>
</ng-container>