import { Directive, ElementRef, HostListener, Input, OnInit } from "@angular/core";
import { UserLoginService } from "src/app/user-login/user-login.service";

@Directive({
    selector:'[specialCharOmit]'
})

export class SpecialCharacterOmitterDirective {

 @Input()charList:any

 constructor(private service:UserLoginService){
}

 @HostListener('keydown',['$event'])
 onKeyAction(event:KeyboardEvent){
    if(this.charList.length){
        this.charList.map((char)=>{
            if(char == event.key){
                event.preventDefault()
            }
        })
    }
 }

 
}