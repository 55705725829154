import { OverlayRef } from '@angular/cdk/overlay';
import { BehaviorSubject } from 'rxjs';

export class DateOverlayRef {

    constructor(public overlayRef:OverlayRef){}

    _dateValues = new BehaviorSubject([])

    close_Overlay_backdrop(){
        this.overlayRef.dispose()
    }
}