import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pendingControl'
})
export class PendingControlPipe implements PipeTransform {

    transform( editState:boolean, formpending:number, tagsState:number) {
        if(editState){
            if(tagsState ==0){
                if(formpending == -1 || formpending ==2 || formpending ==0){
                    return true
                }else if(formpending == 1){
                    return false
                }
            }else{
                if(formpending == -1 || formpending ==2 || formpending ==0){
                    return false
                }else if(formpending == 1){
                    return true
                }
            }

        }else{
            if(tagsState==0){
                return true
            }else{
                return false
            }
        }
    
    }

}
