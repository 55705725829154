import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardLabelComponent } from './dashboard-label/dashboard-label.component';
import { DialogBoxComponent } from './dialog-box/dialog-box.component';
import { DisplayTableComponent } from './display-table/display-table.component';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DashboardWidgetComponent } from './dashboard-widget/dashboard-widget.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { DropdownOverlayComponent } from './custom-overlay/dropdown-overlay/dropdown-overlay.component';
import { TemplateDropdownComponent } from './custom-overlay/template-dropdown/template-dropdown.component';
import { OverlaySelectComponent } from './custom-overlay/overlay-select/overlay-select.component';
import { DatePickerComponent } from './datePicker/date-picker/date-picker.component';
import { DatePickerOverlayComponent } from './datePicker/date-picker-overlay/date-picker-overlay.component';
import { DateHighLighter } from './datePicker/date-picker-overlay/selected-date.pipe';
import { searchFilterPipe } from '../shared/pipes/search-filter.pipe';
import { LimitCharacterPipe } from '../shared/pipes/limit-character.pipe';
import { LimitArrayPipe } from './pipes/limit-array.pipe';
import { ArrayFilterPipe } from './pipes/array-filter.pipe';
import { OnlyOneError } from 'src/app/shared/pipes/only-one-error.pipe';
import { SpecialCharacterOmitterDirective } from './directives/special-char-omitter.directive';
import { ToastComponent } from './custom-overlay/toast/toast.component';
import { defaultToastConfig, TOAST_CONFIG_TOKEN } from './custom-overlay/toast/toast.config';
import { TogglePipe } from './accordion/pipes/toggle.pipe';
import { AccordionComponent } from './accordion/accordion.component';
import { AccordionContent } from './accordion/directives/accordion-content.directive';
import { AccordionTitle } from './accordion/directives/accordion-title.directive';
import { AccordionHeader } from './accordion/directives/accordion-header.directive';
import { AccordionItem } from './accordion/directives/accordion-item.directive';
import { TabComponent } from './assertion/tab/tab.component';
import { TabsComponent } from './assertion/tabs/tabs.component';
import { TagAutocompleteComponent } from './tagautocomplete/tag-autocomplete.component';
import { TagAutocompleteDirective } from './tagautocomplete/tag-autocomplete.directive';
import { TagAutocompleteContentDirective } from './tagautocomplete/tag-autocomplete-content.directive';
import { TagOptionComponent } from './tagautocomplete/tagoption/tag-option.component';
import { TagOptionGroupComponent } from './tagautocomplete/tagoptiongroup/tag-option-group.component';
import { AssetsComponent } from './assets/assets.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ArrayLengthPipe } from './pipes/array-length.pipe';
import { SearchHostsPipe } from './pipes/seach-hosts.pipe';
import { ColorPickerPipe } from './assets/color-picker.pipe';
import { NoValueAssetPipe } from './assets/no-value.pipe';
import { RuleAutocompleteComponent } from './auto-select/rule-autocomplete.component';
import { RuleAutocompleteDirective } from './auto-select/rule-autocomplete.directive';
import { RuleAutocompleteContentDirective } from './auto-select/rule-autocomplete-content.directive';
import { RuleOptionComponent } from './auto-select/rule-option/rule-option.component';
import { ShortNumberPipe } from './pipes/short-number.pipe';
import { ChangeTimeZone } from './pipes/changetimezone.pipe';
import { ScrollDirective } from './assets/directive/scrolldirective';
import { TabDivisionComponent } from './tabs-division/tab-division.component';
import { ExploreDashboardComponent } from './explore-dashboard/explore-dashboard.component';
import { MatTooltipModule } from '@angular/material/tooltip'
import { LimitSpanContentDirective } from './directives/limit-span-content.directive';
import { DisableActionPipe } from './pipes/disable-action.pipe';

const config = defaultToastConfig
@NgModule({
  declarations: [
    DashboardLabelComponent,
    DialogBoxComponent,
    DisplayTableComponent,
    DashboardWidgetComponent,
    DropdownOverlayComponent,
    TemplateDropdownComponent,
    OverlaySelectComponent,
    TabDivisionComponent,
    searchFilterPipe,
    LimitCharacterPipe,
    LimitArrayPipe,
    ArrayFilterPipe,
    ArrayLengthPipe,
    SearchHostsPipe,
    OnlyOneError,
    DatePickerComponent,
    DatePickerOverlayComponent,
    DateHighLighter,
    SpecialCharacterOmitterDirective,
    LimitSpanContentDirective,
    ToastComponent,
    TogglePipe,
    AccordionComponent,
    AccordionContent,
    AccordionTitle,
    AccordionHeader,
    AccordionItem,
    TabsComponent,
    TabComponent,
    TagAutocompleteComponent,
    TagAutocompleteDirective,
    TagAutocompleteContentDirective,
    TagOptionComponent,
    TagOptionGroupComponent,
    AssetsComponent,
    ColorPickerPipe,
    NoValueAssetPipe,
    RuleAutocompleteComponent,
    RuleAutocompleteDirective,
    RuleAutocompleteContentDirective,
    RuleOptionComponent,
    ShortNumberPipe,
    ChangeTimeZone,
    ScrollDirective,
    ExploreDashboardComponent,
    DisableActionPipe
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatDividerModule,
    MatDialogModule,
    MatInputModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    NgxEchartsModule,
    ReactiveFormsModule,
    MatTooltipModule
  ],
  exports: [
    DashboardLabelComponent,
    DialogBoxComponent,
    DisplayTableComponent,
    DashboardWidgetComponent,
    OverlaySelectComponent,
    DatePickerComponent,
    TabDivisionComponent,
    searchFilterPipe,
    LimitCharacterPipe,
    LimitArrayPipe,
    ArrayFilterPipe,
    OnlyOneError,
    SpecialCharacterOmitterDirective,
    LimitSpanContentDirective,
    ToastComponent,
    TogglePipe,
    AccordionComponent,
    AccordionContent,
    AccordionTitle,
    AccordionHeader,
    AccordionItem,
    TabsComponent,
    TabComponent,
    TagAutocompleteComponent,
    TagAutocompleteDirective,
    TagAutocompleteContentDirective,
    TagOptionComponent,
    TagOptionGroupComponent,
    AssetsComponent,
    ArrayLengthPipe,
    SearchHostsPipe,
    ColorPickerPipe,
    RuleAutocompleteComponent,
    RuleAutocompleteDirective,
    RuleAutocompleteContentDirective,
    RuleOptionComponent,
    ShortNumberPipe,
    ChangeTimeZone,
    ScrollDirective,
    ExploreDashboardComponent,
    DisableActionPipe
  ],
  providers: [
    {
      provide: TOAST_CONFIG_TOKEN,
      useValue: { ...defaultToastConfig, ...config },
    },
  ]

})
export class SharedModule {
}
