import { Directive, ElementRef, Input, AfterViewInit, ChangeDetectorRef, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[LimitSpanContent]', // Directive selector
})
export class LimitSpanContentDirective implements AfterViewInit {
  @Input('limit') limit: number; // Input for character limit


  constructor(private el: ElementRef, private cdr: ChangeDetectorRef) {}

  ngAfterViewInit() {
    // Ensure the content is fully rendered
    this.cdr.detectChanges();
    this.truncateContent(); // Apply truncation after view initialization
  }
  // ngAfterViewChecked(){
  //   this.truncateContent(); // Apply truncation after view initialization
  // }
 
  private truncateContent(): void {
    const container: HTMLElement = this.el.nativeElement;
    const originalContent = container.innerHTML; // Store the original content
    // console.log("LimitSpanContentDirective=============",this.limit)
    if (!originalContent || originalContent.trim().length === 0) {
      console.log('No content available yet.');
      return; // Prevent further execution if no content is available
    }

    // console.log("Original Content:", originalContent); // Debugging line to check content

    let totalCharacters = 0;
    let truncatedContent = '';

    // Create a temporary container to process child elements
    const tempContainer = document.createElement('div');
    tempContainer.innerHTML = originalContent;

    // Process each child node (spans and other elements)
    Array.from(tempContainer.childNodes).forEach((node) => {
      if (totalCharacters >= this.limit) return;

      if (node.nodeType === Node.TEXT_NODE) {
        // Handle plain text nodes
        const text = node.textContent || '';
        if (totalCharacters + text.length > this.limit) {
          const remaining = this.limit - totalCharacters;
          truncatedContent += text.slice(0, remaining) + '...'; // Add ellipsis
          totalCharacters = this.limit; // Stop after limit
        } else {
          truncatedContent += text;
          totalCharacters += text.length;
        }
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        // Handle element nodes (e.g., <span>, <strong>, etc.)
        const element = node as HTMLElement;
        const plainText = element.textContent || '';

        if (totalCharacters + plainText.length > this.limit) {
          const remaining = this.limit - totalCharacters;
          const truncatedText = plainText.slice(0, remaining) + '...'; // Add ellipsis
          const clonedElement = element.cloneNode(true) as HTMLElement;
          clonedElement.textContent = truncatedText; // Truncate text inside the element
          truncatedContent += clonedElement.outerHTML; // Append the element
          totalCharacters = this.limit; // Stop after limit
        } else {
          truncatedContent += element.outerHTML; // Add full element if it doesn't exceed the limit
          totalCharacters += plainText.length;
        }
      }
      // console.log(totalCharacters,truncatedContent)
    });

    // Apply the truncated content back to the original container
    // console.log("Truncated Content:", truncatedContent); // Debugging line to check the truncated content
    container.innerHTML = truncatedContent;
  }
}


