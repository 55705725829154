import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { combineLatest, fromEvent, Observable, Subject, Subscription } from 'rxjs';
import { mapTo, takeUntil } from 'rxjs/operators';
import { AuthService } from './auth/auth.service';
import { ErrorService } from './error/err.service';
import { Crump, RoutecrumpsService } from './services/routecrumps.service';
import { CdkOverlyayService } from './shared/custom-overlay/cdk-overlyay.service';
import { TemplateDropdownComponent } from './shared/custom-overlay/template-dropdown/template-dropdown.component';
import { ResetpwdComponent } from './user-login/reset-pwd/reset-pwd.component';
import { UserLoginService } from './user-login/user-login.service';
import { UserprofileComponent } from './user-login/userprofile/userprofile.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {

  title = 'Sauber';
  menu_array = [];
  // previewRef;
  offlineEvent: Observable<Event>
  subscriptions: Subscription[] = []
  onlineEvent: Observable<Event>
  footer: any;
  mat_spinner_userProfile_state: boolean;
  $destroy: Subject<boolean> = new Subject()
  alertShown = true
  UserInfo = ""
  constructor(private router: Router,
    public _service: UserLoginService,
    private authService: AuthService,
    public cdkoverlayDialog: CdkOverlyayService,
    public matdialog: MatDialog,
    private errorService: ErrorService,
    public crumpService: RoutecrumpsService
  ) { }

  ngOnInit(): void {
    console.log("route-crumps=====",this.crumpService.route_crumps)
    this.router.navigateByUrl('login');
    this.offlineEvent = fromEvent(window, 'offline');
    this.onlineEvent = fromEvent(window, 'online');
    this.errorService.getErrorMetadata()

    this.subscriptions.push(this.offlineEvent.subscribe((e) => {
      this.errorService.userOffline(true)
    }))

    this.subscriptions.push(combineLatest([this.onlineEvent.pipe(mapTo(1)), this._service.$userLogged])
      .subscribe((e) => {
        if (e[1]) {
          this.errorService.handleOfflineError()
        }
      }))

    this._service.$metaData.subscribe(val => {
      this.footer = val.commonData.footer
    })
  }

  ngAfterViewInit(): void {
    window.addEventListener("keyup", disableF5);
    window.addEventListener("keydown", disableF5);

    function disableF5(e) {
      if ((e.which || e.keyCode) == 116)
        e.preventDefault();
    };

    this._service.getMenu().subscribe(val => {
      this.menu_array = val;
    })
  }

  crump_click(crump: Crump, index: number) {
    if (index + 1 < this.crumpService.route_crumps.length) {
      for (let i = index + 1; i < this.crumpService.route_crumps.length; i++) {
        if (this.crumpService.route_crumps[i].callback && crump.isParent) {
          this.crumpService.route_crumps[i].callback.next();
        }
      }
      this.router.navigateByUrl(crump.routeTo);
    }
  }

  homeIcon = {
    img_url: "assets/menuicons/home.svg",
    label: "Home",
    url: "home",
    isSelectable: true,
    isSelected: true
  }

  openOverlay_click(template, origin): void {
    let config = {
      data: {
        "template": template,
      },
      width: '15vw'
    }
    this._service.previewRef = this.cdkoverlayDialog.openMenu(config, TemplateDropdownComponent, origin);
  }

  async openMyProfile_click() {
    this.mat_spinner_userProfile_state = true;
    await this._service.getUserProfile({ "user_name": this._service.userName })
      .pipe(takeUntil(this.$destroy))
      .toPromise()
      .then((val) => {
        this.mat_spinner_userProfile_state = false;
        const dialogRef = this.matdialog.open(UserprofileComponent, {
          width: "48%",
          height: "78vh",
          data: { userProfile: val },
          autoFocus: false
        });
        dialogRef.afterClosed().subscribe((dat) => { })
        this.closePreview()
      })
  }

  openResetPassword_click() {
    let dialogRef = this.matdialog.open(ResetpwdComponent,
      {
        width: "50%",
        height: "50vh",
        data: {
          "header": "Reset Password Verification",
          "buttonLabels": ["Submit", "Cancel"]
        }, autoFocus: false
      }
    );
    dialogRef.afterClosed().subscribe((dat) => {
    });
    this.closePreview();
  }

  logout() {
    this.authService.logout().subscribe(() => {
      this._service.userName = null;
      this.router.navigateByUrl('login');
      this.removesessionStoragevalue();
      this.closePreview();
    }, (err) => {
      this.removesessionStoragevalue();
    })
  }
  openOrganization_click() {
    const flattenedArray = [].concat(...this.crumpService.applist);
    const filteredData = flattenedArray.filter(obj => obj.url == 'organization')
    // console.log(filteredData)
    this.crumpService.homeIcon.isSelected = false
    this.crumpService.resetMenuList()
    filteredData[0]['isSelected'] = true
    this.router.navigateByUrl(filteredData[0]['url']);
    this.closePreview()
  }
  removesessionStoragevalue() {
    if (sessionStorage.getItem('SPL')) {
      sessionStorage.removeItem('SPL')
    }
    if (sessionStorage.getItem('SRID')) {
      sessionStorage.removeItem('SRID')
    }
    if (sessionStorage.getItem('query_fields')) {
      sessionStorage.removeItem('query_fields')
    }
  }

  navIconclick(nav: any) {
    if (nav.isSelectable && nav.url) {
      this.crumpService.homeIcon.isSelected = false
      this.crumpService.resetMenuList()
      nav['isSelected'] = true
      this.router.navigateByUrl(nav.url)
    }
  }

  closePreview() {
    if (this._service.previewRef) {
      this._service.previewRef?.close()
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  appReloaded() {
    // this.logout()
  }

  @HostListener('window:unload', ['$event'])
  appUnloaded() {
    // this.logout()
  }

  ngOnDestroy() {
    // this.logout()
    this.subscriptions.forEach(sub => sub.unsubscribe())
  }
  setTooltipStyle() {
    document.addEventListener('DOMContentLoaded', () => {
      const tooltips = document.querySelectorAll('.tooltip');

      tooltips.forEach(tooltip => {
        tooltip.addEventListener('mouseover', () => {
          const title = tooltip.getAttribute('data-title');
          const tooltipDiv = document.createElement('div');
          tooltipDiv.className = 'custom-tooltip';
          tooltipDiv.innerText = title;
          document.body.appendChild(tooltipDiv);

          const rect = tooltip.getBoundingClientRect();
          tooltipDiv.style.left = `${rect.left + window.scrollX + rect.width / 2}px`;
          tooltipDiv.style.top = `${rect.top + window.scrollY - tooltipDiv.offsetHeight}px`;

          tooltip.addEventListener('mousemove', (e) => {
            // tooltipDiv.style.left = `${e['pageX']}px`;
            // tooltipDiv.style.top = `${e['pageY'] - tooltipDiv.offsetHeight}px`;
          });

          tooltip.addEventListener('mouseout', () => {
            tooltipDiv.remove();
          });
        });
      });
    });
  }
}
