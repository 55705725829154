import { Directive, ElementRef, HostListener, Input, OnDestroy, OnInit,ViewContainerRef } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { ConnectionPositionPair, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { RuleAutocompleteComponent } from './rule-autocomplete.component';
import { TemplatePortal } from '@angular/cdk/portal';
import { filter, startWith, takeUntil } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { RuleAutocompleteService } from './rule-autocomplete-content.service';

@Directive({
  selector: '[ruleAutocomplete]'
})
export class RuleAutocompleteDirective implements OnInit, OnDestroy{
  @Input() ruleAutocomplete: [RuleAutocompleteComponent,HTMLElement, FormControl,any[]];
  private overlayRef: OverlayRef;
  @Input() inputdisable:boolean = false;
  $destroy:Subject<boolean> = new Subject();
  
  constructor(
    private host: ElementRef<HTMLInputElement>,
    private viewContentRef: ViewContainerRef,
    private overlay: Overlay,
    private autocompleteservice:RuleAutocompleteService
  ) { }

  @HostListener('keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key == "ArrowUp") {
      this.cannavigate(-1);
    }else if (event.key == "ArrowDown") {
      this.cannavigate(1);
    }else if (event.key == "Enter") {
      this.ruleAutocomplete[2].setValue(this.ruleAutocomplete[0].options.toArray()[this.autocompleteservice.navnumber.getValue()].value);
      this.origin.value = this.ruleAutocomplete[0].options.toArray()[this.autocompleteservice.navnumber.getValue()].display;
      this.ruleAutocomplete[0].selectedItem = this.ruleAutocomplete[0].options.toArray()[this.autocompleteservice.navnumber.getValue()].value;
      this.ruleAutocomplete[0].SelectionChange.emit([])
      this.origin.blur();
      this.close();

    }else{
      this.ruleAutocomplete[0].shortoptions(this.origin.value);
      if(this.ruleAutocomplete[2].value){
        this.ruleAutocomplete[2].setValue('');
        this.ruleAutocomplete[0].SelectionChange.emit([]);
        this.ruleAutocomplete[0].selectedItem = '';
      }
    }
  }

  ngOnInit() {
    fromEvent(this.origin, 'focus').pipe(
    ).subscribe(() => {
      if((!this.overlayRef || !this.overlayRef.hasAttached()) && !this.inputdisable){
        this.autocompleteservice.inputval = this.origin.value;
        this.autocompleteservice.navnumber.next(0);
        this.openDropdown();
      }
    this.ruleAutocomplete[0].optionsClick()
      .pipe(takeUntil(this.overlayRef.detachments()))
      .subscribe((obj:any) => {
        this.ruleAutocomplete[2].setValue(obj.value);
        this.origin.value = obj.disp;
        this.ruleAutocomplete[0].selectedItem = obj.value;
        this.ruleAutocomplete[0].SelectionChange.emit([])
        this.close();
      });
    });

    this.ruleAutocomplete[2].valueChanges.pipe(startWith(this.ruleAutocomplete[2].value))
    .pipe(takeUntil(this.$destroy))
    .subscribe(val=>{
      this.findvalues(val)
    })

    /* this.ruleAutocomplete[4]
    .pipe(takeUntil(this.$destroy))
    .subscribe((val)=>{
      this.ruleAutocomplete[3] = val;
    }) */
  }

  private cannavigate(direction:number){
    if(this.overlayRef.hasAttached()){
      this.handlenavigation(direction)
    }
  }

  private handlenavigation(direction:number){
    if(direction == 1){
      this.handledownarrow(direction);
    }else if(direction == -1){
      this.handleuparrow(direction);
    }
  }

  private handleuparrow(direction:number){
    if(this.autocompleteservice.navnumber.getValue() > 0){
      this.autocompleteservice.navnumber.next(this.autocompleteservice.navnumber.getValue()+direction)
      if(!this.checknavigateoption()){
        this.handleuparrow(direction);
      }
    }
  }

  private handledownarrow(direction:number){
    if(this.autocompleteservice.navnumber.getValue() < this.ruleAutocomplete[0].options.length){
      this.autocompleteservice.navnumber.next(this.autocompleteservice.navnumber.getValue()+direction)
      if(!this.checknavigateoption()){
        this.handledownarrow(direction);
      }
    }
  }

  private checknavigateoption(){
    return this.ruleAutocomplete[0].options.toArray()[this.autocompleteservice.navnumber.getValue()].show
  }

  private findvalues(val:any){
    if(typeof(val) == "object"){
      this.ruleAutocomplete[3].find((item)=>{
        if(JSON.stringify(item.value) == JSON.stringify(val)){
          this.origin.value = item.disp;
          this.ruleAutocomplete[0].selectedItem = item.value;
          return item;
        }
      })
    }
  }

  openDropdown() {
    this.overlayRef = this.overlay.create({
      width: this.ruleAutocomplete[1].offsetWidth,
      maxHeight:40 * 3,
      backdropClass: '',
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      positionStrategy: this.getOverlayPosition()
    });

    const template = new TemplatePortal(this.ruleAutocomplete[0].rootTemplate, this.viewContentRef);
    this.overlayRef.attach(template);
    overlayClickOutside(this.overlayRef, this.origin,).subscribe((val) => {
      this.close()
    });
  }

  private close() {
    this.overlayRef.detach();
  }

  private getOverlayPosition() {
    const positions = [
      new ConnectionPositionPair(
        { originX: 'start', originY: 'bottom' },
        { overlayX: 'start', overlayY: 'top' }
      ),
      new ConnectionPositionPair(
        { originX: 'start', originY: 'top' },
        { overlayX: 'start', overlayY: 'bottom' }
      )
    ];

    return this.overlay
    .position()
    .flexibleConnectedTo(this.ruleAutocomplete[1])
    .withPositions(positions)
    .withFlexibleDimensions(false)
    .withPush(false);
  }

  get origin() {
    return this.host.nativeElement;
  }

  ngOnDestroy() {
    this.overlayRef?.detach();
    this.$destroy.next(true);
    this.$destroy.unsubscribe();
  }
}

export function overlayClickOutside( overlayRef: OverlayRef, origin: HTMLElement) {
  return fromEvent<MouseEvent>(document, 'click')
    .pipe(
      filter(event => {

        const clickTarget = event.target as HTMLElement;
        const notOrigin = clickTarget !== origin; // the input
        const notOverlay = !!overlayRef && (overlayRef.overlayElement.contains(clickTarget) === false); // the autocomplete
        return notOrigin && notOverlay;
      }),
      takeUntil(overlayRef.detachments())
    )
}
