import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'arrayFilter',
})
export class ArrayFilterPipe implements PipeTransform {

    transform(value: Array<any>, argumant1: any, kirew: number, argumant2: any) {
        if (!argumant2) {
            return value.slice(0, argumant1);
        } else {
            return value
        }
    }
}
