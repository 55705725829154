import { AfterContentInit, ChangeDetectionStrategy, Component, ContentChildren, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges } from '@angular/core';
import { TabComponent } from '../tab/tab.component';

@Component({
  selector: 'tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit, AfterContentInit, OnChanges {

  constructor() { }

  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;

  @Output() selectedIndexChange: EventEmitter<number> = new EventEmitter<number>()

  @Input() selectedIndex: number;

  @Input() clickchange: boolean = true;

  private currentIndex: number;

  ngOnInit(): void {
    this.currentIndex = this.selectedIndex;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.selectedIndexChanged(changes)
  }

  ngAfterContentInit(): void {
    console.log(this.tabs)
    let activeTabs = this.tabs.filter((tab) => tab.active);
    if (activeTabs.length === 0 && !this.currentIndex) {
      this.tabs.first.active = true;
      this.currentIndex = 0
    } else {
      this.tabs.toArray()[this.currentIndex].active = true;
    }
  }

  closeTab() {
    this.tabs.toArray().forEach(tab => tab.active = false);
  }

  checkPresentTab(previousindex: number, index: number) {
    return previousindex !== index
  }

  selectTab(tab: TabComponent, index: number) {
    if (this.checkPresentTab(this.currentIndex, index) && this.clickchange && !tab.disable) {
      this.closeTab()
      this.activeTab(index)
    }
  }

  activeTab(index: number) {
    this.tabs.toArray()[index].active = true;
    this.currentIndex = index;
    this.selectedIndexChange.emit(index)
  }

  selectedIndexChanged(changes: any) {
    if (changes.selectedIndex) {
      if (!changes.selectedIndex.firstChange && this.checkPreviousIndex()) {
        this.currentIndex = this.selectedIndex;
        this.closeTab()
        this.activeTab(changes.selectedIndex.currentValue);
      }
    }
  }
  checkPreviousIndex() {
    return this.selectedIndex != this.currentIndex
  }

}
