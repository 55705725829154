import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'colorPicker'
})
export class ColorPickerPipe implements PipeTransform {
  transform(
    value: {},
    search: string
  ): {} {
    if (value) {
      const tagName = search.toLowerCase()
      for (const category in value) {
        for (const tag in value[category].members) {
          if (tag.toLowerCase() == tagName) {
            return { "background-color": value[category].color }
          }
        }
      }
    }
    return { 'background-color': '#fff' }
  }
}