
import { AbstractControl, AsyncValidatorFn, ValidationErrors} from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// import { SettingsService } from '../settings.service';
import { SettingsService } from 'src/app/settings/settings.service';
  
  export class UsernameValidator {
    static createValidator(settingService: SettingsService): AsyncValidatorFn {
      return (control: AbstractControl): Observable<ValidationErrors> => {
        return settingService
          .checkIfUsernameExists(control.value)
          .pipe(
            map((result: any) =>{
              if(result.usernameExists){ 
                return {usernameAlreadyExists: true }
              }else{
                return null
              } 
            })
          );
      };
    }
  }