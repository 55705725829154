import { Pipe, PipeTransform } from '@angular/core';
import { TimeZoneService } from 'src/app/services/time-zone.service';

@Pipe({
  name: 'changetimezone'
})
export class ChangeTimeZone implements PipeTransform {
  constructor(private timezoneservice: TimeZoneService) {}
  transform(timestring:any,format:string) {
    return this.timezoneservice.toUserTimeZone(timestring).format(format);
  }
}