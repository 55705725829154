import { AfterViewInit, Component, ElementRef, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { ToastConfig, ToastData, TOAST_CONFIG_TOKEN } from './toast.config';
import { ToastRef } from './toast.ref';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit, AfterViewInit {

  private intervalId: any;
  private progressInterval:any;
  private timePeriod:any = 5000;
  private width:number = 90;
  toastClass:string
  @ViewChild('element', { static: false }) progressBar: ElementRef;

  constructor(
    readonly data: ToastData,
    readonly ref: ToastRef,
    @Inject(TOAST_CONFIG_TOKEN) private toastConfig: ToastConfig
  ) { }

  @HostListener('mouseover') onMouseOver() {
   this.stopInterval()
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.countdown() 
  }

  ngOnInit(): void {
    this.toastClass = this.data.type
  }

  ngAfterViewInit(): void {
    this.countdown();
  }

  countdown(){
    this.progressInterval = setInterval(()=>{
      this.progressBar.nativeElement.style.width =
      this.width + '%';
      this.width = this.width-10;
      if(this.timePeriod){
        this.timePeriod = this.timePeriod-500;
      }else{
        this.close()
      }
    },500)
  }

  stopInterval(){
    clearTimeout(this.intervalId);
    clearInterval(this.progressInterval);
  }
  
  ngOnDestroy() {
    clearTimeout(this.intervalId);
    clearInterval(this.progressInterval);
  }

  close() {
    this.ref.close();
  }
}
