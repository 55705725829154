<div class="fields-continer" [formGroup]="fieldsDetails">
    <div class="header-wrapper">
        <div class="header-col-1" [formGroup]="platformField">
            <div #platformOrigin class="form-field">
                <label class="form-label-on">{{fieldsMetaData.labels.platform}} </label>
                <app-overlay-select formControlName="platform" [data]="platformlist" [disabled]="true"
                    [origin]="platformOrigin" [style]="{'font-size':'12px'}">
                </app-overlay-select>
            </div>
        </div>
    </div>
    <ng-container *ngIf="mat_spinner_fieldList_state">
        <div class ="body-bottom-spinner">
            <mat-spinner class="spinner" diameter=20></mat-spinner>
        </div>
    </ng-container>
    <div class="body-wrapper" *ngIf="!mat_spinner_fieldList_state && !nodatastate && !errorhandlefields.status">
        <div class="body-title">
            <p>{{fieldsMetaData.labels.editFields}}</p>
        </div>
        <div class="body-top">
            <div class="body-top-col-1">
                <label>{{fieldsMetaData.labels.fieldName}}</label>
            </div>
            <div class="body-top-col-2">
                <label>{{fieldsMetaData.labels.moduleRef}}</label>
            </div>
            <div class="body-top-col-3">
                <label>{{fieldsMetaData.labels.fieldDisp}}</label>
            </div>
            <div class="body-top-col-4">
                <label>{{fieldsMetaData.labels.dataType}}</label>
            </div>
            <div class="body-top-col-5">
                <label>{{fieldsMetaData.labels.isDip}}</label>
            </div>
            <div class="body-top-col-6">
                <label>{{fieldsMetaData.labels.isFilter}}</label>
            </div>
            <div class="body-top-col-7">
                <label>{{fieldsMetaData.labels.dispOrder}}</label>
            </div>
            <div class="body-top-col-8">
            </div>
        </div>
        <div class="body-bottom">
            <ng-container formArrayName="parentArray">
                <div class="body-bottom-row">
                    <div class="parent-wrapper" *ngFor="let parentItem of parentArray?.controls;let parentIndex=index">
                        <ng-container [formGroupName]="parentIndex">
                            <div class="body-bottom-row-col-1">
                                <div>
                                    <input type="text" formControlName="fieldName" class="input-fieldname" disabled>
                                </div>
                            </div>
                            <div class="body-bottom-row-col-2">
                                <ng-container formArrayName="childArray">
                                    <ng-container
                                        *ngFor="let childItem of childArray(parentIndex).controls;let childIndex=index">
                                        <div class="body-bottom-row-col-2-row-1">
                                            <div class="body-bottom-row-col-2-row-1-1" [formGroupName]="childIndex">
                                                <div class="field" *ngIf="childIndex==0" class="input-fieldname">
                                                    <input class="input-fieldname" type="text"
                                                        formControlName="moduleRef" disabled>
                                                </div>
                                                <div #typeorigin class="moduleRef" *ngIf="childIndex !=0">
                                                    <app-overlay-select [hasMultiSelect]=false [data]="fieldsMetaData.moduleList"
                                                        formControlName="moduleRef" [origin]="typeorigin"
                                                        [style]="{'font-size':'13px'}" placeHolder="Modules">
                                                    </app-overlay-select>
                                                </div>
                                                <div class="field">
                                                    <input type="text" formControlName="fieldDisplay">
                                                    <ng-container>
                                                        <div class="alert-danger"
                                                            *ngIf=" childControl(parentIndex,childIndex).get('fieldDisplay').touched">
                                                            {{ fieldsMetaData.fieldDisplay | oneError : childControl(parentIndex,childIndex).controls.fieldDisplay.errors  }}
                                                        </div>
                                                    </ng-container>
                                                </div>
                                                <div #typeorigin class="field">
                                                    <app-overlay-select [hasMultiSelect]=false [data]="fieldsMetaData.typeList"
                                                        formControlName="dataType" [origin]="typeorigin"
                                                        [style]="{'font-size':'13px','color':'#565656'}" placeHolder="Data type">
                                                    </app-overlay-select>
                                                </div>
                                                <div class="is-display">
                                                    <label class="checkbox-container">
                                                        <input type="checkbox" formControlName="isDisp">
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div class="is-filter">
                                                    <label class="checkbox-container">
                                                        <input type="checkbox" formControlName="isFilter">
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div class="field">
                                                    <input type="number" (keydown)="specialChar($event)" formControlName="dispOrder" min="0">
                                                </div>
                                                <div class="add-control-container">
                                                    <div class="add-module-btn"
                                                        (click)="addChild(parentIndex,childIndex)" 
                                                        *ngIf="childIndex == childArray(parentIndex).controls.length-1 && childIndex !=4">
                                                        <img src="assets/settings/fields_plush.svg" alt="" >
                                                    </div>
                                                    <div *ngIf="childIndex !=0 || childIndex != childArray(parentIndex).controls.length-1"
                                                        class="delete-module-btn"
                                                        (click)="deleteChild(parentIndex,childIndex)" >
                                                        <img src="assets/settings/fields_dlt.svg" alt=""
                                                            *ngIf="childIndex!=0" class="delete-module-btn"
                                                            (click)="deleteChild(parentIndex,childIndex)">
                                                    </div>
                                                </div>
                                            </div>
                                            <ng-container *ngIf=" childIndex==childArray(parentIndex).length-1 && showErrorState && childControl(parentIndex,childIndex).get('moduleRef').invalid">
                                                <div class="alert-danger-select">
                                                    Module name required
                                                </div>
                                            </ng-container>
                                            <ng-container >
                                                <div class="alert-danger-select" *ngIf="childArray(parentIndex).errors?.alreadyFound && childControl(parentIndex,childIndex).touched && childIndex==childArray(parentIndex).length-1">
                                                    {{fieldsMetaData.moduleRef |oneError:childArray(parentIndex).errors}}
                                                </div>
                                            </ng-container>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <ng-container class="nodata-container" *ngIf="!mat_spinner_fieldList_state && nodatastate || errorhandlefields.status">
        <div class="fieldslist-nodata">
            <div class="nodata-img">
                <img  src="assets/designer/empty-data.svg">
            </div>
            <div>
                <p>{{errorhandlefields.status ? errorhandlefields.errmsg:nodata}}</p>
            </div>
        </div>
    </ng-container>
    <div class="footer-wrapper" *ngIf="!nodatastate && !errorhandlefields.status">
        <button (click)="cancel_click()" class="cancel_btn">
           {{buttonLabels.cancel}}
        </button>
        <button (click)="submit_click()" class="submit_btn">
            {{buttonLabels.submit}}
        </button>
    </div>
</div>