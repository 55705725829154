import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'limitArray',
})
export class LimitArrayPipe implements PipeTransform {

  transform(value: Array<any>, argumant1: any, argumant2: any) {
    if (!argumant2 && value)
      return value.slice(0, argumant1);
    else if (argumant2 && value)
      return value
    else
      return value
  }
}
