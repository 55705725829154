import { Component, ContentChild, ContentChildren, ElementRef, EventEmitter, Output, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { RuleAutocompleteContentDirective } from './rule-autocomplete-content.directive';
import { RuleOptionComponent } from './rule-option/rule-option.component';
import { switchMap } from 'rxjs/operators';
import { merge } from 'rxjs';

@Component({
  selector: 'rule-autocomplete',
  template: `
    <ng-template #root>
      <div class="rule-autocomplete">
        <ng-container *ngTemplateOutlet="content.tpl"></ng-container>
      </div>
    </ng-template>
  `,
  
  exportAs: 'ruleautocomplete',
  styleUrls: ['./rule-autocomplete.component.scss']
})
export class RuleAutocompleteComponent {

  @ViewChild('root') rootTemplate: TemplateRef<any>;

  @ContentChild(RuleAutocompleteContentDirective)
  content: RuleAutocompleteContentDirective;

  @ContentChildren(RuleOptionComponent)
  options: QueryList<RuleOptionComponent>;

  @Output() SelectionChange:EventEmitter<any> = new EventEmitter();
  
  selectedItem:any;

  constructor(){ }

  optionsClick() {
    return this.options.changes.pipe(
      switchMap(options => {
        const clicks$ = options.map(option => option.click$);
        return merge(...clicks$);
      })
    );
  }

  shortoptions(searchstr:string){
    this.options.map(item=>{
      if(item.display.toLowerCase().includes(searchstr.toLowerCase())){
        item.show = true
      }else{
        item.show = false;
      }
    })
  }
}
