<div class="user-landing-continer">

    <div class="header-wrapper">
        <div class="header-col-1">
            <div class="custom-search">
                <img src="assets/settings/search-small.svg">
                <input type="search" id="input" placeholder="Type to search here" [(ngModel)]="searchUserList"
                    (click)="$event.stopPropagation()">
            </div>
        </div>

        <div class="header-col-2">
            <div class="add-user-btn" (click)="addNewUser()">
                <img src="assets/settings/plus.svg" alt="">
                <p>Add New User</p>
            </div>
        </div>
    </div>
    <div class="body-wrapper">
        <div class="body-top">
            <div class="body-top-col-1">
                <img src="assets/settings/left-arrow.svg"
                    [ngClass]="settingsVarService.pages.length > 1 ? 'enable-action':'disable-action'"
                    (click)="pagination_click('left')">
                <p>{{settingsVarService.pages.presentPage}} of {{settingsVarService.pages.length >0 ?
                    settingsVarService.pages.length:'1'}} </p>
                <img src="assets/settings/right-arrow.svg"
                    [ngClass]="settingsVarService.pages.length > 1 ? 'enable-action':'disable-action'"
                    (click)="pagination_click('right')">
            </div>

            <div class="body-top-col-2">
                <ng-container *ngFor="let pagedat of settingsVarService.commonData.pagination | keyvalue">
                    <p>{{pagedat.key}}</p>
                    <app-overlay-select [formControl]="settingsVarService.userPagination" [data]="pagedat.value"
                        [style]="{'font-size':'12px'}">
                    </app-overlay-select>
                </ng-container>

            </div>
        </div>
        <div class="body-bottom">
            <ng-container *ngIf="!mat_spinner_userList_state">
                <ng-container *ngIf="!settingsVarService.errorhandleuserlanding.status">
                    <table>
                        <thead>
                            <tr>
                                <th *ngFor="let header of settingsVarService.userTableHeader.headers">{{header}}</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr *ngFor="let users of settingsVarService.userList |searchFilter: searchUserList"
                                (click)="editUser(users.user_id,$event)">
                                <td>
                                    <img *ngIf="users.status == 1" src="assets/settings/greenTick.svg">
                                    <img *ngIf="users.status == 0" src="assets/settings/greyTick.svg">
                                    <img *ngIf="users.status == 2" src="assets/settings/warning.svg">
                                </td>
                                <td> {{users.user_id}} </td>
                                <td> {{users.fname}} {{users.lname}}</td>
                                <td> {{users.role_assingned}} </td>
                                <td> {{users.mobile}} </td>
                                <td> {{users.email}} </td>
                                <td> <span *ngIf="users.new_mobile"> {{users.new_mobile}} </span>
                                    <span *ngIf="(users.new_mobile) && (users.new_email && users.new_email != 'null')">
                                        ,
                                        {{users.new_email}} </span>
                                    <span
                                        *ngIf="(!users.new_mobile ) && (users.new_email && users.new_email != 'null')">
                                        {{users.new_email}}
                                    </span>
                                </td>
                                <td>
                                    <img class="show-btn" (click)="editUser(users.user_id,$event)"
                                        src="assets/settings/edit-row.svg">
                                    <img *ngIf="users.user_id != userName" class="show-btn"
                                        (click)="deleteUser(users.user_id,$event)" src="assets/settings/delete.svg">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ng-container>
                <ng-container class="nodata-container" *ngIf="settingsVarService.errorhandleuserlanding.status">
                    <div class="userlist-nodata">
                        <div class="nodata-img">
                            <img src="assets/designer/empty-data.svg">
                        </div>
                        <div>
                            <p>{{settingsVarService.errorhandleuserlanding.errmsg}}</p>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="mat_spinner_userList_state">
                <div class="body-bottom-spinner">
                    <mat-spinner class="spinner" diameter=20></mat-spinner>
                </div>
            </ng-container>
            <ng-container *ngIf="mat_spinner_userList_editState">
                <div class="body-bottom-spinner_edit">
                    <mat-spinner class="spinner" diameter=20></mat-spinner>
                </div>
            </ng-container>
        </div>
    </div>
</div>