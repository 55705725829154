import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RoutecrumpsService } from '../services/routecrumps.service';
import { UserLoginService } from '../user-login/user-login.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(
    private router: Router,
    private rcrumpService: RoutecrumpsService,
    private userloginService: UserLoginService
  ) { }

  label = this.rcrumpService.label;
  applist = this.rcrumpService.applist;

  ngOnInit(): void {
    this.rcrumpService.route_crumps[0] = {
      disp_str: "Home",
      routeTo: "home",
      callback: null,
      isParent: false
    }
    this.rcrumpService.route_crumps.length = 1;
  }

  chip_click(module: any) {
    if (module.isSelectable) {
      // this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };
      this.router.navigateByUrl(module.url)
    }
  }

}
