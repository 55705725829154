import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { mapTo } from 'rxjs/operators';

@Component({
  selector: 'tag-option',
  template: `
    <ng-content></ng-content>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./tag-option.component.scss']
})
export class TagOptionComponent implements OnInit {
  @Input() value: string;
  click$: Observable<string>;

  constructor(
    private host: ElementRef,
    ) {
  }


  ngOnInit() {
    //this.click$ = fromEvent(this.element, 'click').pipe(mapTo(this.value));
  }

  get element() {
    return this.host.nativeElement;
  }
}
