import { Component, ContentChildren, EventEmitter, Input, Output, QueryList, SimpleChanges } from '@angular/core';
import { TabComponent } from 'src/app/shared/assertion/tab/tab.component';

@Component({
  selector: 'tabs-division',
  templateUrl: './tab-division.component.html',
  styleUrls: ['./tab-division.component.scss']
})
export class TabDivisionComponent {

  constructor() { }

  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;
  @Output()selectedIndexChange:EventEmitter<number>= new EventEmitter();
  @Input() selectedIndex:number;
  @Input() clickchange:boolean = true;

  private currentIndex:number;

  ngOnInit(): void {
    this.currentIndex = this.selectedIndex;
  }

  ngOnChanges(changes:SimpleChanges) {
    this.selectedIndexChanged(changes)
  }

  ngAfterContentInit(): void {
    let activeTabs = this.tabs.filter((tab)=>tab.active);
    if(activeTabs.length === 0 && !this.currentIndex) {
      this.tabs.first.active=true;
      this.currentIndex = 0
    }else{
      this.tabs.toArray()[this.currentIndex].active=true;
    }
  }

  closeTab(){
    this.tabs.toArray().forEach(tab => tab.active = false);
  }

  checkPresentTab(previousindex:number,index:number){
    return previousindex !== index
  }

  selectTab(tab: TabComponent,index:number){
    if(this.checkPresentTab(this.currentIndex,index) && this.clickchange){
      this.closeTab()
      this.activeTab(index)
    }
  }

  activeTab(index:number){
    this.tabs.toArray()[index].active=true;
    this.currentIndex = index;
    this.selectedIndexChange.emit(index)
  }

  selectedIndexChanged(changes:any){
    if(changes.selectedIndex){
      if(!changes.selectedIndex.firstChange && this.checkPreviousIndex()){
        this.currentIndex = this.selectedIndex;
        this.closeTab()
        this.activeTab(changes.selectedIndex.currentValue);
      }
    }
  }
  checkPreviousIndex() {
    return this.selectedIndex != this.currentIndex
  }

}
