import { ChangeDetectionStrategy, Component, ContentChildren, ElementRef, Input, OnInit, QueryList } from '@angular/core';
import { Observable } from 'rxjs';
import { TagOptionComponent } from '../tagoption/tag-option.component';

@Component({
  selector: 'tag-option-group',
  template: `
    <div class="option-group">
      <p>{{label}}</p>
      <ng-content></ng-content>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls:['./tag-option-group.component.scss']
})
export class TagOptionGroupComponent implements OnInit{
  @Input() label: string;
  click$: Observable<string>;

  /* @ContentChildren(TagOptionComponent)
  options: QueryList<TagOptionComponent>; */

  constructor(
     private grouptitle: ElementRef,
     ) {
  }

  ngOnInit() { }

 
  get element() {
    return this.grouptitle.nativeElement;
  }
}
