<div class="login-warning-container">
    <div class="warning-container-body">
        <!-- <P><span class="username-text">{{loginService.userName}}</span> is already logged in at <span class="last-login-time">{{data.last_login}}</span>. Would you like to terminate that session?</P> -->
        <p>{{loginService.already_login_warning_msg}}</p>
        <p class="last-login-info">User <span class="username-text">{{loginService.userName}}</span> last logged in at <span class="last-login-time">{{data.last_login}}</span></p>
        <!-- <p class="last-login-info">User : <span class="username-text">{{loginService.userName}}</span> </p> -->
        <!-- <p class="last-login-info">Last login : <span class="last-login-time">{{data.last_login}}</span></p> -->
    </div>
    <div class="warning-container-bottom">
        <button class="submit" mat-dialog-close (click)="terminate_session_alert()" >yes</button>
        <button class="cancel" mat-dialog-close (click)="cancel()" >no</button>
    </div>
</div>
