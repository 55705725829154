<div class="dialogbox-container">

  <div class="header-wrapper">
    <h3 class="heading">
      <ng-container [ngTemplateOutlet]="headContent"></ng-container>
    </h3>
    <img mat-dialog-close src="assets/close-dialog.svg">
  </div>

  <div class="description">
    <span class="subHeading">
      <ng-container [ngTemplateOutlet]="subHeadContent"></ng-container>
    </span>
  </div>


  <mat-dialog-content>
    <ng-container [ngTemplateOutlet]="htmlContent"></ng-container>
  </mat-dialog-content>

  <mat-dialog-actions>
    <ng-container [ngTemplateOutlet]="footContent"></ng-container>
  </mat-dialog-actions>

</div>